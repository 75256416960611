import React, { createContext } from 'react';
import { appConfig } from '../logic/appConfigProvider';
import * as microsoftTeams from "@microsoft/teams-js";
import { TeamsUserCredential, UserInfo } from '@microsoft/teamsfx';
import { AccountInfo, EndSessionRequest, PublicClientApplication, RedirectRequest, InteractionRequiredAuthError, BrowserAuthError, BrowserCacheLocation } from '@azure/msal-browser';
import * as apiCalls from '../logic/apiCalls';
import { ApiTokenResponse } from '../logic/apiCalls';
import { TokenRetrievalError, extractErrorMessage } from '../utils/errors';
import { getProfilePicture } from '../logic/graphService';
import * as misc from '../utils/misc';
import { urls } from '../logic/urls';
import log from 'loglevel';

export enum AuthenticationStates {
    'NotAuthenticated' = 'NOT_AUTHENTICATED', // No user is currently logged-in
    'Authenticating' = 'AUTHENTICATING', // The redirect back from Microsoft has taken place, but the process of exchanging the authentication code for the tokens is still in progress.
    'Authenticated' = 'AUTHENTICATED', // The user is successfully logged in. The first time this state is reached, the check for the authorization will be started.
    'AuthenticationError' = 'AUTHENTICATION_ERROR', // The user login has failed for some reason.
}

export interface AuthenticationContextProps {
    authenticationState: AuthenticationStates,
    displayName: string,
    preferredUserName: string,
    lastAuthenticationErrorMessage: string,
    profilePicture: string
}

export interface AuthenticationContextFunctions {
    getApiToken() : Promise<ApiTokenResponse>,
    loginRedirect(postRedirectUrl: string) : void,
    logoutRedirect(): void,
    isSSO(): boolean
}

export interface AuthenticationContextInterface extends AuthenticationContextProps, AuthenticationContextFunctions {
}

export const AuthenticationContext = createContext<AuthenticationContextInterface | undefined>(undefined);

interface AuthenticationContextProviderProps {
    children?: React.ReactNode,
}

interface AuthenticationContextProviderState extends AuthenticationContextProps  {    
}

export class AuthenticationContextProvider extends React.Component<AuthenticationContextProviderProps, AuthenticationContextProviderState> {
    state = {
        authenticationState: AuthenticationStates.Authenticating,
        displayName: "",
        preferredUserName: "",
        lastAuthenticationErrorMessage: "",
        profilePicture: ""
    }

    isSso?: boolean = undefined;

    // SSO related fields.
    teamsUserCredential?: TeamsUserCredential;
    userInfo?: UserInfo;

    // MSAL related fields.
    publicClientApplication?: PublicClientApplication;
    accountInfo? : AccountInfo;

    async componentDidMount(): Promise<void> {
        apiCalls.registerApiTokenProviderCallback(this.getApiToken);

        try {
            await microsoftTeams.app.initialize();
            const teamsContext = microsoftTeams.app.getContext();
            if (teamsContext === null) {
                throw new Error("No Teams context received from call to 'app.getContext'");           
            }

            // The notifySuccess will result in the removal of the native loading indicator. This is done before the authentication/authorization is finished on purpose,
            // so that in case of an error the error view is shown which might contain useful detailed information. The default error view which is shown with notifyFailure
            // cannot contain specific information.
            microsoftTeams.app.notifySuccess();
        }
        catch (error: any) {
            log.debug(`Initialization failed: '${error.message}'. Assuming that application is not running in Teams. Performing MSAL authenticaton.`);
            
            await this.performMsalAuthentication();
            return;
        }
                
        await this.performSSOAuthentication();       
    }

    componentWillUnmount(): void {
        apiCalls.clearApiTokenProviderCallback();
    }

    performMsalAuthentication = async () => {
        this.isSso = false;
        this.clearLastError();

        this.publicClientApplication = new PublicClientApplication({
            auth: {
                authority: appConfig.authority,
                clientId: appConfig.clientId,
                navigateToLoginRequestUrl: false,
                redirectUri: misc.urlCombine(misc.getBaseUriWithoutParameters(), urls.postLogin)
            },
            cache: {
                cacheLocation: BrowserCacheLocation.SessionStorage,
                storeAuthStateInCookie: false    
            },
            system: {
                iframeHashTimeout: 6000,
            }
        });  
        
        try {
            log.info(`[performMsalAuthentication] Invoking the 'handleRedirectPromise' method on the MSAL Public Client Application.`);
            const result = await this.publicClientApplication.handleRedirectPromise();
            
            if (result === null || result.account === null) {
                // No authentication redirect detected. This also happens on a page refresh, in which case the cached user account is used.
                const existingAccount = this.publicClientApplication.getActiveAccount();
                if (existingAccount !== null) {
                    log.info(`[performMsalAuthentication] Using cached information for logged-in user ${existingAccount.username}.`);
                    this.updateAuthenticationState(AuthenticationStates.Authenticated, existingAccount.name, existingAccount.username);
                    this.tryDownloadProfilePicture();
                    return;
                }

                log.info(`[performMsalAuthentication] No user is logged in.`);
                this.updateAuthenticationState(AuthenticationStates.NotAuthenticated);
                return;
            }

            this.accountInfo = result.account;
            log.info(`[performMsalAuthentication] User '${this.accountInfo.username}' successfully logged in.`);

            this.publicClientApplication.setActiveAccount(this.accountInfo);
            
            this.updateAuthenticationState(AuthenticationStates.Authenticated, this.accountInfo.name, this.accountInfo.username);
            this.tryDownloadProfilePicture();
        }
        catch (error: any) {
            log.error(`[performMsalAuthentication] Error while invoking the 'handleRedirectPromise' method on the MSAL Public Client Application: ${error}`);

            this.setLastError(error);
            this.updateAuthenticationState(AuthenticationStates.AuthenticationError);
        }
    }

    performSSOAuthentication = async () => {
        this.isSso = true;
        this.clearLastError();

        // Use the configured ssoClientId if present, use the normal clientId otherwise.
        const ssoClient = appConfig.ssoClientId ?? appConfig.clientId;

        const authConfig = {
            "initiateLoginEndpoint": "auth-start.html",
            "clientId": ssoClient,
        };

        try {
            this.teamsUserCredential = new TeamsUserCredential(authConfig);    
            this.userInfo = await this.teamsUserCredential.getUserInfo();

            this.updateAuthenticationState(AuthenticationStates.Authenticated, this.userInfo.displayName, this.userInfo.preferredUserName);        
        }
        catch (error: any) {
            this.setLastError(error);
            this.updateAuthenticationState(AuthenticationStates.AuthenticationError);
        }
    }

    getApiToken = async () : Promise<ApiTokenResponse> => {
        if (this.isSso === undefined) {
            throw new Error("Authentication not initialized yet.")
        }

        if (this.isSso) {
            const token = await this.getSsoApiToken();

            return {
                token: token,
                isSso: true
            }
        }
        else {
            const token = await this.getMsalApiToken();

            return {
                token: token,
                isSso: false
            }
        }
    };

    getSsoApiToken = async () : Promise<string> => {
        try {
            const ssoToken = await this.teamsUserCredential?.getToken("");
            if (!ssoToken) {
                throw new Error("No token received from 'getToken' method.");
            }
    
            return ssoToken.token;
        }
        catch (err) {
            log.error(`[getSsoToken] Error occurred while retrieving token for back-end API: ${err}`);
    
            throw new TokenRetrievalError(err, "Error occurred while retrieving token for back-end API");                   
        }        
    }

    getMsalApiToken = async () : Promise<string> => {
        return await this.getMsalToken(appConfig.apiScopes);
    }

    getMsalGraphToken = async () : Promise<string> => {
        return await this.getMsalToken([".default"]);
    }

    getMsalToken = async (scopes: string[]) : Promise<string> => {
        const activeAccount = this.publicClientApplication?.getActiveAccount();
        if (!activeAccount) {
            throw new Error("No active MSAL account found.");
        }

        const accessTokenRequest = {
            scopes: scopes,
            account: activeAccount
        };

        try {
            const accessTokenResponse = await this.publicClientApplication?.acquireTokenSilent(accessTokenRequest);               
    
            if (!accessTokenResponse) {
                throw new Error("No token received from 'acquireTokenSilent' method."); 
            }

            log.trace("[getMsalToken] Accesstoken for back-end API successfully retrieved!");        
    
            return accessTokenResponse.accessToken;
        }
        catch (err) {
            log.error(`[getMsalToken] Error occurred while retrieving token for back-end API: ${err}`);

            if (err instanceof InteractionRequiredAuthError || err instanceof BrowserAuthError) {
                log.error(`[getMsalToken] Current session of the user is no longer valid, resetting state to 'Not authenticated'.`);                
                this.updateAuthenticationState(AuthenticationStates.NotAuthenticated);
            }
    
            throw new TokenRetrievalError(err, "Error occurred while retrieving token for back-end API");                   
        }
    }

    loginRedirect = (postRedirectUrl: string) => {
        if (!this.publicClientApplication) {
            throw new Error("loginRedirect method only allowed when application is not running inside Teams.");
        }

        this.clearLastError();

        if (postRedirectUrl) {
            sessionStorage.setItem("postRedirectUrlKey", postRedirectUrl);
        } else {
            sessionStorage.removeItem("postRedirectUrlKey");
        }

        const loginRequest: RedirectRequest = {
            prompt: "select_account",
            scopes: [".default"]
        }
    
        this.publicClientApplication.loginRedirect(loginRequest);
    }

    logoutRedirect = () => {
        if (!this.publicClientApplication) {
            throw new Error("logoutRedirect method only allowed when application is not running inside Teams.");
        }

        this.clearLastError();

        sessionStorage.removeItem("postRedirectUrlKey");

        const logoutRequest: EndSessionRequest = {
            postLogoutRedirectUri: urls.home
        }

        this.publicClientApplication.logoutRedirect(logoutRequest);
    }

    isSSO = () : boolean  => {
        if (this.isSso === undefined) {
            throw new Error("Authentication not initialized yet.")
        }

        return this.isSso; 
    }

    tryDownloadProfilePicture = async () => {
        try {
            var graphToken = await this.getMsalGraphToken();
            const result = await getProfilePicture(graphToken);
            const imageUrl = URL.createObjectURL(result);

            this.setState({
                profilePicture: imageUrl
            });
        }
        catch (error) {
            log.error(`[tryDownloadProfilePicture] Error during downloading of profile picture: ${error}`);
        }
    }

    updateAuthenticationState = (authenticationState: AuthenticationStates, displayName: string = "", preferredUserName: string = "") => {
        // Use the UserPrincipalName in case no display name is present.
        if (!displayName) {
            displayName = preferredUserName;
        }
        
        this.setState({
            authenticationState: authenticationState,
            displayName: displayName,
            preferredUserName: preferredUserName
        })
    }

    setLastError = (error: any) => {
        const message = extractErrorMessage(error);

        this.setState({
            lastAuthenticationErrorMessage: message
        });
    }

    clearLastError = () => {
        this.setState({
            lastAuthenticationErrorMessage: ""
        });
    }

    render() {
        return (
            <AuthenticationContext.Provider value={{
                authenticationState: this.state.authenticationState,
                displayName: this.state.displayName,
                preferredUserName: this.state.preferredUserName,
                lastAuthenticationErrorMessage: this.state.lastAuthenticationErrorMessage,
                profilePicture: this.state.profilePicture,
                getApiToken: this.getApiToken,
                loginRedirect: this.loginRedirect,
                logoutRedirect: this.logoutRedirect,
                isSSO: this.isSSO
            }}>
                {this.props.children}
            </AuthenticationContext.Provider>
        )
    }
}