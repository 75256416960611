import { Component } from 'react';
import { Button, Flex, ChevronDownMediumIcon, ChevronEndMediumIcon } from '@fluentui/react-northstar'
import LocationSelectorDialog, { OnConfirmEventArgs } from '../../map/LocationSelectorDialog';
import { GeographyPointsFacetValue } from './Types';
import { connectTeamsTheme } from "./../../../context/connectTeamsTheme";
import { getStyle } from '../../ThemeLogic';
 

interface GeographyPointsFacetTemplateProps {
    name: string; //The field name
    facetData: any;
    facetSelection: GeographyPointsFacetValue | null;
    context?: any;
    displayName: string;
    onChangeFacet(facetName: string, facetValue: GeographyPointsFacetValue ) : void;
}
interface GeographyPointsFacetTemplateState {
    isExpanded: boolean;
    isDialogOpen: boolean;
    longitude: number;
    latitude: number;
    radius: number;
}

class GeographyPointsFacetTemplate extends Component<GeographyPointsFacetTemplateProps, GeographyPointsFacetTemplateState> {
    constructor(props: GeographyPointsFacetTemplateProps) {
        super(props);

        this.state = {
            isExpanded: false,
            isDialogOpen: false,
            longitude: 0,
            latitude: 0,
            radius: 0
        }
    }

    handleSectionExpand = () => this.setState(prevState => ({ isExpanded: !prevState.isExpanded }))

    openDialog = () => this.setState({isDialogOpen: true}) 

    onConfirm = (eventArgs: OnConfirmEventArgs) => {
        this.setState({
            isDialogOpen: false,
            latitude: eventArgs.latitude,
            longitude: eventArgs.longitude,
            radius: eventArgs.radius
        });
        this.props.onChangeFacet(this.props.name, eventArgs);
    }

    onClose = () => {
        this.setState({
            isDialogOpen: false,

        });
    }

    render() {        
        // The facetSelection is an array of strings that should be shown as checked.
        let facetSelection: GeographyPointsFacetValue | null  = this.props.facetSelection ;
        
        const { handleSectionExpand, state } = this;
        // is isExpanded we display the section
        const { isExpanded } = state;
        const { style } = this.props.context;

        const teamsContext = { ...this.props.context.theme.siteVariables, style: this.props.context.style }
        const selectLocationButtonStyle = {
            ...getStyle(teamsContext).button,
        }
        
        return (
            <section className={`search-fragment ${isExpanded ? 'expanded' : ''} ${style === 0 ? 'light' : ''}`}>
                <header className={`${facetSelection && ('active')}`} onClick={() => handleSectionExpand()}>
                    <Button
                        iconOnly
                        icon={isExpanded ? <ChevronDownMediumIcon size="large" /> : <ChevronEndMediumIcon size="large" />}
                        styles={{ minWidth: "none", maxWidth: 'none' }}
                    />
                    {this.props.displayName}
                </header>
                <div className="support-content">
                    {facetSelection &&
                        <>
                            <Flex gap="gap.small" column={true}>
                                <div>Latitude: {facetSelection.latitude}</div>
                                <div>Longitude: {facetSelection.longitude}</div>
                                <div>Radius: {facetSelection.radius}</div>
                                <div></div>
                            </Flex>
                        </>
                    }
                    <Button primary style={selectLocationButtonStyle} content="Select location" onClick={this.openDialog} />
                    <LocationSelectorDialog open={this.state.isDialogOpen} onConfirm={this.onConfirm} onClose={this.onClose}></LocationSelectorDialog>
                </div>
            </section>
        )
    }    
}
const GeographyPointsFacet = connectTeamsTheme(GeographyPointsFacetTemplate);
export default GeographyPointsFacet; 


