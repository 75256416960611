import {mediasWithSummarization} from '../types/Summarization';

export class MediaDetail {
    public readonly mediaId: string;
    public readonly tenantId: string;
    public readonly owner: OwnerEntity;
    public readonly timeStart: Date;
    public readonly timeStop: Date;
    public readonly duration: number;
    public readonly mediaTypes: string;
    public readonly sourceType: string;
    public readonly recovered: boolean;
    public readonly participants: ParticipantsEntity[];
    public readonly contentReferences: ContentReferencesEntity[];
    private readonly eventsUnParsed: EventsEntity[];
    private eventsParsed: boolean = false;
    private _events: EventsEntity[];

    //For performance reasons we only parse the events when the are requested. We could implement this behavior also for other arrays when needed.
    public get events() {
        if (!this.eventsParsed) {
            if (this.eventsUnParsed) {
                for (let i = 0; i < this.eventsUnParsed.length; i++) {
                    this._events[i] = EventsEntity.Create(this.eventsUnParsed[i]);
                }
            }

            this.eventsParsed = true;
        }
        return this._events;
    }

    public readonly direction: string;
    public readonly callId: string;
    public readonly globalCallId: string;
    public static Parse(d: string): MediaDetail {
        return MediaDetail.Create(JSON.parse(d));
    }

    public static Create(d: any): MediaDetail {
        d.owner = OwnerEntity.Create(d.owner);

        if (d.participants) {
            for (let i = 0; i < d.participants.length; i++) {
                d.participants[i] = ParticipantsEntity.Create(d.participants[i]);
            }
        }
        if (d.participants === undefined) {
            d.participants = null;
        }

        if (d.contentReferences) {
            for (let i = 0; i < d.contentReferences.length; i++) {
                d.contentReferences[i] = ContentReferencesEntity.Create(d.contentReferences[i]);
            }
        }
        if (d.contentReferences === undefined) {
            d.contentReferences = null;
        }

        return new MediaDetail(d);
    }

    private constructor(d: any) {
        this.mediaId = d.mediaId;
        this.tenantId = d.tenantId;
        this.owner = d.owner;
        this.timeStart = new Date(d.timeStart);
        this.timeStop = new Date(d.timeStop);
        this.duration = d.duration;
        this.mediaTypes = d.mediaTypes;
        this.sourceType = d.sourceType;
        this.recovered = d.recovered;

        if (d.participants === null) {
            this.participants = [];
        }
        else {
            this.participants = d.participants;
        }

        this.contentReferences = d.contentReferences;
        this.eventsUnParsed = d.events;
        this.direction = d.direction;
        this.callId = d.callId;
        this.globalCallId = d.globalCallId;

        this._events = [];
    }

    public hasLocationTrace(): boolean{
        const gpsTraceCR =  this.contentReferences.find(cr => cr.type === "gpstrace");

        if (gpsTraceCR){
            return true;
        }
        
        return false;
    }

    public canHavePeople(): boolean{     
        const cfDetectedFaces =  this.contentReferences.find(cr => cr.type === "detected-faces");

        if (cfDetectedFaces){
            return true;
        }
        
        return false;
    }

    public canHaveSummarization(): boolean{                     
        // The if statement below should be removed when the Replay API supports summarization.
        if (mediasWithSummarization.includes(this.mediaId)) {
            return true;
        }

        const cfSummarization =  this.contentReferences.find(cr => cr.type === "summarization");

        if (cfSummarization){
            return true;
        }
        
        return false;
    }
    
}

export class ParticipantsEntity {
    public readonly participantId: string;
    public readonly userId: string;
    public readonly displayName: string;
    public readonly email: string;
    public readonly phoneNumber: string;
    public readonly tenantId: string;
    public readonly type: string;
    public readonly contributions: string;
    public readonly groups: GroupsEntity[] | null;
    
    public static Parse(d: string): ParticipantsEntity {
        return ParticipantsEntity.Create(JSON.parse(d));
    }

    public static Create(d: any): ParticipantsEntity {
        if (d.groups) {
            for (let i = 0; i < d.groups.length; i++) {
                d.groups[i] = GroupsEntity.Create(d.groups[i]);
            }
        }
        if (d.groups === undefined) {
            d.groups = null;
        }
        return new ParticipantsEntity(d);
    }

    private constructor(d: any) {
        this.participantId = d.participantId;
        this.userId = d.userId;
        this.displayName = d.displayName;
        this.email = d.email;
        this.phoneNumber = d.phoneNumber;
        this.tenantId = d.tenantId;
        this.type = d.type;
        this.contributions = d.contributions;
        this.groups = d.groups;
    }
}

export class OwnerEntity {
    public readonly participantId: string;
    public readonly userId: string;
    public readonly displayName: string;
    public readonly email: string;
    public readonly phoneNumber: string;
    public readonly tenantId: string;
    public readonly type: string;
    public readonly contributions: string;
    public readonly groups: GroupsEntity[] | null;

    public static Parse(d: string): OwnerEntity {
        return OwnerEntity.Create(JSON.parse(d));
    }
    
    public static Create(d: any): OwnerEntity {
        if (d.groups) {
            for (let i = 0; i < d.groups.length; i++) {
                d.groups[i] = GroupsEntity.Create(d.groups[i]);
            }
        }
        if (d.groups === undefined) {
            d.groups = null;
        }
        return new OwnerEntity(d);
    }
    private constructor(d: any) {
        this.participantId = d.participantId;
        this.userId = d.userId;
        this.displayName = d.displayName;
        this.email = d.email;
        this.phoneNumber = d.phoneNumber;
        this.tenantId = d.tenantId;
        this.type = d.type;
        this.contributions = d.contributions;
        this.groups = d.groups;
    }
}

export class GroupsEntity {
    public readonly id: string;
    public readonly displayName: string;

    public static Parse(d: string): GroupsEntity {
        return GroupsEntity.Create(JSON.parse(d));
    }

    public static Create(d: any): GroupsEntity {        
        return new GroupsEntity(d);
    }

    private constructor(d: any) {
        this.id = d.id;
        this.displayName = d.displayName;
    }
}

export class ContentReferencesEntity {
    public readonly type: string;
    public readonly reference: string;

    public static Parse(d: string): ContentReferencesEntity {
        return ContentReferencesEntity.Create(JSON.parse(d));
    }

    public static Create(d: any): ContentReferencesEntity {        
        return new ContentReferencesEntity(d);
    }
    private constructor(d: any) {
        this.type = d.type;
        this.reference = d.reference;
    }
}

export class EventsEntity {
    public readonly type: string;
    public readonly timestamp: string;
    public readonly participantId: string;

    public static Parse(d: string): EventsEntity {
        return EventsEntity.Create(JSON.parse(d));
    }

    public static Create(d: any): EventsEntity {
        return new EventsEntity(d);
    }
    private constructor(d: any) {
        this.type = d.type;
        this.timestamp = d.timestamp;
        this.participantId = d.participantId;
    }
}

export class RawSourcesEntity {
    public readonly id: string;
    public readonly references: string[] | null;
    public readonly type: string;
    public readonly participantId: string;
    public readonly timeStart: string;
    public readonly timeStop: string;
    public readonly mediaTimeStampStart: number;
    public readonly mediaTimeStampActivityStart: number;

    public static Parse(d: string): RawSourcesEntity {
        return RawSourcesEntity.Create(JSON.parse(d));
    }
    
    public static Create(d: any): RawSourcesEntity {
        return new RawSourcesEntity(d);
    }
    
    private constructor(d: any) {
        this.id = d.id;
        this.references = d.references;
        this.type = d.type;
        this.participantId = d.participantId;
        this.timeStart = d.timeStart;
        this.timeStop = d.timeStop;
        this.mediaTimeStampStart = d.mediaTimeStampStart;
        this.mediaTimeStampActivityStart = d.mediaTimeStampActivityStart;
    }
}
