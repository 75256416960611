import { Component  } from 'react';
import { Button, Segment } from '@fluentui/react-northstar'
import css from './ErrorMessage.module.scss';

interface ErrorMessageToggleProps {
    errorMessage: string    
}

export class ErrorMessageToggle extends Component<ErrorMessageToggleProps, {}> {
    state = {
        showDetails: false
    }
    
    handleShowDetailsClick = () => {
        this.setState({
            showDetails: !this.state.showDetails
        });
    }

    render() {
        const detailsMessageRendered = this.state.showDetails ? 
            <Segment className={css.detailMessage}>
                <pre>{this.props.errorMessage}</pre>
            </Segment> : 
        <></>

        return (
            <div className={css.details}>
                <Button content="Show details" primary onClick={this.handleShowDetailsClick} />
                <div className={css.paddingTopLarge}>
                    {detailsMessageRendered}
                </div>
            </div>
        )
    }
}
