import MediaReference from './MediaReference';
import * as apiCalls from './apiCalls';

let store = new Map();
const maxCacheSize = 250;
const retrieveHoldOffTimeMs = 100;
const maxBatchRequestSize = 8;
const useRequestBatching = true;

const addThumbnail = (reference, objectUrl) => {
    store.set(reference, objectUrl);

    if (store.size > maxCacheSize) {
        // The order of the entries in the map is maintained. 
        // Look-up the first entry (which is the oldest) and remove it.
        let keyToDelete;
        let objectUrlToRevoke;
        for (let entry of store) {
            [keyToDelete, objectUrlToRevoke] = entry;
            break;
        }

        URL.revokeObjectURL(objectUrlToRevoke);
        store.delete(keyToDelete);
    }
}

const getThumbnail = async (mediaId, contentReference) => {
    // First try to retrieve the thumbnail from the in-memory cache...
    const mediaReference = new MediaReference(mediaId, contentReference);

    const cachedThumbnailObjectUrl = store.get(mediaReference.toString());
    if (cachedThumbnailObjectUrl) {
        return cachedThumbnailObjectUrl;
    } 
    
    // Thumbnail was not found, now retrieve it from the back-end.
    const response = useRequestBatching ? 
        await getThumbnailFromBackend(mediaReference) : 
        await apiCalls.getMediaContent(mediaId, contentReference);

    const objectUrl = URL.createObjectURL(response);   

    // Store the thumbnail in the in-memory cache.
    addThumbnail(mediaReference.toString(), objectUrl);

    return objectUrl;
}

let pendingThumbnailRequests = [];
let timeoutId;
const getThumbnailFromBackend = (mediaReference) => {
    return new Promise((resolve, reject) => {
        pendingThumbnailRequests.push({
            mediaReference: mediaReference,
            resolve: resolve,
            reject: reject
        });

        if (pendingThumbnailRequests.length === 1) {
            // This is the first pending request, start the hold-off timer which will trigger 
            // the retrieval once the time-out has elapsed.
            timeoutId = setTimeout(() => {
                getPendingThumbnailRequestsFromBackend();
            }, retrieveHoldOffTimeMs);
        } 
        else if (pendingThumbnailRequests.length === maxBatchRequestSize) {
            // The maximum size of a batch request has been reached. 
            // Cancel the running hold-off timer and immediately start the retrieval.
            clearTimeout(timeoutId);
            getPendingThumbnailRequestsFromBackend();
        }
    });
}

const getPendingThumbnailRequestsFromBackend = async () => {
    // Create a copy of the pending thumbnail requests and clear the original list,
    // so that new requests can be pending while this batch is being requested at the back-end.
    const thumbnailRequestBatch = [...pendingThumbnailRequests];
    pendingThumbnailRequests = [];

    // Request the thumbnails as a batch at the back-end.
    const mediaReferences = thumbnailRequestBatch.map(item => item.mediaReference);

    console.log(`Starting batch request for ${thumbnailRequestBatch.length} thumbnails...`);
    let errorDuringBackendCall;
    try {
        const result = await apiCalls.selectMediaContent(mediaReferences);    

        for (const [mediaReferenceString, blob] of result) {
            const mediaReferenceFromResult = MediaReference.parse(mediaReferenceString);
    
            // Find the corresponding request which contains the method to resolve the waiting promise.
            const correspondingRequestIndex = thumbnailRequestBatch.findIndex(item => item.mediaReference.equals(mediaReferenceFromResult));
    
            if (correspondingRequestIndex < 0) {
                console.warn(`Returned media (${mediaReferenceString}) not found in request.`)
                continue;
            }
    
            // Resolve the promise with the blob received from the back-end. 
            thumbnailRequestBatch[correspondingRequestIndex].resolve(blob);        
    
            // Remove this processed request from the batch.
            thumbnailRequestBatch.splice(correspondingRequestIndex, 1);
        }
    }
    catch (error) {
        errorDuringBackendCall = error;
    }
    finally {
        // Ensure that the requests that are left in the request batch are rejected. These have failed because of a failure of the request at the back-end, insufficient permissions, 
        // the content that is missing, or any other unforeseen issue.
        for (const entry of thumbnailRequestBatch) {
            const rejectionText = errorDuringBackendCall ?
                `Error occurred while retrieving thumbnails in batch at the back-end: ${errorDuringBackendCall.message}`  :
                `Media with reference '${entry.mediaReference.toString()}' not found in result.`

            entry.reject(rejectionText);
        }
    }

    console.log(`Batch request for thumbnails done.`);
}

export const thumbnailProvider = {    
    getThumbnail: getThumbnail    
}