import { Participant, TextItem, TextHighlight, DominantSpeakerEvent, Transcription, TextDiarizationSegments, AudioSearchHits } from '../types/transcription';
import moment, { Moment } from 'moment';
import log from 'loglevel';

// Method return a dictionary for the translation from the (call unique) participant Id in the mediaInfo
// to the global participant Id  (object Id in Azure).
function createMediaInfoParticipantMap(mediaInfo: any) : Map<string, string> {
    const output = new Map<string, string>();

    for (const participant of mediaInfo.participants) {
        const mediaInfoParticipantId = participant.participantId;
        const globalParticipantId = participant.userId;

        output.set(mediaInfoParticipantId, globalParticipantId);
    }

    return output;
}

export function createDominantSpeakerEvents(mediaInfo: any) : DominantSpeakerEvent[] {
    const participantTranslationMap = createMediaInfoParticipantMap(mediaInfo);
    let timeStart: Moment | undefined = undefined;

    const output: any[] = [];
    for (const event of mediaInfo.events) {
        if (event.type !== 'dominantSpeaker') {
            continue;
        }       

        const timestamp = moment(event.timestamp);
        if (!timeStart) {
            // Use the timestamp of the first dominant speaker event as a reference to calculate the offset.
            // The timeStart of the call cannot be used because this includes the ringing.
            timeStart = timestamp;
        }

        const offsetMs = timestamp.diff(timeStart);
        const participantId = participantTranslationMap.get(event.participantId);

        output.push({
            offsetSeconds: offsetMs / 1000,
            participantId: participantId,            
        });
    }
    if (output.length < 1) {
        // if we do not have any dominant speaker events, push a dummy one
        output.push({
            offsetSeconds: 0,
            participantId: "A",            
        });
    }
    
    return output;
}

export function createParticipants(mediaInfo: any) :  Participant[] {
    if (!mediaInfo.participants || mediaInfo.participants.length === 0) {
        log.warn(`[transcription, createParticipants] No participants found in call with Id ${mediaInfo.mediaId}. Creating single participant based upon the owner of the call as fall-back.`);
        
        const owner = mediaInfo.owner;

        const singleParticipant: Participant = {
            participantId: owner.participantId,
            userId: owner.userId,
            displayname: owner.displayName,
            image: null
        }

        return [singleParticipant];
    }

    const output: Participant[] = [];

    for (const participantInfo of mediaInfo.participants) {
        const newParticipant: Participant = {
            participantId: participantInfo.participantId,
            userId: participantInfo.userId,
            displayname: participantInfo.displayName,
            image: null
        }

        output.push(newParticipant);
    }

    return output;
}

export function createTextItems(transcription: Transcription, diarization: TextDiarizationSegments, audioSearchHits: AudioSearchHits) : TextItem[] {
    const transcriptionContent = transcription.transcription;
    let textItems: TextItem[] = [];
    
    for (let i = 0; i < diarization.diarizationSegments.length; i++) {
        const diarizationSegment = diarization.diarizationSegments[i];
        const isLastSegment = (i === (diarization.diarizationSegments.length - 1));

        // Determine the start and end position of this segment in the transcription.
        const characterOffsetStart = diarizationSegment.characterOffset;
        let characterOffsetEnd = transcriptionContent.length - 1;
        if (!isLastSegment) {
            // Only the character start position of the segment is known. So the start of the next segment is needed to determine the contents of this segment.
            const nextDiarizationSegment = diarization.diarizationSegments[i + 1];
            characterOffsetEnd = nextDiarizationSegment.characterOffset;  
        }

        let content = transcriptionContent.substring(characterOffsetStart, characterOffsetEnd);
        if (content.length === 0) {
            continue; // Empty text items are ignored.
        }

        // Determine the search hits that fall within this current segment.
        // ToDo: Find out if a search hit can fall in two segments.
        const relevantAudioSearchHits = audioSearchHits.searchHits.filter(searchHit => searchHit.characterOffset >= characterOffsetStart && searchHit.characterOffset < characterOffsetEnd)
        const highlights = relevantAudioSearchHits.map(item => {
            const absoluteHighlightStartPosition = item.characterOffset;
            const absoluteHighlightEndPosition = item.characterOffset + item.phrase.length - 1;
            const absoluteHighlightTimeOffset = item.timeOffset;

            // Note: The positions and offset are relative to the contents of this current segment.
            return {
                startPosition: absoluteHighlightStartPosition - characterOffsetStart,
                endPosition: absoluteHighlightEndPosition - characterOffsetStart,
                offsetSeconds: absoluteHighlightTimeOffset - diarizationSegment.timeOffset
            } as TextHighlight
        });

        let textItem: TextItem = {
            offsetSeconds: diarizationSegment.timeOffset,            
            participantId: diarizationSegment.userId, // Note: although the name is userId, its value it actually the participantId.
            content: content,
            highlights: highlights
        }

        textItems.push(textItem);
    }

    return textItems;  
}