import { ServerErrorResponse } from '../utils/errors';
import { appConfig } from './appConfigProvider';
import MediaReference from './MediaReference';
import * as utils from '../utils/misc';
import log from 'loglevel';
import { Transcription, TextDiarizationSegments, TextSearchHits, AudioSearchHits } from '../types/transcription';
import { MediaDetail } from '../types/MediaDetail';
import { FacetConfiguration } from '../types/Facet';
import { FieldConfiguration } from '../types/Field';
import { DetectedFaces } from '../types/Faces';
import { Summarization } from '../types/Summarization';
import defaultSummarizationData from './mockedApiData/getSummarization-default.json';
import {mediasWithSummarization} from '../types/Summarization';

export interface SearchRequest{
    top: number;
    skip: number;
    query: string;
    sortBy: string;
    orderBy: string[];
    filter: string;
    sortOrder: string;
    searchFields: string[];
    facets: string[];
    select: string[];
    searchMode: string;
    includeTotalResultCount: boolean;    
}

export interface AutoCompleteRequest {
    top: number;
    query:string;
    searchFields: string[];
}

export type ApiTokenResponse = {
    token: string,
    isSso : boolean
}

export interface AuthorizeResponse
{
    isTenantKnown: boolean,
    isReplayEnabled?: boolean,
    userPermissions: string[]
}

export type ApiTokenProviderCallback = () => Promise<ApiTokenResponse>;

let apiTokenProviderCallback: ApiTokenProviderCallback | null = null;

// Method registers the provided callback which is invoked for every request for which a token is required.
export function registerApiTokenProviderCallback(callback: ApiTokenProviderCallback) : void {
    apiTokenProviderCallback = callback;
}

// Method clears the callback which is invoked for every request for which a token is required.
export function clearApiTokenProviderCallback() : void {
    apiTokenProviderCallback = null;
}

interface AccessToken {
    token: string;
    expiresOn: string;
}

async function executeJsonMethodOnApi(method: string, relativeApiPath: string, jsonBody: any = null): Promise<any> {
    const response = await executeMethodOnApi(method, relativeApiPath, jsonBody);
    return await response.json();
}

async function executeBlobMethodOnApi(method: string, relativeApiPath: string, jsonBody: any = null): Promise<Blob> {
    const response = await executeMethodOnApi(method, relativeApiPath, jsonBody);
    return await response.blob();
}

async function executeMethodOnApi(method: string, relativeApiPath: string , jsonBody: any = null): Promise<Response> {  
    try {
        log.debug(`[executeCallOnBackend] Invoking '${method}' on '${relativeApiPath}'...`)

        if (!apiTokenProviderCallback) {
            throw new Error("No API token provider callback has been registered.")
        }

        const apiTokenResponse = await apiTokenProviderCallback();
        if (!apiTokenResponse) {
            throw new Error("No API token received from token provider callback");
        }
    
        const basePath = apiTokenResponse.isSso ? 'replay-api/app' : 'replay-api';
        const url = `https://${appConfig.replayApiHostname}/${basePath}/${relativeApiPath}`;
        
        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.set('Authorization', `Bearer ${apiTokenResponse.token}`);

        const requestParameters: RequestInit = {
            method: method,
            headers: requestHeaders
        };
    
        // Add the body if one was provided.
        if (jsonBody) {
            requestHeaders.set('Content-Type', 'application/json');
            requestParameters.body = JSON.stringify(jsonBody);
        }
    
        const response = await fetch(url, requestParameters);
    
        if (!response.ok) {
            // Some non-success response received.
            throw new ServerErrorResponse(null, response.status, response.statusText, await response.text());
        }
    
        // A success response is received from the back-end.
        return response;
    }
    catch (error: any) {
        if (error instanceof ServerErrorResponse) {
            const responseBody = error.responseBody ? `\r\n${error.responseBody}` : "";
            log.error(`Server error response received while invoking '${method}' on '/${relativeApiPath}' endpoint. Received ${error.statusCode} '${error.statusText}'.${responseBody}`);
        } else {
            log.error(`Error occurred while invoking '${method}' on '/${relativeApiPath}': ${error.message}`);    
        }

        throw (error);
    }
}

export async function getVersion(): Promise<any> {
    return await executeJsonMethodOnApi('GET', 'version', null);
}

export async function getAuthorize(): Promise<AuthorizeResponse> {
    return await executeJsonMethodOnApi('GET', 'v2/authorize', null);
}

export async function getFacets(): Promise<FacetConfiguration> {    
    return await executeJsonMethodOnApi('GET', `v2/facets`, null); 
}

export async function getFields(): Promise<FieldConfiguration> {    
    return await executeJsonMethodOnApi('GET', `v2/configuration/fields`, null); 
}

export async function getSorters(): Promise<any> {
    return await executeJsonMethodOnApi('GET', `v2/sorters`, null); 
}

export async function search(searchRequest: SearchRequest): Promise<any> {
    return await executeJsonMethodOnApi('POST', `v2/search`, searchRequest); 
}

export async function autoComplete(autoCompleteRequest: any): Promise<any> {
    return await executeJsonMethodOnApi('POST', `v2/autocomplete`, autoCompleteRequest); 
}

export async function getMediaInfo(mediaId: any): Promise<MediaDetail> {
    let json: string = await executeJsonMethodOnApi('GET', `v2/media/${mediaId}/info`, null);
    let mediaDetail: MediaDetail = MediaDetail.Create(json);
    return mediaDetail;
}

export async function getMediaContentUrl(mediaId: string, contentReference: string, validForSeconds: number): Promise<any> {
    return await executeJsonMethodOnApi('GET', `v2/media/${mediaId}/contenturl?reference=${contentReference}&validforseconds=${validForSeconds}`, null);
}

export async function getMediaContent(mediaId: string, contentReference: string): Promise<any> {
    return await executeBlobMethodOnApi('GET', `v2/media/${mediaId}/content?reference=${contentReference}`, null);
}

export async function selectMediaContent(mediaReferences: string[]): Promise<any> {
    const body = {
        mediaContentReferences: mediaReferences
    }

    const result = await executeJsonMethodOnApi('POST', `v2/media/content/select`, body);

    const mediaContent = result.mediaContent;
    let resultMap = new Map();

    for (const entry of mediaContent) {
        const mediaReference = new MediaReference(entry.mediaId, entry.contentReference);
        const blob = utils.b64toBlob(entry.contentBase64);

        resultMap.set(mediaReference.toString(), blob);
    }

    return resultMap;
}

export async function getTranscription(mediaId: string): Promise<Transcription> {
    return await executeJsonMethodOnApi('GET', `v2/media/${mediaId}/content/transcription`, null);
}

export async function getTextDiarization(mediaId: string): Promise<TextDiarizationSegments> {
    return await executeJsonMethodOnApi('GET', `v2/media/${mediaId}/content/textdiarization`, null);
}

export async function getTextSearchHits(mediaId: string, search: string): Promise<TextSearchHits> {
    const searchEscaped = encodeURIComponent(search);

    return await executeJsonMethodOnApi('GET', `v2/media/${mediaId}/content/textsearchhits?search=${searchEscaped}`, null);
}

export async function getAudioSearchHits(mediaId: string, search: string): Promise<AudioSearchHits> {
    const searchEscaped = encodeURIComponent(search);

    return await executeJsonMethodOnApi('GET', `v2/media/${mediaId}/content/audiosearchhits?search=${searchEscaped}`, null);
}

export async function getGPSTrace(mediaId: string): Promise<any> {
    return await executeJsonMethodOnApi('GET', `v2/media/${mediaId}/gpstrace`, null);
}

export async function getAzureMapsToken(): Promise<AccessToken> {
    return await executeJsonMethodOnApi('GET', `v2/tokens/azuremaps`, null);
}

export async function getDetectedFaces(mediaId: string): Promise<DetectedFaces> {
    return await executeJsonMethodOnApi('GET', `v2/media/${mediaId}/detectedfaces`, null);    
}

export async function getSummarization(mediaId: string): Promise<Summarization> {    
    if (mediasWithSummarization.includes(mediaId)) {
        // This code should be removed when the Replay API supports summarization.
        return new Promise<Summarization>(
            (resolve) => {
                let data = defaultSummarizationData;

                if (mediasWithSummarization.includes(mediaId)) {
                    data = require(`./mockedApiData/getSummarization-${mediaId}.json`);
                }
                
                resolve(data);
            });
    }
    else {
        return await executeJsonMethodOnApi('GET', `v2/media/${mediaId}/content/summarization`, null);
    }
    
}