import {} from './globals'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import './assets/styles/index.scss';
import './logic/backgroundColorUpdater';

import { appConfig } from './logic/appConfigProvider';
import log from 'loglevel';

log.setDefaultLevel(appConfig.logLevel);

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
  document.getElementById('root')
);

window.addEventListener('load', () => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistration().then((registration) => { if (registration) registration.unregister(); });
    }
});