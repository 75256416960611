export function isFunction(functionToCheck: any) : boolean {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

export const isTrue = (value: any) : boolean => {
    if (value === undefined) {
        return false;
    }

    if (typeof (value) === "boolean") {
        return value;
    }

    if (!(typeof (value) === "string")) {
        return false;
    }

    const lowerCaseValue = value.toLowerCase();
    return lowerCaseValue === 'true' || lowerCaseValue === 'yes';
}

export function sleep(ms: number) :Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function trimRight(input: string, charlist: string | undefined) : string {
    if (charlist === undefined) {
        charlist = "\\s";
    }

    return input.replace(new RegExp("[" + charlist + "]+$"), "");
};

export function trimLeft(input: string, charlist: string | undefined) : string {
    if (charlist === undefined)
    {
        charlist = "\\s";
    }

    return input.replace(new RegExp("^[" + charlist + "]+"), "");
};

export function isRunningInIframe() : boolean {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }    
}

export function isEmpty(value: any) : boolean {
    return value === undefined || value === null;
}

export function getBaseUriWithoutParameters() {
    let urlString = document.getElementById('root')?.baseURI;

    if (!urlString) {
        throw new Error("Could not determine Base URI.");
    }

    let url = new URL(urlString);

    return url.origin;
}

// Returns the value of a query variable in the URL or null when the variable was not found.
export const getQueryVariableFromUrl = (variable: string) : string | null => {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (const varPairs of vars) {
        const pair = varPairs.split('=');
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }

    return null;
}

export function urlCombine(...urlFragments: string[]) : string {
    let url = urlFragments[0];

    for (let i = 1; i < urlFragments.length; i++) {
        const urlFragment = urlFragments[i];

        url = trimRight(url, '/') + '/' + trimLeft(urlFragment, '/');
    }

    return url;
}

export const b64toBlob = (b64Data: string, contentType='', sliceSize=512) : Blob => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
}

export const isDebugEnabled = () : boolean => {
    return window.localStorage['debug'] === 'true';
}

export const toggleDebug = () : void => {
    const newToggledValue = isDebugEnabled() ? 'false' : 'true';

    window.localStorage['debug'] = newToggledValue;
}