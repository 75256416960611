import * as graph from '@microsoft/microsoft-graph-client';

function getGraphClient(accessToken: string) {
    // Initialize Graph client
    const client = graph.Client.init({
        // Use the provided access token to authenticate requests
        authProvider: (done) => {
            done(null, accessToken);
        },
        defaultVersion: 'beta'
    });

    return client;
}

export async function getUserDetailsOfLoggedInUser(accessToken: string) {
    const client = getGraphClient(accessToken);

    const url = `me`;
    return await client.api(url).get();
}

export async function getProfilePicture(accessToken: string) {
    const client = getGraphClient(accessToken);

    const url = `me/photo/$value`;
    return await client.api(url).get();
}

export async function getOrganizationDetails(accessToken: string) {
    const client = getGraphClient(accessToken);

    const url = `organization`;
    return await client.api(url).get();
}