export function isIOS() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    
    return /ip(hone|od|ad)/.test( userAgent );
}

export function isMobile() {
    const userAgent = window.navigator.userAgent.toLowerCase();

    return (/mobile|ip(hone|od|ad)|android|blackberry|iemobile|kindle|netfront|silk-accelerated|(hpw|web)os|fennec|minimo|opera m(obi|ini)|blazer|dolfin|dolphin|skyfire|zune/.test(userAgent));
};

export function isFullscreenEnabled() {
    return document.fullscreenEnabled ||
           document.webkitFullscreenEnabled ||
           document.mozFullScreenEnabled ||
           document.msFullscreenEnabled || 
           false;
}