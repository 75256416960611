import React, { Component } from 'react';
import './RecordingInfoTab.scss'
import * as timeDisplay from '../../logic/timeDisplay';
import { connectTeamsTheme } from "./../../context/connectTeamsTheme";
import { toDisplayString } from '../../logic/displayString';
import { Avatar } from '@fluentui/react-northstar'
import { TimelineContext } from '../../context/TimelineContext';

class RecordingInfoTabInternal extends Component {

    static contextType = TimelineContext;

    state = {
        id: '',
        timeLine: [],
        emptyTimeLine: false
    }

    componentDidMount(){
        this.createTimeline(this.props.textItems)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props && !this.state.timeLine.length) {
            this.createTimeline(this.props.textItems)
        }
    }

    componentWillUnmount() {
        if (this.state.id) {
            this.context.addEvents([])
        }
    }

    createTimeline = (particapents) => {

        const setTaiming = particapents.map(({offsetSeconds, participantId}) => ({offsetSeconds, participantId}))
        const duration = []

        for (let i = 1; i <= setTaiming.length-1; i++ ) {
            setTaiming[i-1].timeToStart = setTaiming[i-1].offsetSeconds
            setTaiming[i-1].timeToEnd = setTaiming[i].offsetSeconds
            duration[i-1] = setTaiming[i-1]
        }

        this.setState({timeLine: duration})
    }

    createTimeLinesEvent = (id, particapentName) => {

        if (this.state.id === id) {
            this.setState({id: ''})
            return this.context.addEvents([])
        }

        if (!this.state.timeLine.length) {
            return; 
        }

        const createLine = this.state.timeLine.reduce((a,b) => {
            if(!Array.isArray(a))
                a = [a];

            let last = a[a.length - 1];

            if(!Array.isArray(last) && last.participantId === b.participantId) {
                a[a.length - 1] = [last, b]
            }else if(Array.isArray(last) && last[0].participantId === b.participantId) {
                a[a.length - 1].push(b)
            }else {
                a.push(b);
            }

            return a;
        });


        const lines = Array.isArray(createLine) ? createLine.map(item => {
            if (Array.isArray(item)) {
                const last = item.pop()
                return {...item[0], timeToStart: item[0].timeToStart, timeToEnd: last.timeToEnd}
            }
            return item
        }) : [createLine]


        const particapentTimeLines = lines.filter(el => el.participantId === id)

        if(!particapentTimeLines.length) {
            this.setState({emptyTimeLine: true})
        }else {
            this.setState({emptyTimeLine: false})
        }

        const setTimeToZero = (num) => num >= 0 && num < 10 ? `0${num}` : num
        const transformTime = (sec) => {
            const hours = Math.floor(sec / 60 / 60);
            const minutes = Math.floor(sec/ 60) - (hours * 60);
            const seconds = sec % 60;

            return `${setTimeToZero(hours)}:${setTimeToZero(minutes)}:${setTimeToZero(+seconds.toFixed())}`
        }

        const eventTimeLines = particapentTimeLines.map(({timeToStart, timeToEnd}) => {
            const startTime = transformTime(timeToStart)
            const endTime = transformTime(timeToEnd)

            return {
                offsetSeconds: timeToStart,
                durationInSecond: timeToEnd - timeToStart,
                getTooltipTitle: () => particapentName,
                getTooltipContent: () => {
                    return <span>From {startTime.toString()} to {endTime.toString()}</span>;
                }
            }
        })
        return this.context.addEvents(eventTimeLines);
    }

    handleOnClick = (id, particapentName) => {
        this.setState({id: id})
        this.createTimeLinesEvent(id, particapentName)
    }

    displayNameOrPhoneNumber = (displayName, phoneNumber) => {
        return displayName?.length > 0 ? displayName : phoneNumber;
    }

    renderParticipant = (participantId, displayName, phoneNumber) => {
        return phoneNumber?.length > 0 ?
            <>
                <div className="option-icon material-icons">phone_iphone</div>
                <p className="width-100">{phoneNumber}</p>
            </> :
            <>
                <div className={`participant-avatar ${this.state.id === participantId ? (this.state.emptyTimeLine ? 'empty' : 'active') : ''}`}>
                        <Avatar name={this.displayNameOrPhoneNumber(displayName, phoneNumber)} />
                </div>
                <p className="width-100">{displayName}</p>
            </>
    }

    render() {
        if (!this.props.mediaInfo.mediaId) {
            return null;
        }
        const { isPanelOpen } = this.props;

        return (
            <section className={`video-side-bar ${isPanelOpen && ('active')}`}>
                { 
                    this.props.mediaInfo.owner.displayName?.length > 0 && (
                        <div className="row">
                            <div className="title">User</div>
                            <div className="description"><b>{this.props.mediaInfo.owner.displayName}</b></div>
                        </div>)
                }
                {
                    this.props.mediaInfo.owner.email?.length > 0 && (
                        <div className="row">
                            <div className="title">E-mail</div>
                            <div className="description">{this.props.mediaInfo.owner.email}</div>
                        </div>)
                }
                {
                    this.props.mediaInfo.owner.phoneNumber?.length > 0 && (
                        <div className="row">
                            <div className="title">Phone number</div>
                            <div className="description">{this.props.mediaInfo.owner.phoneNumber}</div>
                        </div>)
                }
                <div className="row">
                    <div className="title">Direction</div>
                    <div className="description">{toDisplayString(this.props.mediaInfo.direction)}</div>
                </div>
                <div className="row">
                    <div className="title">Time start</div>
                    <div className="description">{timeDisplay.dateStringToDisplayString(this.props.mediaInfo.timeStart)}</div>
                </div>
                <div className="row">
                    <div className="title">Time stop</div>
                    <div className="description">{timeDisplay.dateStringToDisplayString(this.props.mediaInfo.timeStop)}</div>
                </div>
                <div className="row">
                    <div className="title">Duration</div>
                    <div className="description">{timeDisplay.durationToDisplayString(this.props.mediaInfo.duration)}</div>
                </div>
                <div className="row">
                    <div className="title">Global call Id</div>
                    <div className="description">{this.props.mediaInfo.globalCallId}</div>
                </div>
                <div className="row">
                    <div className="title">Call Id</div>
                    <div className="description">{this.props.mediaInfo.callId}</div>
                </div>
                <div className="row">
                    <div className="title">Media Id</div>
                    <div className="description">{this.props.mediaInfo.mediaId}</div>
                </div>
                {
                    this.props.mediaInfo.participants.length > 0 && (
                        <div className="row">
                            <div className="title">Participants</div>
                            <div
                                className="description">
                                {this.props.mediaInfo.participants
                                    .map(({participantId, displayName, phoneNumber}) => (  
                                        <div className='desc-cont'
                                             key={participantId}
                                             onClick={() => this.handleOnClick(participantId, displayName)}>

                                            { this.renderParticipant(participantId, displayName, phoneNumber) }
                                        </div>))}
                            </div>
                        </div>
                    )
                }
            </section>
        );
    }
}

export const RecordingInfoTab = connectTeamsTheme(RecordingInfoTabInternal);

