import React from 'react'
import {connectTeamsTheme} from './../../context/connectTeamsTheme'
import { style } from 'typestyle';

const customTabs = (props) => {
    const {context, tabs} = props
    const colors = ['#5c5b5b', '#c7c6c6', '#ffffff']
    const tabTextColor = colors[context.style]?.toString()

    const styleTabs = style({
        borderBottom: `0.06666666666666667rem solid ${context.theme.siteVariables.colorScheme.brand.foregroundDisabled1}`,
        margin: 0,
        padding: 0,
        width: '100%',
        paddingBottom: '10px',
    })

    const styleButton = style({
        color: tabTextColor,
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        marginRight: '1.3333rem',
        padding: '0.26666rem',
        borderBottom: '0.266rem solid transparent',
        '&:hover': {
            borderBottomColor: context.theme.siteVariables.colorScheme.brand.foreground1,
        }
    })

    const renderTab = (item) => {
        const selected = props.selectedTabId === item.id;

        const activeButton = style({
            borderBottom: `0.266rem solid ${context.theme.siteVariables.colorScheme.brand.borderFocus1}`,
            color: context.theme.siteVariables.colorScheme.brand.borderFocus1
        })

        return (
            <button
                className={`${styleButton} ${selected ? activeButton : ''}`}
                tabIndex={selected ? 0 : -1}
                key={item.id}
                onMouseDown={(e) => e.preventDefault()}
                onClick={item.onSelect}
                role="tab">
                {item.text}
            </button>
        );
    };

    return (
        <div
            className={styleTabs}
            role="tablist">
            {tabs.map(renderTab)}
        </div>
    )
}

export const Tabs = connectTeamsTheme(customTabs)