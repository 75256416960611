export interface SystemSettings {
    displayName: string;
    type: string;
    isFilterable: boolean;
    isFacetable: boolean;
    isSortable: boolean;
    filterClause: string;
    isSearchable: boolean;
    supportSuggestions: boolean;
}

export interface UserSettings {
    displayName: string;
    showInFacetArea: boolean;
    orderInFacetArea: number;
    showInDetailsPane: boolean;
    orderInDetailsPane: number;
    showInSearchInFieldsSelector: boolean;
}

export interface Field {
    name: string;
    systemSettings: SystemSettings;
    userSettings: UserSettings;
}

export interface FieldConfiguration {
    fields: Field[];
}

export function isTranscriptionField(name: string){
    return name === "SearchableContent/Transcription/Transcription";
}