import React, { Component, useContext } from 'react';
import { Button } from '@fluentui/react-northstar'
import { AuthorizationContext, AuthorizationContextInterface } from '../../context/AuthorizationContext';
import { ErrorMessageToggle } from './ErrorMessageToggle';
import css from './ErrorMessage.module.scss';

interface AuthorizationFailureInternalProps {
    authorizationContext: AuthorizationContextInterface    
}

class AuthorizationFailureInternal extends Component<AuthorizationFailureInternalProps, {}> {
    handleLogout = () => {
        this.props.authorizationContext.logoutRedirect();
    }

    render() {
        const logoutRendered = !this.props.authorizationContext.isSSO() ?
            <>
                <p className={css.paddingTop}>Use the button below to logout and login as a different user.</p>
                <div className={css.paddingTop}>
                    <Button content="Logout" primary onClick={this.handleLogout} />
                </div>
            </> : null

        return (
            <div className={css.main}>
                <p><strong>Error occurred during the authorization.</strong></p>                
                <p>Please try again later.</p>                
                {logoutRendered}
                <ErrorMessageToggle errorMessage={this.props.authorizationContext.lastAuthorizationErrorMessage} />
            </div>
        )
    }
}

// Inject the required contexts.
export const AuthorizationFailure: React.FunctionComponent = () => {
    const authorizationContext = useContext(AuthorizationContext);
    if (!authorizationContext) {
        throw new Error("No AuthorizationContext found in React tree.");
    }

    return (
        <AuthorizationFailureInternal authorizationContext={authorizationContext} />
    )
}