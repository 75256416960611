import React, { Fragment, useState, useEffect } from 'react';
import './TeamsRecordingSearchResult.scss';
import { urls } from '../../logic/urls';
import { thumbnailProvider } from '../../logic/thumbnailProvider';
import { toDisplayString } from '../../logic/displayString';
import {  Button, ArrowLeftIcon, ArrowRightIcon } from '@fluentui/react-northstar'

export const TeamsRecordingSearchResult = ({ document, onClick }) => {
    const [thumbnailUrl, setThumbnailUrl] = useState("");

    useEffect(() => {
        async function getThumbnail() {
            // 'Audio only' media always have the same thumbnail, so it it al lot of overhead to retrieve this from the back-end. 
            // For this reason the 'audio only' thumbnail is directly retrieved from the webserver (and thus will be cached by the browser).
            if (!document.mediaTypes.includes("video") && !document.mediaTypes.includes("screen")) {
                setThumbnailUrl(urls.thumbnailAudio);
                return;
            }

            if (document.playAllowed === false) {
                setThumbnailUrl(urls.thumbnailNoContentReference);
                return;
            }

            let thumbnailReference = document.contentReferences.find(item => item.type === "thumbnail");
            if (!thumbnailReference) {
                setThumbnailUrl(urls.thumbnailNoContentReference);
                return;
            }

            try {
                const objectUrl = await thumbnailProvider.getThumbnail(document.mediaId, thumbnailReference.reference);
                setThumbnailUrl(objectUrl);
                return;
            }
            catch (error) {
                setThumbnailUrl(urls.thumbnailRetrievalError);
                return;
            }
        }

        getThumbnail();
    }, [document.contentReferences, document.mediaId, document.mediaTypes, document.playAllowed]);

    const handleOnClick = () => {
        if (document.playAllowed) {
            onClick(document.mediaId);
        }
    }

    const thumbnailUrlToDisplay = thumbnailUrl !== "" ? thumbnailUrl : urls.thumbnailPlaceholder;
    const showThumbnail = thumbnailUrl !== "";

    let topLineRendered;
    if (document.showArrow) {
        const iconType = document.showLeftArrow ? <ArrowLeftIcon /> : <ArrowRightIcon />;

        topLineRendered = <>
            {document.ownerName}
            <Button iconOnly icon={iconType} style={{ height: '11px', padding: '0' }} />
            {document.otherParticipantName}
        </>
    }
    else {
        topLineRendered = <>{document.topLine}</>;
    }

    let sourceTypeIcon;
    switch (document.sourceType) {
        case "teams":
        case "bodyCam":
        case "cybergate":
            sourceTypeIcon = <div className={`option-icon ${document.sourceType}`} title={toDisplayString(document.sourceType)} />;
            break;
        case "fixedLine":
            sourceTypeIcon = <div className="option-icon material-icons" title={toDisplayString(document.sourceType)}>phone</div>;
            break;
        case "mobile":
            sourceTypeIcon = <div className="option-icon material-icons" title={toDisplayString(document.sourceType)}>phone_iphone</div>;
            break;
        default:
            sourceTypeIcon = null;
            break;
    }

    return (
        <Fragment>
            <section className="result-item">
                <div onClick={handleOnClick} className="thumbnail-support">
                    <img className={`${showThumbnail ? "displayThumbnail" : ""}`} src={thumbnailUrlToDisplay} alt="Thumbnail" />
                    {document.playAllowed ?
                        <img className={`overlay shadowed`} src={urls.thumbnailOverlay} alt="Overlay" /> :
                        <></>
                    }
                </div>
                <div className="text-result">
                    <h3>{topLineRendered}</h3>
                    <div className="row">
                        <i>{document.subline}</i>
                    </div>
                    <div className="row">
                        <b>{document.startTimeText}</b>, {document.durationText}
                    </div>
                    <div className="options row">
                        {sourceTypeIcon}
                        {document.hasNoAudio ? <div className="option-icon material-icons" title="Audio">volume_off</div> : null}
                        {document.hasVideo ? <div className="option-icon material-icons" title="Video">videocam</div> : null}
                        {document.hasScreenSharing ? <div className="option-icon material-icons" title="Screen sharing">screen_share</div> : null}
                    </div>
                </div>
            </section>
        </Fragment>
    );
}
