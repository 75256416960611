export const urls = {
    home: '/',
    login: '/login',
    launchPortal: '/launchportal',
    search: '/search',
    play: '/play',
    testPage: '/testPage',
    postLogin: '/post-login',
    tenantUnknown: '/tenant-unknown',
    tenantReplayNotEnabled : '/tenant-noreplay',
    userUnauthorized: '/user-unauthorized',    
    subscriptionAgreement: 'https://www.cybertwice.com/subscription-agreement',
    subscriptionDetails: 'https://www.cybertwice.com/cybertwice-attest-subscription-details',    
    thumbnailAudio: '/thumbnail_audio.png',
    thumbnailPlaceholder: '/thumbnail_placeholder.png',
    thumbnailOverlay: '/thumbnail_overlay.png',
    thumbnailNoContentReference: '/thumbnail_NoContentReference.png',
    thumbnailRetrievalError: '/thumbnail_RetrievalError.png',
    maintenance: '/Maintenance.png'
}

export function getRelativeUrlWithParameters(): string {
    const originLength = window.location.origin.length;
    const fullUrl = window.location.href;

    return fullUrl.substring(originLength);
}