import React, { Component, useContext } from 'react';
import { Button } from '@fluentui/react-northstar'
import { AuthorizationContext, AuthorizationContextInterface } from '../../context/AuthorizationContext';
import css from './ErrorMessage.module.scss';

interface UserUnauthorizedInternalProps extends UserUnauthorizedProps {
    authorizationContext: AuthorizationContextInterface    
}

class UserUnauthorizedInternal extends Component<UserUnauthorizedInternalProps, {}> {
    handleLogout = () => {
        this.props.authorizationContext.logoutRedirect();
    }

    render() {
        const requiredPermissionsRendered = this.props.requiredPermission ? 
            <p>You need one of the following permissions: {this.props.requiredPermission}.</p> : 
            <></>

        const logoutButtonRendered = !this.props.authorizationContext.isSSO() ?
            <>
                <p className={css.paddingTop}>Use the button below to logout and login as a different user.</p>
                <div className={css.paddingTop}>
                    <Button content="Logout" primary onClick={this.handleLogout} />
                </div>
            </> :
            <></>

        return (
            <div className={css.main}>
                <p><strong>You don't have the permission to access this page.</strong></p>
                {requiredPermissionsRendered}
                <p>Please contact your IT administrator to configure your permissions.</p>                
                {logoutButtonRendered}
            </div>
        )
    }
}

export interface UserUnauthorizedProps {
    requiredPermission: string
}

// Inject the required contexts.
export const UserUnauthorized: React.FunctionComponent<UserUnauthorizedProps> = (props: UserUnauthorizedProps) => {
    const authorizationContext = useContext(AuthorizationContext);
    if (!authorizationContext) {
        throw new Error("No AuthorizationContext found in React tree.");
    }

    return (
        <UserUnauthorizedInternal authorizationContext={authorizationContext} requiredPermission={props.requiredPermission} />
    )
}