import React, { Component } from 'react';
import ReactSlider from 'react-slider'
import { Checkbox, Button, ChevronDownMediumIcon, ChevronEndMediumIcon} from '@fluentui/react-northstar';
import { connectTeamsTheme } from "./../../../context/connectTeamsTheme";

const stepSizeSeconds = 10;
const stepSizeMinutes = 5;
const secondsStepCount = (60 / stepSizeSeconds);
const minutesStepCount = (60 / stepSizeMinutes);
const shortCallsLimit = 60;
const longCallsLimit = 60 * 60;

class DurationFacetTemplate extends Component {
    constructor() {
        super();

        this.state = {
            isExpanded: true,
        }
    }

    handleSectionExpand = () => this.setState(prevState => ({ isExpanded: !prevState.isExpanded }))

    durationToSliderValue = (duration) => {
        let silderValue = 0;
        if (duration <= 60) {
            silderValue = Math.round(duration / stepSizeSeconds);
        } else if (duration <= 60 * 60) {
            silderValue = secondsStepCount + (duration / (60 * stepSizeMinutes));
            silderValue = Math.round(silderValue);
        } else {
            silderValue = secondsStepCount + minutesStepCount + (duration / (60 * 60) - 1);
            silderValue = Math.round(silderValue);
        }
        return silderValue;   
    }

    sliderValueToDuration = (silderValue) => {
        let duration = 0;
        if (silderValue <= secondsStepCount) {
            duration = silderValue * stepSizeSeconds;
        } else if (silderValue <= secondsStepCount + minutesStepCount) {
            duration = (silderValue - secondsStepCount) * 60 * stepSizeMinutes;
        } else {
            duration = (silderValue - (secondsStepCount + minutesStepCount) + 1) * 60 * 60;
        } 
        return duration;
    }

    toDurationString = (duration, longNotation = false) => {
        if (duration < 60) {
            return `${duration}${longNotation?duration>1?" seconds":" second":"s"}`;
        } else if (duration < 60 * 60) {
            let minutes = Math.round(duration / 60);
            return `${minutes}${longNotation?minutes>1?" minutes":" minute":"m"}`;
        } else {
            let hours = Math.round(duration / (60 * 60));
            return `${hours}${longNotation?hours>1?" hours":" hour":"h"}`;
        }
    }

    reportFacetChange = (lowerValue, upperValue) =>
    {
        let valueChanged = {lowerValue: lowerValue, upperValue: upperValue};

        this.props.onChangeFacet(this.props.name, valueChanged);
    }

    setValues = (values) => {
        if(Array.isArray(values) && values.length === 2) {
            this.reportFacetChange(this.sliderValueToDuration(values[0]), this.sliderValueToDuration(values[1]));
        }
    }

    setShortCallsChecked = (checkbox) => {
        this.reportFacetChange(this.props.minValue, checkbox?shortCallsLimit:this.props.maxValue);
    }

    setLongCallsChecked = (checkbox) => {
        this.reportFacetChange(checkbox?longCallsLimit:this.props.minValue, this.props.maxValue);
    }

    setChecked = (checkbox, lowerValue, upperValue) => {
        this.reportFacetChange(checkbox?lowerValue:this.props.minValue, checkbox?upperValue:this.props.maxValue);
    }  

    render() {
        const rangeData = this.props.rangeData;

        let minValue = this.durationToSliderValue(this.props.minValue);
        let maxValue = this.durationToSliderValue(this.props.maxValue);
        let lowerValue = this.durationToSliderValue(this.props.lowerValue);
        let upperValue = this.durationToSliderValue(this.props.upperValue);

        if (lowerValue < minValue) {
            lowerValue = minValue;
        }

        if (upperValue > maxValue) {
            upperValue = maxValue;
        }

        let checkboxChecked = false;

        let rangeCheckboxes = rangeData.map((rangeEntry, index) => {
            if (!(rangeEntry.count) || rangeEntry.count <= 0) {
                return null;
            }

            if (rangeEntry.checked) {
                checkboxChecked = true;
            }

            const countDisplay = rangeEntry.count ? `(${rangeEntry.count})` : "";
            let title = ' more than 1 hour' ;
            if(rangeEntry.displayName === 'Short') {
                title = 'less than 5 minutes';
            } 
            if (rangeEntry.displayName === 'Standard'){
                title = 'between 5 minutes and 1 hour';
            }

            return (
                <li key={index + 1} title={title}>
                    <Checkbox 
                    label={`${rangeEntry.displayName} ${countDisplay}`} 
                    checked={rangeEntry.checked} 
                    onClick={(_, options) => this.setChecked(options.checked, rangeEntry.lowerValue, rangeEntry.upperValue)} />

                     {/* <Checkbox 
                     value={rangeEntry.displayName} 
                     label={`${rangeEntry.displayName} ${countDisplay}`} 
                     checked={rangeEntry.checked} 
                     onChecked={(value) => this.setChecked(value, rangeEntry.lowerValue, rangeEntry.upperValue)} /> */}
                </li>
            );
        });
        const { handleSectionExpand, state } = this;
        // is isExpanded we display the section
        const { isExpanded } = state;
        const { style } = this.props.context;

        return (
            <section className={`search-fragment ${isExpanded ? 'expanded' : ''} ${style === 0 ? 'light' : ''}`}>
                <header className={`${checkboxChecked && ('active')}`} onClick={() => handleSectionExpand()}>
                    <Button
                        iconOnly
                        icon={isExpanded ? <ChevronDownMediumIcon size="large"/> : <ChevronEndMediumIcon size="large"/>}
                        styles={{minWidth: "none", maxWidth: 'none'}}
                    />
                    {this.props.displayName}
                </header>
                <div className="support-content">
                    <ul>
                        {rangeCheckboxes}
                        <li key="slider">
                            <ReactSlider
                                className="horizontalSlider"
                                thumbClassName="sliderButtons"
                                trackClassName="sliderTrack"
                                min={minValue}
                                max={maxValue}
                                value={[lowerValue, upperValue]}
                                renderThumb={(props, state) => <div {...props}>{this.toDurationString(this.sliderValueToDuration(state.valueNow))}</div>}
                                pearling
                                minDistance={1}
                                onAfterChange={this.setValues}
                            />
                        </li>
                    </ul>
                </div>
            </section>
        );

    }    
}

const DurationFacet = connectTeamsTheme(DurationFacetTemplate);
export default DurationFacet;