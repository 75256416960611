import { appConfig as appConfigStaticImport } from './appConfig';
import { appConfig as appConfigDevelopmentImport } from './appConfig.development';

// merge file based config with static config
const appConfigImport = {...appConfigStaticImport, ...window.config};

//console.log(appConfigImport)

// The development configuration only contains the values that needs to be overridden in the appConfig.
// The complete development configuration is created by merging these two configurations.
const developmentConfig = { ...appConfigImport, ...appConfigDevelopmentImport }

const runsInDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const appConfigOutput = runsInDevelopment ? developmentConfig : appConfigImport;

export const appConfig = appConfigOutput;
