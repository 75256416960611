import { Component, ComponentType, ReactNode, useContext } from 'react';
import { Route, Redirect } from 'react-router';
import { AuthenticationStates } from '../context/AuthenticationContext';
import { AuthorizationContext, AuthorizationContextInterface } from '../context/AuthorizationContext';
import { TenantUnknown } from '../components/login/TenantUnknown';
import { TenantNoReplay } from '../components/login/TenantNoReplay';
import { UserUnauthorized } from '../components/login/UserUnauthorized';
import { AuthenticationFailure } from '../components/login/AuthenticationFailure';
import { AuthorizationFailure } from '../components/login/AuthorizationFailure';
import { VerticallyAlignedSpinner } from '../components/common/VerticallyAlignedSpinner';
import { getRelativeUrlWithParameters, urls } from '../logic/urls';
import { Maintenance } from '../components/login/Maintenance';

interface ProtectedRouteInternalProps extends ProtectedRouteProps {
    authorizationContext: AuthorizationContextInterface    
}

class ProtectedRouteInternal extends Component<ProtectedRouteInternalProps, {}> {
    isRedirecting: boolean = false;

    componentDidMount() {
        this.redirectToMicrosoftIfNecessary();
    }

    componentDidUpdate() {
        this.redirectToMicrosoftIfNecessary();
    }

    redirectToMicrosoftIfNecessary = () => {
        if (this.shouldPerformRedirectToMicrosoft()) {
            // User has landed on a protected page while not logged-in and running outside of Teams on a page that should automatically redirect to Microsoft.
            this.isRedirecting = true;
            const postRedirectUrl = getRelativeUrlWithParameters();
            this.props.authorizationContext.loginRedirect(postRedirectUrl);            
        }
    }

    shouldPerformRedirectToMicrosoft = () => {
        return (this.isUserNotAuthenticated() &&
                !this.props.authorizationContext.isSSO() &&
                this.props.autoRedirectToMicrosoft &&
                !this.isRedirecting);
    }

    isUserNotAuthenticated = () => {
        return (this.props.authorizationContext.authenticationState === AuthenticationStates.NotAuthenticated);
    }

    isAuthenticationFailure = () => {
        return (this.props.authorizationContext.authenticationState === AuthenticationStates.AuthenticationError);
    }

    isManifestMismatch = () => {
        return (this.props.authorizationContext.lastAuthenticationErrorMessage.includes("App resource defined in manifest and iframe origin do not match"));
    }

    renderSuccessfulAuthorization = () : ReactNode => {
        if (!this.props.authorizationContext.isTenantKnown()) {
            return <TenantUnknown/>
        }
        
        if (this.props.authorizationContext.isReplayEnabled() === false) {
            return <TenantNoReplay/>
        }

        if (this.props.authorizationContext.hasAccess(this.props.allowedPermissions)) {
            // Happy flow. Authentication and authorization was successful and user is authorized for this page.
            return <Route {...this.props} component={this.props.component} />
        }
        
        const requiredPermissionsDisplayString = this.props.allowedPermissions.join(" or ");
        return <UserUnauthorized requiredPermission={requiredPermissionsDisplayString} />
    }

    renderSsoFailure = () : ReactNode => {
        if (this.isUserNotAuthenticated() || this.isAuthenticationFailure()) {
            // In case the user has not yet the updated version of the App Manifest which supports SSO, the SSO will fail because the 'webApplicationInfo\resource' value does not
            // contain the API identifier of the front-end API. In this specific situation a message is shown that this is a temporary issue and the user should use the web-version instead.
            if (this.isManifestMismatch()) {
                return <Maintenance/>
            } else {
                return <AuthenticationFailure/>
            }
        }

        return <AuthorizationFailure/>
    }

    renderNonSsoFailure = () : ReactNode  => {
        if (this.isUserNotAuthenticated()) {
            if (this.props.autoRedirectToMicrosoft) {
                return <VerticallyAlignedSpinner description="Waiting for redirect to Microsoft..." />
            } else {
                return <Redirect to={urls.login} />
            }
        } else if (this.isAuthenticationFailure()) {
            return <AuthenticationFailure/>
        }

        return <AuthorizationFailure/>
    }

    render() {
        if (this.props.authorizationContext.isAuthorizationInProgress()) {
            return <VerticallyAlignedSpinner description="Waiting for authorization to complete..." />            
        }

        if (this.props.authorizationContext.isAuthorizationDone()) {            
            return this.renderSuccessfulAuthorization();            
        }

        if (this.props.authorizationContext.isSSO()) {
            return this.renderSsoFailure();
        } else {
            return this.renderNonSsoFailure();
        }
    }
}

export interface ProtectedRouteProps {
    // Note: The exact and the path property are not used by this component itself, but are used by the Switch component in the App component.
    exact?: boolean,
    path: string | readonly string[] | undefined,
    autoRedirectToMicrosoft?: boolean,
    allowedPermissions: string[],
    component: ComponentType<any>
}

// Inject the required contexts.
export const ProtectedRoute: React.FunctionComponent<ProtectedRouteProps> = (props: ProtectedRouteProps) => {
    const authorizationContext = useContext(AuthorizationContext);
    if (!authorizationContext) {
        throw new Error("No AuthorizationContext found in React tree.");
    }

    return (
        <ProtectedRouteInternal exact={props.exact} path={props.path} authorizationContext={authorizationContext} autoRedirectToMicrosoft={props.autoRedirectToMicrosoft} allowedPermissions={props.allowedPermissions} component={props.component} />
    )
}