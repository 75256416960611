import React from 'react';
import { Redirect } from 'react-router';

// This page is loaded after the login at Azure is finished and the user has been redirected back. This page is only used in case the application is not running in Teams.
// This page immediately redirects to the URL which has been stored before the redirect login was started. This is normally a page which required authentication.
export class PostLogin extends React.Component {
    render() {
        const postRedirectUrl = sessionStorage.getItem('postRedirectUrlKey');
        
        if (postRedirectUrl) {
            // Add the hash part of the current location to the post redirect URL. This hash contains the information that is
            // required by the MSAL library.
            const postRedirectUrlWithHash = postRedirectUrl + window.location.hash;
            return <Redirect to={postRedirectUrlWithHash} />
        }

        return <div>No post redirect URL set at login...</div>
    }
}