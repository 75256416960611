import { Component } from 'react';
import { connectTeamsTheme } from "./../../context/connectTeamsTheme";
import { MediaDetail } from '../../types/MediaDetail';
import * as apiCalls from '../../logic/apiCalls';
import { Summarization } from '../../types/Summarization';

type SummarizationTabProps = {
    isPanelOpen: boolean, // ToDo: Is this still required? (copied from TranscriptionTab.tsx)       
    mediaInfo: MediaDetail,
    context: any
}

type SummarizationTabState = {
    summarization?: Summarization;
}

class SummarizationTabInternal extends Component<SummarizationTabProps, SummarizationTabState> {

    constructor(props: SummarizationTabProps) {
        super(props);
        this.state = {
            summarization: undefined,
        };
    }

    async componentDidMount() {
        const summarization = await apiCalls.getSummarization(this.props.mediaInfo.mediaId);

        this.setState({ summarization: summarization });
    }

    render() {
        const { isPanelOpen } = this.props;
        return (
            <div className={`video-side-bar ${isPanelOpen && ('active')}`}>

                <div className="summarization-content">
                    {this.state.summarization === undefined && (
                        <div>Loading...</div>
                    )}
                    {this.state.summarization !== undefined && (
                        <div>
                            {this.state.summarization.aspects.map((aspect, index) => {
                                return (
                                    <div key={index}>
                                        <h4>{aspect.name}</h4>
                                        <ul>
                                            {aspect.items.map((item, index) => {
                                                return (
                                                    <li key={index}>{item.text}</li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export const SummarizationTab = connectTeamsTheme(SummarizationTabInternal as any);