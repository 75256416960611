import { Component } from 'react';
import TraceMap from './TraceMap';
import * as apiCalls from '../../logic/apiCalls';
import { connectTeamsTheme } from "./../../context/connectTeamsTheme";

type MediaTraceMapProps = {
    isPanelOpen: boolean, // ToDo: Is this still required? (copied from TranscriptionTab.tsx)   
    play: boolean,
    startTime: Date,
    timeOffSetNavigationPointer: number,
    mediaId: string
}

type MediaTraceMapState = {
    traceData: any
}

class MediaTraceMapInternal extends Component<MediaTraceMapProps, MediaTraceMapState> {   
    constructor(props: MediaTraceMapProps) {
        super(props)
        this.state = {
            traceData: null
        }
    }

    componentDidMount() {
        this.getGPSTraceData(this.props.mediaId);
    }
    
    getGPSTraceData = async (mediaId: string) => {
        const mediaTranscription = await apiCalls.getGPSTrace(mediaId);
        this.setState({traceData: mediaTranscription});        
    }

    render() {
        const { isPanelOpen } = this.props;

        return (
            <div className={`video-side-bar ${isPanelOpen && ('active')}`} style={{ height: '70vh'  }}>
                <TraceMap traceData={this.state.traceData} timeOffSetNavigationPointer={this.props.timeOffSetNavigationPointer}
                    startTime={this.props.startTime}/>
            </div>
        );
    }
}

export const MediaTraceMap = connectTeamsTheme(MediaTraceMapInternal as any);