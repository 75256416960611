import React, { Component } from 'react';
import FacetFieldCheckboxes from './FacetFieldCheckboxes'
import { connectTeamsTheme } from "./../../../context/connectTeamsTheme";
import { Button, ChevronDownMediumIcon, ChevronEndMediumIcon, ChevronDownIcon } from '@fluentui/react-northstar';

// TODO: The search results should no longer limit the number of facets to 10.
// To test the 'see more' feature lower this setting to 5. 
const limit = 10 

class StringFacetTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isExpanded: this.props.facetSelection?.length,
        }
    }


    handleSectionExpand = () => this.setState(({ isExpanded }) => ({ isExpanded: !isExpanded }))

    createTopFilters = (facetData, facetSelection, offset) => {
        if (!facetData) {
            return null
        }

        const topFacets = Object.keys(facetData).sort().slice(0, offset);
        facetSelection.forEach(el => !topFacets.includes(el) ? topFacets.push(el) : null);
        const newFacetData = topFacets.reduce((acc, el) => ({ ...acc, [el]: facetData[el] }), {})

        return newFacetData
    }

    render() {
        // The facetData is an object of which the properties name (key) is what is displayed as an entry
        // and the value contains the count of this entry.
        const facetData = this.props.facetData;
        // The facetSelection is an array of strings that should be shown as checked.
        const facetSelection = this.props.facetSelection || [];


        if (!facetData || Object.keys(facetData).length < 1) {
            return null;
        }

        const topThreeFilters = this.createTopFilters(facetData, facetSelection, limit);
        const hasMore = Object.keys(facetData).length > limit;

        const { handleSectionExpand, state } = this;
        // is isExpanded we display the section
        const { isExpanded } = state;
        const { style } = this.props.context;

        return (
            <>
                <section className={`search-fragment ${isExpanded ? 'expanded' : ''} ${style === 0 ? 'light' : ''}`}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <header className={`${facetSelection.length > 0 && ('active')}`}
                            onClick={() => handleSectionExpand()}>
                            <Button
                                iconOnly
                                icon={isExpanded ? <ChevronDownMediumIcon size="large" /> : <ChevronEndMediumIcon size="large" />}
                                styles={{ minWidth: "none", maxWidth: 'none' }}
                            />
                            {this.props.displayName}
                        </header>
                    </div>
                    <div className="support-content">
                        <ul>
                            <FacetFieldCheckboxes
                                facetData={topThreeFilters}
                                facetSelection={facetSelection}
                                onChangeFacet={this.props.onChangeFacet}
                                name={this.props.name}
                            />
                        </ul>
                        {hasMore && <Button icon={<ChevronDownIcon />} styles={{paddingLeft: 0}} title="See more" content="See more" text onClick={() => this.props.setFacetExpanded(this.props.name)} />}
                    </div>
                </section>
            </>
        )
    }
}
const StringFacet = connectTeamsTheme(StringFacetTemplate);
export default StringFacet;