export interface Summarization {
    aspects: Aspect[];
}

export interface Aspect {
    name: string;
    items: AspectItem[];
}

export interface AspectItem {
    text: string;
}

//This class should be removed when the Replay API supports summarization.
export const mediasWithSummarization = [
    "c5c78e85-74d1-4d16-be50-14b9ddb8d51f", //For each aspect a single item
    "78f1d4a0-eba2-497d-8b82-ff7097380704" // A single Recap with multiple ChapterTitles and Narratives
    //"cd01bfb1-cb5a-4023-b041-14709744cae3"
]