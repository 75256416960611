import { microsoftTeamsLogic } from './microsoftTeamsLogic';

// This module automatically registers for changes in the Teams theme when the module is imported 
// and will set a black background for the 'dark' and the 'high contrast' mode.
// This background color is especially visible on mobile devices where the page has bounce scrolling
// where the background becomes visible.

function updateBackgroundColor(newTheme) {
    const color = getColor(newTheme);

    document.body.style.backgroundColor = color;
}

function getColor(themeStr) {    
    switch (themeStr) {
        case 'dark':
            return 'black';
        case 'contrast':
            return 'black';            
        case 'default':
        default:
            return 'white';
    }    
}

// Register a callback which is invoked on every change in the Teams theme.
(function registerThemeChangeCallback() {
    microsoftTeamsLogic.registerThemeChangeCallback(updateBackgroundColor);
})();