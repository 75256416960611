import React, { Fragment, Component } from 'react';
import './Login.scss'
import { AuthorizationContext } from '../../context/AuthorizationContext';
import { MagicPixel } from '../MagicPixel';
import { isMobile } from '../../logic/platformDetection';
import { urls } from '../../logic/urls';
import { Flex, Button } from '@fluentui/react-northstar'
import { getStyle } from '../ThemeLogic';
import { connectTeamsTheme } from "./../../context/connectTeamsTheme";
import { Anchor } from "./../common/Anchor";
import CyberTwiceLogo from '../common/CyberTwiceLogo'

class LoginInteral extends Component {
  static contextType = AuthorizationContext;

  login = () => {
    this.context.loginRedirect(urls.search);
  }
  
  render() {
    const teamsThemeContext = {...this.props.context.theme.siteVariables, style: this.props.context.style};

    const signupTextRendered = !isMobile() ? <>
          <p className="small-text">When your organization has not signed up yet for the ATTEST service please contact <Anchor href="https://www.cybertwice.com/contact.html" target="_blank" rel="noopener noreferrer">CyberTwice</Anchor> to get a subscription.</p>
          <p>&nbsp;</p>
      </> : null;    

    return (
      <Fragment>
        <MagicPixel debugChanged={() => this.forceUpdate()} />
        <Flex className="content-height">
          <div className='main'>
            <section className="support-login" style={getStyle(teamsThemeContext)}>
              <section className="content-login flex--wrap">
                <section className="control-center">
                  <header>
                    <div className="company-logo" />
                    <div className="company-text">ATTEST Replay</div>
                  </header>
                  <section className="login flex--wrap">
                    <p className="title">Log in</p>
                    <p className="sub-text">with your office 365 account</p>
                    <Button 
                      primary
                      className="login-button"
                      style={getStyle(teamsThemeContext).button}
                      variables={{minWidth: '6.4rem'}}
                      onClick={this.login}>Log in</Button>
                    {signupTextRendered}
                    <p className="small-text">By using ATTEST Replay
                      you agree to the <Anchor href={urls.subscriptionAgreement} target="_blank" rel="noopener noreferrer">Subscription Agreement</Anchor> and
                      the <Anchor href={urls.subscriptionDetails} target="_blank" rel="noopener noreferrer">ATTEST Subscription Details</Anchor>.
                    </p>
                  </section>
                </section>
                <footer>
                  <div className="red-line" />
                  <p>by</p>
                  <div>
                    <CyberTwiceLogo/>
                    </div>
                </footer>
              </section>
            </section>
          </div>
        </Flex>
      </Fragment>
    )
  }
}

export const Login = connectTeamsTheme(LoginInteral);