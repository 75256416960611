import React, { Component, useContext } from 'react';
import { AuthenticationContext, AuthenticationContextInterface } from '../../context/AuthenticationContext';
import css from './ErrorMessage.module.scss';
import { Anchor } from '../common/Anchor';
import { appConfig } from '../../logic/appConfigProvider';
import { urls } from '../../logic/urls';

interface MaintenanceInternalProps {
    authenticationContext: AuthenticationContextInterface    
}

class MaintenanceInternal extends Component<MaintenanceInternalProps, {}> {
    handleLogout = () => {
        this.props.authenticationContext.logoutRedirect();
    }

    render() {
        return (
            <div className={css.main}>
                <p><strong>This app is currently under maintenance</strong></p>    
                <img className={css.maintenanceImage} alt='Maintenance crew' src={urls.maintenance}></img>            
                <p>Please use the <Anchor href={appConfig.attestReplayWebUrl} target="_blank" rel="noopener noreferrer">web version</Anchor> until the maintenance is finished.</p>                
            </div>
        )
    }
}

// Inject the required contexts.
export const Maintenance: React.FunctionComponent = () => {
    const authenticationContext = useContext(AuthenticationContext);
    if (!authenticationContext) {
        throw new Error("No AuthenticationContext found in React tree.");
    }

    return (
        <MaintenanceInternal authenticationContext={authenticationContext} />
    )
}