import { Component } from 'react';
import {MediaTraceMap} from '../map/MediaTraceMap'
import { MediaDetail } from '../../types/MediaDetail';
import { connectTeamsTheme } from "./../../context/connectTeamsTheme";

type LocationTabProps = {
    isPanelOpen: boolean, // ToDo: Is this still required? (copied from TranscriptionTab.tsx)   
    timeOffSetNavigationPointer: number,
    mediaInfo: MediaDetail
}

type LocationTabState = {
}

class LocationTabInternal extends Component<LocationTabProps, LocationTabState> {    

    state = {
    };  

    componentDidMount() {
    }

    componentDidUpdate(prevProps: LocationTabProps) {

    }



    render() {

        const { isPanelOpen } = this.props;

        return (
            <div className={`video-side-bar ${isPanelOpen && ('active')}`}>
                <MediaTraceMap play={false} isPanelOpen={this.props.isPanelOpen} timeOffSetNavigationPointer={this.props.timeOffSetNavigationPointer}
                    startTime={this.props.mediaInfo.timeStart} mediaId={this.props.mediaInfo.mediaId}/>
            </div>
        );
    }
}

export const LocationTab = connectTeamsTheme(LocationTabInternal as any);