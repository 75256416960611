// @ts-nocheck
import React, { Component } from 'react';
import { TextItem, Participant } from '../../types/transcription';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ListOnScrollProps, VariableSizeList } from 'react-window'
import { TranscriptionListRow, TranscriptionRowData } from './TranscriptionListRow';

import css from './TranscriptionList.module.scss';

const ESTIMATED_ROW_HEIGHT = 100;
const MAX_SMOOTH_SCROLL_IN_PIXELS = 500;
const REENABLE_SMOOTH_SCROLLING_TIMEOUT_MS = 0;

export type TranscriptionData = {
    textItems: TextItem[],
    participants: Participant[]
}

type Props = {
    data: TranscriptionData,    
    enableAutoScroll: boolean,
    onSetPositionRequest: (position: number) => void,
}

type State = {
    enableSmoothScroll: boolean
}

type GenerateRowCallback = {
    index: number,
    style: any
}

export class TranscriptionList extends Component<Props, State> {
    private listRef: React.RefObject<VariableSizeList<any>> = React.createRef();    
    private heights: number[] = [];
    
    private listHeight = 0;
    private selectedItem = 0;
    private calculatedScrollheight = 0;
    private singleSpeaker = false;

    private deferredResetAfterIndex = Number.MAX_SAFE_INTEGER;
    private deferredScroll = false;
    private deferredSelectItemAtIndex = Number.MAX_SAFE_INTEGER;
    
    state = {
        enableSmoothScroll: true
    }

    componentDidMount() {
        this.singleSpeaker = this.hasSingleSpeaker();
    }

    componentDidUpdate(prev: Props) {
        if (this.deferredResetAfterIndex !== Number.MAX_SAFE_INTEGER) {
            // A deferred ResetAfterIndex is executed when list items have reported their height before the VariableSizeList has finished its initial rendering.
            // When that happens, the ResetAfterIndex method cannot be invoked yet and is performed here when the initial rending is finished.
            if (this.listRef.current) {
                this.listRef.current.resetAfterIndex(this.deferredResetAfterIndex);
                this.deferredResetAfterIndex = Number.MAX_SAFE_INTEGER;
            } 
            else {
                console.warn("Cannot refresh transcription list because list reference is not available.");
            }
        }

        if (this.deferredScroll) {            
            // A deferred scroll is executed when a large jump has to be made without the 'smooth scrolling'. To prevent all items between the start and endpoint of the scroll are rendered.
            // At this point the VariableSizeList instance has been re-rendered without the 'smooth scrolling' CSS class and the 'scrollTo' method can be invoked with the previously calculated position.

            // console.log("[TranscriptionList, componentDidUpdate] Executing deferred scroll...")
            if (this.listRef.current) {
                this.deferredScroll = false;
                this.listRef.current.scrollTo(this.calculatedScrollheight);                
            }                    
        }

        if (this.deferredSelectItemAtIndex !== Number.MAX_SAFE_INTEGER) {
            // A deferred SelectItemAtIndex is executed when the SelectItemAtIndex method has been invoked while the VariableSizeList was not finished with its initial rendering
            // which is required for its execution. 
            if (this.listRef.current) {
                this.selectItemAtIndex(this.deferredSelectItemAtIndex);
                this.deferredSelectItemAtIndex =  Number.MAX_SAFE_INTEGER;
            } 
            else {
                console.warn("Cannot perform deferred select item at index because list reference is not available.");
            }
        }

        // In case the TextItems have changed the list should re-render all items again. This happens for example when the text highlights have changed.
        if (prev.data.textItems !== this.props.data.textItems) {            
            this.forceListUpdate();
            this.singleSpeaker = this.hasSingleSpeaker();
        }
    }

    // Method selects the item with the provided index. This will show the red line on the left side of the text balloon. 
    // In case auto-scolling is enabled the item is positioned in the middle of the list view.
    public selectItemAtIndex = (index: number) => {
        if (!this.listRef.current) {
            this.deferredSelectItemAtIndex = index;
            return; // List has not been rendered yet.
        }   

        if (this.selectedItem === index) {
            return; // Item is already selected.
        }

        if(!this.props.enableAutoScroll) {
            return; // Follow player is turn off 
        }

        this.selectedItem = index;
        this.forceListUpdate();

        if (this.props.enableAutoScroll) {
            this.calculatedScrollheight = this.calculateScrollHeightForItemWithIndex(index, this.listHeight);

            // console.log(`[TranscriptionList, selectItemAtIndex] Calculated scrollheight for index ${index}: ${this.calculatedScrollheight}`);
            var currentScrollPosition = (this.listRef.current.state as any).scrollOffset;
            if (Math.abs(this.calculatedScrollheight - currentScrollPosition) > MAX_SMOOTH_SCROLL_IN_PIXELS) {  
                // console.log(`[TranscriptionList, selectItemAtIndex] Position jump too large, disabling smooth scrolling...`);              
                // The new scroll position is too far away. To prevent all items between the current and new postion are rendered, the smooth scrolling is disabled during the scroll.
                // This is done by changing the 'enableSmoothScroll' value in the state, which will trigger a re-render and the invocation of the ComponentDidUpdate where the 
                // actual scrolling is started.
                this.deferredScroll = true;
                this.setState({enableSmoothScroll: false});
            }
            else {
                // console.log(`[TranscriptionList, selectItemAtIndex] Invoking scrollTo method for position ${this.calculatedScrollheight}`);              
                this.listRef.current.scrollTo(this.calculatedScrollheight);
            }
        }
    }

    // Method selects the item with the provided offset in seconds. The item that is selected is latest item that has a start time that is equal to or 
    // less than to provided offset. After the item index to select has been determined, the actual selection is handled by the 'selectItemAtIndex' method.
    public selectItemAtOffset = (offset: number) => {
        // console.log(`[TranscriptionList, selectItemAtOffste] Method invoked for offset ${offset}`);

        // Determine the index of the text item to show by comparing the requested offset with the start time of each text item.
        // On purpose the comparison does not consider the end time of the text items because the requested offset may point between two text items.
        let indexToShow = 0;
        for (var i = 0; i < this.props.data.textItems.length; i++) {
            const textItem =this.props.data.textItems[i];

            if (textItem.offsetSeconds <= offset) {
                indexToShow = i;
            }
            else {
                break;
            }
        }

        // console.log(`[selectItemAtOffset] Selecting item with index ${indexToShow}`);
        this.selectItemAtIndex(indexToShow);
    }

    // Method determines if all the text items have the same speaker or not.
    private hasSingleSpeaker() : boolean {
        const textItems = this.props.data.textItems;

        if (textItems.length === 0) {
            return true;
        }
        
        let participantId = textItems[0].participantId;

        for (let textItem of textItems) {
            if (textItem.participantId !== participantId) {
                return false;
            }
        }            
        
        return true;
    }

    // Method calculates the scroll height in pixels that will position the item with the provided index exactly in the middle of the list view.
    // This is done by first add together all (currently) known height of all items before the provided index. Then the value is adjusted for the 
    // height of the list view and the height of the item with the provided index.
    // Note that the result of this method may vary when estimated item heights are replaced with the actual heights.
    private calculateScrollHeightForItemWithIndex(index: number, listHeight: number) {
        let scrollHeight = 0;

        for (let i=0; i < index; i++) {
            scrollHeight += this.getRowHeight(i);
        }

        // Without adjustment the item would now be shown at the top of the list. 
        // Adjust the scrolling so that the top of the item is shown in the middle of the list.
        scrollHeight -= (listHeight / 2);

        // Now adjust with the height of the item, so that the middle of the item is shown in the middle of the list.
        scrollHeight += (this.getRowHeight(index) / 2);

        // Prevent negative scroll offset values.
        if (scrollHeight < 0 ) {
            scrollHeight = 0;
        }

        // Prevent scrolling further than possible with the total scroll height and the height of the list, because this leads to undefined behavior.
        let totalScrollHeight = this.getScrollHeight();
        let maxScrollHeight = totalScrollHeight - listHeight;
        if (scrollHeight > maxScrollHeight) {
            scrollHeight = maxScrollHeight;
        }

        return scrollHeight;
    }

    // Method forces a re-render of all list items.
    private forceListUpdate = () => {
        if (this.listRef.current) {
            this.listRef.current.forceUpdate();
        }
    }

    // Method is invoked by a list item when it has finished rendering and has determined its actual height.
    // This method updates the administration of the row heights in this component and ensures the VariableSizeList component also 
    // updates its internal row height administration.
    private handleHeightCallback = (index: number, height: number) => {
        if (this.heights[index] === height) {
            // console.log(`[TranscriptionList, handleHeightCallback] Ignoring height update for index: ${index}, current value ${this.heights[index]}, new value ${height}`);
            return;
        }

        this.heights[index] = height;
        if (this.listRef.current) {
           // console.log(`[TranscriptionList, handleHeightCallback] Height update for index: ${index}, current value ${this.heights[index]}, new value ${height}`);

            this.listRef.current.resetAfterIndex(index);           
        } else {
            // The 'resetAfterIndex' cannot be executed now, because the VariableSize component has not fully rendered yet. 
            // This is executed later in the ComponentDidUpdate method. The 'forceUpdate' is required to ensure the ComponentDidUpdate will be invoked.
            if (index < this.deferredResetAfterIndex) {
                this.deferredResetAfterIndex = index;
                this.forceUpdate();
            }
        }
    }

    // Method returns the row height of the list row with the provided index.
    // The row height of every item is initially set to the estimated row height and will be updated once a list row has been rendered.
    private getRowHeight = (index: number) => {
        // console.log(`[TranscriptionList, getRowHeight] getRowHeight: ${index}: ${this.heights[index]}`);

        // Note: The default value is arbitrary, but should never be 0 because the VariableSizeList uses this value to calculate how many 
        // rows needs to be created/rendered to fill the view. With a value of 0 all items will be created/rendered.
        // It should also not be smaller than the expected item height because else more items will
        // initially created and rendered and will be discarded if it turns out they are not needed for the (initial) display.
        return this.heights[index] || ESTIMATED_ROW_HEIGHT;
    }

    // Method calculates the total scroll height by adding together all row heights (both actual reported heights and estimated heights).
    private getScrollHeight = () => {
        let total = 0;
        for (let i = 0; i < this.props.data.textItems.length; i++) {
            total += this.getRowHeight(i);
        }

        return total;
    }

    // Method generates the JSX elements for a single list row.
    private generateRow = (params: GenerateRowCallback) => {
        const index = params.index;
        const currentTextItem = this.props.data.textItems[index];
        const participant: Participant = this.props.data.participants.find(item => item.participantId === currentTextItem.participantId) || this.props.data.participants[0];
        const isSelected = this.selectedItem === index;
        const previousParticipantId = this.props.data.textItems[index - 1]?.participantId || '';
        const isAttached = previousParticipantId === participant.participantId;
        // When there is only a single pariticpant in the transcription, this is very likely caused by missing dominant speaker information 
        // and this single participant is not shown.
        const showParticipant = !this.singleSpeaker; 

        const data: TranscriptionRowData = {
            textItem: currentTextItem,
            participant: participant,
            isSelected: isSelected,
            isAttached: isAttached,
            showParticipant: showParticipant,
        }

        return (
            <TranscriptionListRow index={index} style={params.style} data={data} onSetPositionRequest={this.props.onSetPositionRequest} heightCallback={this.handleHeightCallback} />
        )
    }

    // Method is invoked when the AutoSizer has detected a resize.
    // All stored information about the size of the transcription items is cleared and the list is forced to be re-rendered.
    private handleResize = () => {
        // Reset all determined heights since they are no longer accurate.
        this.heights = [];
        // Trigger the re-read of the heights in the VariableSizeList.
        this.listRef.current?.resetAfterIndex(0);  
        // Trigger the re-rendering of the VariableSizeList. Because the heights have been cleared, the items will re-render.
        this.forceListUpdate();
    }

    // Method is invoked after every scroll of the list. This scroll might be initiated by the user or from code by invoking the 'scrollTo' method. User scrolls are ignored by the implementation.
    // This method is required to make the scrolling to a specific list item work correctly. When the scroll position of an item is calculated, it might have used estimated row heights. After rendering the items 
    // at the scroll position, more actual row height might have become available which can significantly influence the scroll position. This deviation might be so huge that the selected item is not even shown.
    // This method is invoked after the rendering at the new position has finished and calculates the scroll position for the selected item again. When the scroll postition is different than the current 
    // scroll position the 'scrollTo' method is invoked again. Theoretically this could take multiple rounds before the final scroll position is reached, but in practise the second time is already the 
    // final position.
    private handleOnScroll = (e: ListOnScrollProps) => {
        // console.log(`[TranscriptionList, handleOnScroll] scrollUpdateWasRequested: ${e.scrollUpdateWasRequested}, scrollOffset: ${e.scrollOffset}`);

        if (!e.scrollUpdateWasRequested) {            
            return; // The user has scrolled.
        }

        const recalculatedScrollHeight = this.calculateScrollHeightForItemWithIndex(this.selectedItem, this.listHeight);
        if (recalculatedScrollHeight !== this.calculatedScrollheight) {
            // console.log(`[TranscriptionList, handleOnScroll] Original calculated scrollheight ${this.calculatedScrollheight}, recalculated scrollheight ${recalculatedScrollHeight}`);

            this.calculatedScrollheight = recalculatedScrollHeight;
            this.listRef.current?.scrollTo(recalculatedScrollHeight);
        } else {
            //console.log('[TranscriptionList, handleOnScroll] Scrolled to correct position...');

            if (!this.state.enableSmoothScroll) {
                console.log('[TranscriptionList, handleOnScroll] Re-enabling smooth scrolling...');

                // Re-enable the smooth scrolling. This is done with a small delay or else the smooth scrolling is still applied.
                // console.log("[TranscriptionList, handleOnScroll] Re-enabling smooth scrolling...");
                setTimeout(() => this.setState({enableSmoothScroll: true}), REENABLE_SMOOTH_SCROLLING_TIMEOUT_MS);
            } else {
                // console.log('[TranscriptionList, handleOnScroll] Smooth scrolling is already enabled.');
            }
        }
    }

    render() {
        // console.log(`[TranscriptionList, render] enableSmoothScroll: ${this.state.enableSmoothScroll}`);   
        
        return (
            <AutoSizer onResize={this.handleResize}>
                {({ height, width }) => { 
                    this.listHeight = height;

                    return (<VariableSizeList
                                ref={this.listRef}
                                height={height}
                                width={width}
                                itemCount={this.props.data.textItems.length}
                                itemSize={this.getRowHeight}
                                estimatedItemSize={ESTIMATED_ROW_HEIGHT}                                
                                onScroll={this.handleOnScroll}
                                className={this.state.enableSmoothScroll ? css.smooth : ""}
                            >
                                {this.generateRow}
                            </VariableSizeList>
                )}}            
            </AutoSizer>)
    }
}