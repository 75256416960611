import React from 'react'
import {connectTeamsTheme} from './../../context/connectTeamsTheme'
import { style } from 'typestyle';


 const customAnchor = (props) => {
    const {context, ...rest} = props;
    const color = context.theme.siteVariables.colorScheme.brand.foreground1;

    const anchorStyle = style({
        outline: 'none', 
        textDecoration: 'none',
        color,
        "&:hover": {
            color,
            textDecoration: 'underline'
          },
        "&:active": {
            color,
            textDecoration: 'underline'
          },
          "&:focus": {
            color,
            textDecoration: 'underline'
          },
    })

    return (
        <a className={anchorStyle} {...rest}>{props.children}</a>
    )
}

export const Anchor = connectTeamsTheme(customAnchor)