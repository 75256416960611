import React, { Component} from 'react';
import { Button } from "@fluentui/react-northstar";
import {ExpandIcon, AudioOffIcon, VolumeUpIcon, PlayIcon, PauseIcon, DownloadIcon, ClosedCaptionsIcon} from '@fluentui/react-icons-northstar'
import ReactSlider from 'react-slider' // https://www.npmjs.com/package/react-slider
import { connectTeamsTheme } from "./../../context/connectTeamsTheme";
import { Tooltip } from '@fluentui/react-northstar'
import { isIOS, isMobile, isFullscreenEnabled } from '../../logic/platformDetection';
import { durationToPlayerDisplayString } from '../../logic/timeDisplay';
import { themeContext } from './../../context/TeamsFluentUiContextProvider'

import css from './PlayerControls.module.scss';
import { TimelineEvent } from '../../context/TimelineContext';

const PROGRESSBAR_MAX_VALUE = 10000;

interface PlayerControlsProps {
    showFullscreenIcon: boolean;
    showSubtitleIcon: boolean;
    showVolumeControls: boolean;
    isMediaPlaying: boolean;
    isSubtitleActive: boolean;
    isMuted: boolean;
    volume: number;
    timelineEvents: TimelineEvent[];
    duration: number;
    position: number;
    onFullScreenToggle: () => void;
    onPlayPauseToggle: () => void;
    onSubtitleToggle: () => void;
    onVolumeChange: (value: number) => void;
    onMuteToggle: () => void;
    onPositionChange: (position: number) => void;
    onDownload: () => void;
}

interface PlayerControlsInternalProps extends PlayerControlsProps { }

export class PlayerControlsInternal extends Component<PlayerControlsInternalProps, {}> {
    context!: React.ContextType<typeof themeContext>;  
    static contextType = themeContext

    timer!: number;

    componentDidMount(){
        this.timer = window.setTimeout(() => {
            document.addEventListener('keydown', this.keyboardPlayerControls)
        }, 1000);
    }

    componentWillUnmount() {
        clearTimeout(this.timer)
        document.removeEventListener('keydown', this.keyboardPlayerControls)
    }

    keyboardPlayerControls = (e: KeyboardEvent & {target: any}) => {
        const {position, duration, onPositionChange, onPlayPauseToggle} = this.props;
        const changePositionSec = 10;
        const target = e.target === document.body || ['sliderButtons', 'ms-FocusZone'].some(className => e.target.classList.contains(className)) || e.target.tagName === 'BUTTON';

        if (e.keyCode === 27) {
            e.target.blur()
        }
        
        if (e.keyCode === 32 && target) {
            e.preventDefault()
            onPlayPauseToggle()
        }

        if (e.keyCode === 39 && target && duration - position > changePositionSec){
            onPositionChange(position + changePositionSec);
        }
        if (e.keyCode === 37 && target && position >= changePositionSec){
            onPositionChange(position - changePositionSec);
        }
    }

    // Member contains the mapping between the scaled position of a timeline-event and the timeline-event itself.
    // This is required in the 'render mark' callback of the react-slider component to get the related MarkItem, because
    // unfortunately only the position is provided in this callback.
    timelineEventsByScaledPositionLookup: Map<number, TimelineEvent> = new Map<number, TimelineEvent>();
    
    createTimelineEventsByScaledPositionLookup = (timelineEvents: TimelineEvent[], duration: number) => {
        const lookupList = new Map<number, TimelineEvent>();

        for (const timelineEvent of timelineEvents) {
            
            let scaledPosition = (timelineEvent.offsetSeconds / duration) * PROGRESSBAR_MAX_VALUE;   
            // console.log(scaledPosition); 
            lookupList.set(scaledPosition, timelineEvent);  
            // console.log(lookupList);            ;
            // if (timelineEvent.durationInSecond){
            //     // if the timeline event has a duration, create an item for each second from start to end
            //     for (let index = 1; index <= timelineEvent.durationInSecond; index++) {
            //         let scaledPosition = ((timelineEvent.offsetSeconds + index) / duration) * PROGRESSBAR_MAX_VALUE;            
            //         lookupList.set(scaledPosition, timelineEvent);            
            //     }
            // }
        }

        return lookupList;
    }

    handleRenderMark = (props: any) => {
        const { numOfTheme: teamsTheme } = this.context;

        let additionalProgressBarMarkerClass = (teamsTheme === 0) ? css.darkProgressBarMarker : "";

        // Find the event information by the (scaled) offset of the event.
        const timelineEvent = this.timelineEventsByScaledPositionLookup.get(props.key);
        if (!timelineEvent) {
            return (<></>);
        }

        let contents = timelineEvent.getTooltipContent();
        let title = timelineEvent.getTooltipTitle();

        // Build the tooltip using the MSteams tooltip.
        return (            
            <Tooltip trigger={<div className={`${css.progressBarMarker} ${additionalProgressBarMarkerClass}`} style={{...props.style}} />} content={
                <div className={css.tooltip}>
                    <div className={css.tooltipTitle}>{title}</div>
                    {contents}                    
                </div>
            } />            
        )
    }

    handleRenderThumbCallback = (props: any, state: any) => <div {...props}></div>;

    handlePositionChange = (relativePosition: number) => {
        // Relative position is a value between 0 and 'progressBarMaxValue'.
        const absolutePosition = (this.props.duration / PROGRESSBAR_MAX_VALUE) * relativePosition;
        this.props.onPositionChange(absolutePosition);
    }


    handleOnDownload = () => {
         this.props.onDownload();
    }

    render() {

        const { duration, position, isMuted, volume, isMediaPlaying, showFullscreenIcon, showSubtitleIcon, showVolumeControls } = this.props;        
        const { numOfTheme: teamsTheme } = this.context;
 

        let subtitleButtonTheme = '';
        switch (teamsTheme) {
            case 0:
                subtitleButtonTheme = css.lightTheme;
                break;
            case 2:
                subtitleButtonTheme = css.highContrastTheme;
        }

        const subtitleButtonRendered = showSubtitleIcon ? 
            <div>
            <PlayerControlsButton
            height = '0rem'
            size="medium"
            action={this.props.onSubtitleToggle}
            icon={<ClosedCaptionsIcon size='large' />}
            title="Subtitle" />
            </div>
             :
            <></>;

        
        const downloadButtonRendered = !isMobile() ?
            <div className={`${css.downloadIcon} ${subtitleButtonTheme} ${true}`} >
                <PlayerControlsButton
                    height = '0rem'
                    size="medium"
                    action={this.handleOnDownload}
                    icon={<DownloadIcon size='medium' />}
                    title="Download" />
            </div> :
            <></>

        const shouldDisplayFullScreenIcon = showFullscreenIcon && (isFullscreenEnabled() || isIOS());
        const fullscreenIconRendered = shouldDisplayFullScreenIcon ? 
            <div className={css.fullScreenIcon}>
                <PlayerControlsButton 
                size="medium" 
                action={this.props.onFullScreenToggle} 
                icon = {<ExpandIcon size='medium' />} 
                title = "FullScreen"/>
            </div> :
            <></>


        // The volume slider is not displayed on iOS since the volume cannot be set by an application.
        const volumeSliderRendered = !isIOS() ?
            <ReactSlider
                className={css.horizontalSlider} 
                thumbClassName={'sliderButtons'}
                trackClassName={'sliderTrack'}
                min={0}
                max={100}
                value={volume}
                renderThumb={(props: any, state: any) => <div {...props} onFocus={(e) => e.preventDefault()}></div>}
                step={1}
                pearling
                onChange={this.props.onVolumeChange}
            /> : 
            <></>

            // <AudioOffIcon />
        const volumeControlsRendered = showVolumeControls ? 
            <div className={`${css.volumeSliderContainer} display--flex`}>                        
                <PlayerControlsButton
                    size="medium"
                    action={this.props.onMuteToggle}
                    icon={isMuted ? <AudioOffIcon size='medium'/> : <VolumeUpIcon size='medium'/>}
                    title="Volume" />
                {volumeSliderRendered}
            </div> : 
            <></>            

        this.timelineEventsByScaledPositionLookup = this.createTimelineEventsByScaledPositionLookup(this.props.timelineEvents, duration);
        const timelineEventsPositions = duration > 0 ? [...this.timelineEventsByScaledPositionLookup.keys()] : [];        

        let scaledPosition = 0;
        if (duration > 0) {
            scaledPosition = (position / duration) * PROGRESSBAR_MAX_VALUE;
        }

        const progressBarRendered = (
            <ReactSlider
                className={css.horizontalSlider} 
                thumbClassName={'sliderButtons'}
                trackClassName={'sliderTrack'}
                min={0}
                max={PROGRESSBAR_MAX_VALUE}
                value={scaledPosition}
                renderThumb={this.handleRenderThumbCallback}
                step={1}
                pearling
                marks={timelineEventsPositions}
                renderMark={(props: any) => this.handleRenderMark(props)}
                onChange={(value) => this.handlePositionChange(value)}
            />
        )

        const currentTimeDisplay = durationToPlayerDisplayString(position);
        const durationDisplay = durationToPlayerDisplayString(duration);
        const playPauseIconType = isMediaPlaying ? <PauseIcon size="medium"/> : <PlayIcon size="medium"/>;
        const playPauseIconTitle = isMediaPlaying ? "Pause" : "Play";

        return (
            <div className='width-100'>
                { /* Progress bar */ }
                <div className={`${css.controls} display--flex width-100`}>
                    <div className={css.progressBarContainer}>
                        {progressBarRendered}
                    </div>
                </div>
                <div className={`${css.controls} display--flex width-100`}>                    
                    { /* Play/pause button */ }
                    <div style = {{marginLeft: 3}}>
                        <PlayerControlsButton
                            size="medium"
                            action={this.props.onPlayPauseToggle}
                            icon={playPauseIconType}
                            title={playPauseIconTitle} />
                    </div>

                    { /* Current time / total duration */ }
                    <div className={`${css.timeDisplay} playercontrols-timedisplay`}>
                        <label htmlFor="progress" role="timer">{currentTimeDisplay}</label> / <label htmlFor="progress" role="timer">{durationDisplay}</label>
                    </div>
                    {volumeControlsRendered}
                    {subtitleButtonRendered}
                    {downloadButtonRendered}
                    {fullscreenIconRendered}
                </div>
            </div>
        );
    }
}

// wrapper from player controls button
const PlayerControlsButton = ({ size, action, icon, title, height = '2rem' }: any) => {
    
    return (
        <Button
            iconOnly
            size={size}
            onClick={action}
            icon={icon}
            title={title}
            variables={{
                backgroundColor: 'inherit',
                height: height
            }}
        />
    )
}

export const PlayerControls = connectTeamsTheme(PlayerControlsInternal);