// @ts-nocheck

import React, { Component } from 'react';
import WaveSurferComponent, { WaveSurferParams } from "wavesurfer.js"; // https://wavesurfer-js.org/docs/
import { getReadableTimestamp } from '../../logic/timeDisplay';
import { isIOS } from '../../logic/platformDetection';
import { PlayerControls } from './PlayerControls';
import log from 'loglevel';
import { connectTeamsTheme } from "./../../context/connectTeamsTheme";
import css from './AudioPlayer.module.scss';
import { TimelineEvent } from '../../context/TimelineContext';

const maxProgressUpdatesPerSecond = 10;

type positionRequest = {
    position: number,
}

type OnTimeUpdateCallback = (currentTime: number) => void;

interface AudioPlayerProps {
    contentUrl: string;
    waveforms: number[][]
    timelineEvents: TimelineEvent[];
    positionRequest: positionRequest;
    onTimeUpdate: OnTimeUpdateCallback;
    context?: any;
    onDownload: () => void;
}

interface AudioPlayerInternalProps extends AudioPlayerProps { }

interface AudioPlayerState {
    isMuted: boolean,
    isAudioPlaying: boolean,
    hasError: boolean,
    volume: number,
    position: number,
    duration: number  
}

class AudioPlayerInternal extends Component<AudioPlayerInternalProps, AudioPlayerState> {
    state = {
        isMuted: false,
        isAudioPlaying: false,
        hasError: false,
        volume: 1,
        position: 0,
        duration: 0      
    }

    lastCurrentTime = 0;
    wavesurferContainer: React.RefObject<HTMLDivElement> = React.createRef();
    wavesurfer: React.MutableRefObject<WaveSurfer | null | undefined> = React.createRef();

    componentDidMount() {
        log.debug(`[AudioPlayer componentDidMount] ${getReadableTimestamp()}`);

        this.initializeAudioPlayer(this.props.contentUrl);
    }

    componentDidUpdate(prevProps: AudioPlayerProps) {
        // Note: The position is wrapped in an object to be able to detect a position request with the same value as the previous one.
        if (prevProps.positionRequest !== this.props.positionRequest) {
            const requestedPosition = this.props.positionRequest.position;
            if (!requestedPosition) {
                return;
            }
            
            this.setPlayerPosition(requestedPosition);
        }
    }

    componentWillUnmount() {
        this.unregisterAudioElementEvents();
    }    

    getWaveSurferOrThrow = () => {
        if (!this.wavesurfer.current) {
            log.error("[getWaveSurferOrThrow] WaveSurfer reference is unexpectedly empty.");
            throw new Error("WaveSurfer reference is unexpectedly empty.");
        }

        return this.wavesurfer.current;
    }

    setPlayerPosition = (position: number) => {
        this.getWaveSurferOrThrow().setCurrentTime(position);

        this.updatePlayerProgress();
    }

    updatePlayerProgress = () => {
        const waveSurfer = this.wavesurfer.current;
        if (!waveSurfer || isNaN(waveSurfer.getDuration())) {
            return; // Wavesurfer not fully created or rendering not finished yet.
        }

        const currentTime = waveSurfer.getCurrentTime();
        const duration = waveSurfer.getDuration();

        this.setState({
            position: currentTime,
            duration: duration
        });       
    }

    initializeAudioPlayer = (url: string) => {
        // Destroy any previous instance of the audio player, which might happen when the playback failed with an error.
        if (this.wavesurfer.current) {
            this.wavesurfer.current.destroy();
        }

        const waveSurferContainer = this.wavesurferContainer.current;
        if (!waveSurferContainer) {
            log.error("[initializeAudioPlayer] WaveSurfer container reference is unexpectedly empty.");;
            return;
        }

        const options: WaveSurferParams = {
            container: waveSurferContainer,
            backend: "MediaElement",
            mediaType: "audio",
            waveColor: "#eee",
            progressColor: "#6264A7",
            cursorWidth: 0, // Hide the cursor
            hideScrollbar: true,
            interact: false,        
            barWidth: 1,
            barRadius: 3,
            responsive: true,
            height: 200,
            normalize: true, // Docs: If true, normalize by the maximum peak instead of 1.0.
            splitChannels: true,
        };

        // Create a new audio wave based on the options.
        this.wavesurfer.current = WaveSurferComponent.create(options);
        this.wavesurfer.current.song = url;

        this.registerAudioElementEvents();

        // Load the audio with the url provided.
        this.wavesurfer.current.load(this.wavesurfer.current.song, this.props.waveforms, 'auto');
    }

    registerAudioElementEvents = () => {
        const wavesurfer = this.getWaveSurferOrThrow();

        wavesurfer.on("error", this.handleAudioOnError);

        wavesurfer.on("ready", this.handleAudioOnReady); // Docs: When audio is loaded, decoded and the waveform drawn. This fires before the waveform is drawn when using MediaElement.
        wavesurfer.on("audioprocess", this.handleAudioOnAudioProcess); // Docs: Fires continuously as the audio plays. Also fires on seeking.
        wavesurfer.on("play" , this.handleAudioOnPlay);
        wavesurfer.on("pause" , this.handleAudioOnPause);
        wavesurfer.on("finish" , this.handleAudioOnFinish);
        wavesurfer.on("mute", this.handleAudioOnMute);
        wavesurfer.on("volume", this.handleAudioOnVolume);
        wavesurfer.on("seek", this.handleAudioOnSeek);
    }

    unregisterAudioElementEvents = () => {
        const wavesurfer = this.getWaveSurferOrThrow();

        wavesurfer.unAll();
    }

    handleAudioOnError = (error: string) => {
        log.error(error);

        this.setState({ 
            hasError: true,
            isAudioPlaying: false 
        });
    }

    handleAudioOnReady = () => {
        // Automatically start playing when the player is ready, except for iOS where playing the audio
        // without user gesture results in an error.
        if (!isIOS()) {
            this.startPlayAudio();
        }

        this.getWaveSurferOrThrow().setVolume(this.state.volume);
    }

    handleAudioOnAudioProcess = (newCurrentTime: number) => {        
        var truncated = Math.trunc(newCurrentTime * maxProgressUpdatesPerSecond)
        if (this.lastCurrentTime === truncated) {                
            return; // This event is fired about 50 times per second, this is now reduced to 'maxTimeUpdatesPerSecond' times per second.
        }       

        this.lastCurrentTime = truncated;
        this.props.onTimeUpdate(newCurrentTime);
        this.updatePlayerProgress();        
    }

    handleAudioOnPlay = () => {
        log.debug(`[onPlay] ${getReadableTimestamp()}`);
        this.setState({ isAudioPlaying: true });
    }

    handleAudioOnPause = () => {
        log.debug(`[onPause] ${getReadableTimestamp()}`);
        this.setState({ isAudioPlaying: false });
    }

    handleAudioOnFinish = () => {
        this.setState({ isAudioPlaying: false });
        this.updatePlayerProgress();
    }

    handleAudioOnMute = (isMuted: boolean) => {
        this.setState({ isMuted: isMuted });
    }

    handleAudioOnVolume = (newVolume: number) => {
        const showMuted = newVolume === 0;
        this.setState(
            { 
                volume: newVolume,
                isMuted: showMuted
            });
    }

    handleAudioOnSeek = (position: number) => {
        log.debug(`[handleAudioOnSeek] ${position}`);
    }

    startPlayAudio = () => {
        const waveSurfer = this.getWaveSurferOrThrow();
        if (!waveSurfer.isPlaying()) {
            waveSurfer.play()?.catch(error => log.error(`Error occurred while starting the playback of the audio: ${error}`));            
        }
    }
       
    handlePlayPauseToggle = () => {
        if (this.state.hasError) {
            this.setState({hasError: false});           
            this.initializeAudioPlayer(this.props.contentUrl);
        }

        this.getWaveSurferOrThrow().playPause();
    };

    handleVolumeChange = (volume: number) => {
        const scaledVolume = volume / 100;
        const waveSurfer = this.getWaveSurferOrThrow();

        if (scaledVolume > 0) {
            waveSurfer.setVolume(scaledVolume);
            waveSurfer.setMute(false);
        }
        else {
            waveSurfer.setMute(true);
        }
    };

    handleMuteToggle = () => {
        const waveSurfer = this.getWaveSurferOrThrow();

        waveSurfer.setMute(!waveSurfer.getMute());        
    }

    handlePositionChange = (position: number) => {
        this.getWaveSurferOrThrow().setCurrentTime(position);
        this.updatePlayerProgress();
    }

    render() {
        const { isMuted, isAudioPlaying, volume, position, duration } = this.state;
        const { style } = this.props.context;

        return (
            <div className={`${css.audioContainer} noselect`}> 
                <div ref={this.wavesurferContainer}/>
                <div className={`${css.playerControlsContainer} ${style === 0 ? css.lightTheme : ''}`}>
                    <PlayerControls 
                        showFullscreenIcon={false}
                        showSubtitleIcon={false}
                        showVolumeControls={!isIOS()}
                        isMediaPlaying={isAudioPlaying}
                        isSubtitleActive={false}
                        isMuted={isMuted}
                        volume={volume * 100}
                        timelineEvents={this.props.timelineEvents}
                        duration={duration}
                        position={position}
                        onFullScreenToggle={() => {}}
                        onPlayPauseToggle={this.handlePlayPauseToggle}
                        onSubtitleToggle={() => {}}
                        onVolumeChange={this.handleVolumeChange}
                        onMuteToggle={this.handleMuteToggle}
                        onPositionChange={this.handlePositionChange}
                        onDownload={this.props.onDownload} />
                </div>     
            </div>
        )
    }
}

export const AudioPlayer = connectTeamsTheme(AudioPlayerInternal);
