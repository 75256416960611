import { FC } from 'react';
import { Loader } from '@fluentui/react-northstar';
import css from './VerticallyAlignedSpinner.module.scss';

interface VerticallyAlignedSpinnerProps {
  description: string;
}

export const VerticallyAlignedSpinner: FC<VerticallyAlignedSpinnerProps> = ({ description }) => {
    return (
        <Loader className={css.alignVertically} label={description} />
    );
};