import { toDisplayString } from '../../../logic/displayString';
import { Checkbox } from '@fluentui/react-northstar';

const FacetFieldCheckboxes = ({ facetData, facetSelection, onChangeFacet, name }: any) => {

    if (!facetData || !facetSelection) {
        return null;
    }

    const sortedValueNames = Object.keys(facetData).sort()

    const checkboxes = sortedValueNames.map((valueKey: string, index) => {
        const value = facetData[valueKey];

        if (!value) {
            return null;
        }

        // Don't display facets that have value 0.
        // if (!(value.count) || value.count <= 0) {
        //     return null;
        // }

        const countDisplay = value.count ? `(${value.count})` : '(0)';
        const isChecked: boolean = facetSelection.some((item: any) => item === valueKey);

        let displayValue = toDisplayString(valueKey);

        return (
            <li key={index + 1}>
                <Checkbox label={`${displayValue} ${countDisplay}`}
                    checked={isChecked}
                    disabled={!value.count}
                    onClick={() => onChangeFacet(name, valueKey)}
                />
            </li>
        );
    });

    return checkboxes
}

export default FacetFieldCheckboxes