import { Field } from '../../../types/Field';
import IntFacet from './IntFacetPresentation';
import { FacetLogic } from './Types';

function createFilter(facetSelection: any, field: Field) {
    // facetSelection is an array of int values.
    const filterClauses = facetSelection.map((selection: any) => field.systemSettings.filterClause.replace('{{VALUE}}', selection).replace('{{OPERATOR}}', 'eq'));

    if (filterClauses.length === 0) {
        return "";
    }

    const filterClausesString = filterClauses.join(' and ');    
    return `(${filterClausesString})`;    
}

function createRequest(field: Field) {
    return field.name;
}

function extractFacetValues(facetValuesFromSearch: any) {
    let extractedValues: any = {};

    facetValuesFromSearch.forEach((facetValue: any) => {
        extractedValues[facetValue.value] = {
            count: facetValue.count
        }
    });

    return extractedValues;
}

function renderFacet (field: Field, facetValue: any, facetSelection: any, onChanged: any) { 
    return <IntFacet 
              key={field.name} 
              name={field.name} 
              displayName={field.userSettings.displayName} 
              facetData={facetValue} 
              facetSelection={facetSelection}
              onChangeFacet={onChanged}/>          
}

// The facetValue contains the value of the entry that has been clicked.
// If this entry currently exists in the selection it will be removed else it will be added.
function createUpdatedSelection(facetValue: any, prevFacetSelection: any) {
    prevFacetSelection = prevFacetSelection || [];

    const isValueCurrentlySelected = prevFacetSelection.some((item: any) => item === facetValue);
    const newSelectionsOfFacet = isValueCurrentlySelected ?
        prevFacetSelection.filter((item: any) => item !== facetValue) : // Remove item
        [...prevFacetSelection, facetValue]; // Add item

    return newSelectionsOfFacet;
}

export const intFacetLogic : FacetLogic= {
    dataType: 'Edm.Int32',
    createFilter: createFilter,
    createRequest: createRequest,
    extractFacetValues: extractFacetValues,
    renderFacet: renderFacet,
    createUpdatedSelection: createUpdatedSelection,
}