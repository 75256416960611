import * as azmaps from "azure-maps-control";
import { CentreControlOptions } from './FollowControlOptions';

export class CentreControl implements azmaps.Control {

    private _container: HTMLElement | null = null;
    private _button: HTMLButtonElement | null = null;
    private _darkColor = '#011c2c';
    private _hclStyle: azmaps.ControlStyle | null = null;
    private _map: azmaps.Map | null = null;
    private _isActive = false
    private _options: CentreControlOptions = {
        style: 'light',
        padding: 100,
        onclick: () => {console.warn("you should handle the onclick of the FollowControl.")}
    };

    private _buttonCSS = 
        ".azmaps-centreBtn{margin:0;padding:0;border:none;border-collapse:collapse;width:32px;height:32px;text-align:center;cursor:pointer;line-height:32px;background-repeat:no-repeat;background-size:20px;background-position:center center;z-index:200;box-shadow:0px 0px 4px rgba(0,0,0,0.16);}" +
        ".azmaps-centreBtn-pressed{background-image:url(\"data:image/svg+xml,%3Csvg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.78.982a1.64 1.64 0 0 0-1.148 2.815l-1.59 3.181a1.515 1.515 0 0 0-1.108.223L6.473 5.95a1.645 1.645 0 1 0-2.614.573L2.409 9.71a1.608 1.608 0 0 0-.188-.02 1.659 1.659 0 1 0 1.1.432L4.77 6.934c.063.01.126.016.19.019.303-.001.6-.088.857-.249L7.28 7.957a1.644 1.644 0 1 0 2.66-.535l1.59-3.18c.082.015.166.023.25.025a1.643 1.643 0 0 0 0-3.286zM2.22 11.976a.643.643 0 1 1 0-1.285.643.643 0 0 1 0 1.285zM4.96 5.953a.643.643 0 1 1 0-1.286.643.643 0 0 1 0 1.286zm3.833 3.285a.643.643 0 1 1 0-1.285.643.643 0 0 1 0 1.285zm2.987-5.97a.643.643 0 1 1 0-1.286.643.643 0 0 1 0 1.285z' fill='%2331ACCE'/%3E%3C/svg%3E\")}" +
        ".azmaps-centreBtn-pressed:hover{background-image:url(\"data:image/svg+xml,%3Csvg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.78.982a1.64 1.64 0 0 0-1.148 2.815l-1.59 3.181a1.515 1.515 0 0 0-1.108.223L6.473 5.95a1.645 1.645 0 1 0-2.614.573L2.409 9.71a1.608 1.608 0 0 0-.188-.02 1.659 1.659 0 1 0 1.1.432L4.77 6.934c.063.01.126.016.19.019.303-.001.6-.088.857-.249L7.28 7.957a1.644 1.644 0 1 0 2.66-.535l1.59-3.18c.082.015.166.023.25.025a1.643 1.643 0 0 0 0-3.286zM2.22 11.976a.643.643 0 1 1 0-1.285.643.643 0 0 1 0 1.285zM4.96 5.953a.643.643 0 1 1 0-1.286.643.643 0 0 1 0 1.286zm3.833 3.285a.643.643 0 1 1 0-1.285.643.643 0 0 1 0 1.285zm2.987-5.97a.643.643 0 1 1 0-1.286.643.643 0 0 1 0 1.285z' fill='%2383888D'/%3E%3C/svg%3E\")}" +
        ".azmaps-centreBtn-unpressed{background-image:url(\"data:image/svg+xml,%3Csvg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.78.982a1.64 1.64 0 0 0-1.148 2.815l-1.59 3.181a1.515 1.515 0 0 0-1.108.223L6.473 5.95a1.645 1.645 0 1 0-2.614.573L2.409 9.71a1.608 1.608 0 0 0-.188-.02 1.659 1.659 0 1 0 1.1.432L4.77 6.934c.063.01.126.016.19.019.303-.001.6-.088.857-.249L7.28 7.957a1.644 1.644 0 1 0 2.66-.535l1.59-3.18c.082.015.166.023.25.025a1.643 1.643 0 0 0 0-3.286zM2.22 11.976a.643.643 0 1 1 0-1.285.643.643 0 0 1 0 1.285zM4.96 5.953a.643.643 0 1 1 0-1.286.643.643 0 0 1 0 1.286zm3.833 3.285a.643.643 0 1 1 0-1.285.643.643 0 0 1 0 1.285zm2.987-5.97a.643.643 0 1 1 0-1.286.643.643 0 0 1 0 1.285z' fill='%2383888D'/%3E%3C/svg%3E\")}" +
        ".azmaps-centreBtn-unpressed:hover{background-image:url(\"data:image/svg+xml,%3Csvg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.78.982a1.64 1.64 0 0 0-1.148 2.815l-1.59 3.181a1.515 1.515 0 0 0-1.108.223L6.473 5.95a1.645 1.645 0 1 0-2.614.573L2.409 9.71a1.608 1.608 0 0 0-.188-.02 1.659 1.659 0 1 0 1.1.432L4.77 6.934c.063.01.126.016.19.019.303-.001.6-.088.857-.249L7.28 7.957a1.644 1.644 0 1 0 2.66-.535l1.59-3.18c.082.015.166.023.25.025a1.643 1.643 0 0 0 0-3.286zM2.22 11.976a.643.643 0 1 1 0-1.285.643.643 0 0 1 0 1.285zM4.96 5.953a.643.643 0 1 1 0-1.286.643.643 0 0 1 0 1.286zm3.833 3.285a.643.643 0 1 1 0-1.285.643.643 0 0 1 0 1.285zm2.987-5.97a.643.643 0 1 1 0-1.286.643.643 0 0 1 0 1.285z' fill='%2331ACCE'/%3E%3C/svg%3E\")}";


    /**
     * Constructor
     * @param options Options for defining how the control is rendered and functions.
     */
    constructor(options?: CentreControlOptions) {
        this._options = Object.assign(this._options, options || {});
    }

    
    /**
     * Action to perform when the control is added to the map.
     * @param map The map the control was added to.
     * @param options The control options used when adding the control to the map.
     * @returns The HTML Element that represents the control.
     */
    public onAdd(map: azmaps.Map, options?: azmaps.ControlOptions): HTMLElement {
        const self = this;
        self._map = map;

        const mcl = map.getMapContainer().classList;
        if (mcl.contains("high-contrast-dark")) {
            self._hclStyle = azmaps.ControlStyle.dark;
        } else if (mcl.contains("high-contrast-light")) {
            self._hclStyle = azmaps.ControlStyle.light;
        }

        let color = 'light';

        if (self._hclStyle) {
            if (self._hclStyle === 'dark') {
                color = self._darkColor;
            }
        } else if(self._options.style) {
            color = self._options.style;
        }

        if (color === 'light') {
            color = 'white';
        } else if (color === 'dark') {
            color = self._darkColor;
        } else if (color === 'auto') {
            //Color will change between light and dark depending on map style.
            map.events.add('styledata', self._mapStyleChanged);
            color = self._getColorFromMapStyle();
        }

        //Add the CSS style for the control to the DOM.
        const style = document.createElement('style');
        style.innerHTML = self._buttonCSS;
        document.body.appendChild(style);

        //Create a button container.
        const c = document.createElement('div');
        c.classList.add('azure-maps-control-container');
        c.style.flexDirection = 'column';
        self._container = c;

        //Create the button.
        const btn = document.createElement("button");
        btn.classList.add('azmaps-centreBtn');
        btn.style.backgroundColor = color;        
        btn.setAttribute('type', 'button');
        self._button = btn;
        this.updateButtonStyle();

        btn.addEventListener('click', () => {            
            this._isActive = !this._isActive ;
            this.updateButtonStyle();
            this._options.onclick();
        });

        c.appendChild(btn);
        return c;
    }

    /**
     * Action to perform when control is removed from the map.
     */
    public onRemove(): void {
        const self = this;
        if (self._container) {
            self._container.remove();
            self._container = null;
        }

        if (self._options.style === 'auto' && self._map) {
            self._map.events.remove('styledata',self._mapStyleChanged);
        }

        self._map = null;
    }

    /**
     * An event handler for when the map style changes. Used when control style is set to auto.
     */
    private _mapStyleChanged = () => {
        const self = this;
        if (self._button && !self._hclStyle) {
            self._button.style.backgroundColor = self._getColorFromMapStyle();
        }
    }

    /**
     * Retrieves the background color for the button based on the map style. This is used when style is set to auto.
     */
    private _getColorFromMapStyle(): string {
        //When the style is dark (i.e. satellite, night), show the dark colored theme.
        let mapStyle = '';
        if(this._map){
            let style = this._map.getStyle().style;
            if(style){
                mapStyle = style;
            }            
        }
        
        if (['satellite', 'satellite_road_labels', 'grayscale_dark', 'night'].indexOf(mapStyle) > -1) {
            return this._darkColor;
        }

        return 'white';
    }

    private updateButtonStyle = () => {
        if (!this._button) {
            return;
        }

        let label: string;
        let removeClass: string;
        let addClass: string;

        if(this._isActive){
            removeClass = 'unpressed';
            addClass = 'pressed';
            label = "Follow";
        }
        else{
            removeClass = 'pressed';
            addClass = 'unpressed';
            label = "Unfollow";
        }

        const btn = this._button;

        btn.setAttribute('title', label);
        btn.setAttribute('alt', label);
        
        btn.classList.remove('azmaps-centreBtn-' + removeClass);
        btn.classList.add('azmaps-centreBtn-' + addClass);
    }
}