import { Dialog } from '@fluentui/react-northstar'
import { Component } from 'react';
import { LocationSelector } from './LocationSelector';
import { connectTeamsTheme } from "./../../context/connectTeamsTheme";

//onSelectedLocationChanged? (longitude: number, latitude: number): void;
export interface OnConfirmEventArgs {
    longitude: number;
    latitude: number;
    radius: number;
}
interface LocationSelectorDialogProps {
    open: boolean;
    onConfirm (eventArgs: OnConfirmEventArgs): void;
    onClose (): void;
}
interface LocationSelectorDialogState {
    longitude: number;
    latitude: number;
    radius: number;
}

export default class LocationSelectorDialogInternal extends Component<LocationSelectorDialogProps, LocationSelectorDialogState> {
    constructor(props: LocationSelectorDialogProps) {
        super(props);      
        this.state = {
            longitude: 0,
            latitude: 0,
            radius: 1000
        }
    }

    onClose = () => this.props.onClose()

    onConfirm = () => {
        if (this.state.latitude === 0){
            alert("Please select a location.");
            return;
        }
        this.props.onConfirm({
            latitude: this.state.latitude,
            longitude: this.state.longitude,
            radius: this.state.radius
        });
    }

    onRadiusChanged = (radius: number) => {
        this.setState({radius: radius});
    }

    onSelectedLocationChanged = (longitude: number, latitude: number) => {
        this.setState({longitude: longitude, latitude: latitude});
    }

    getContentDialog = () => {
        return <LocationSelector onRadiusChanged={this.onRadiusChanged} onSelectedLocationChanged={this.onSelectedLocationChanged} radius={this.state.radius}></LocationSelector>
    }

    render() {
        return (
            <> 
                <Dialog
                    className='location-block'
                    open={this.props.open}
                    cancelButton="Cancel"
                    confirmButton="Select"
                    content = {this.getContentDialog()}
                    header="Select location"     
                    onCancel={this.onClose}
                    onConfirm={this.onConfirm}       
                >                    
                </Dialog>
            </>
        )
    }    
}

export const LocationSelectorDialog = connectTeamsTheme(LocationSelectorDialogInternal as any);