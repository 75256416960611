import { ReactNode } from 'react';
import { TextItem } from '../types/transcription';

type onClickHighlightCallback = (offsetSeconds: number) => void;

// Method creates the rendered text content with highlighting applied. This is used in both the content of the Chat boxes in the transcription
// and the tooltip which is shown when a marker on the timeline of the videoplayer is hovered.
export const createContent = (textItem: TextItem, highlightClassname: any, onClickHighlight: onClickHighlightCallback) : ReactNode => {
    if (textItem.highlights.length === 0) {
        return textItem.content;
    }

    let output: ReactNode[]  = [];
    let currentPostition = 0;

    for (let highlight of textItem.highlights) {
        // Add the text from current position up to the first highlighted character.
        if (currentPostition < highlight.startPosition) {
            output.push(textItem.content.substr(currentPostition, highlight.startPosition - currentPostition));
        }

        // Add the highlighted text.        
        const textToHighlight = textItem.content.substr(highlight.startPosition, highlight.endPosition - highlight.startPosition + 1);
        const highlightedText = <span onClick={() => onClickHighlight(textItem.offsetSeconds + highlight.offsetSeconds)} key={`key-${currentPostition}`} className={highlightClassname}>{textToHighlight}</span>

        output.push(highlightedText);

        currentPostition = highlight.endPosition + 1;
    }

    // Add the part after the last highlighted character.
    if (currentPostition < textItem.content.length - 1) {
        output.push(textItem.content.substr(currentPostition));
    }
    
    return <div>{output}</div>;
}