import * as React from "react";
import './Pager.scss';
import { getStyle } from '../ThemeLogic';
import { style } from 'typestyle';
import { Button } from "@fluentui/react-northstar";
import {ChevronStartIcon, ChevronEndIcon} from '@fluentui/react-icons-northstar'
import { connectTeamsTheme } from "./../../context/connectTeamsTheme";

class PagerTemplate extends React.Component {

    render() {
        const teamsContext = {...this.props.context.theme.siteVariables, style: this.props.context.style};
        const { count, top, skip, showPager, pageUp, pageDown, loadPage } = this.props;

        const css = {
            pagerNav: 'pager-nav',
            pagerList: 'pager-list',
            pagerItem: 'pager-page-item',
            pagerItemDisabled: 'pager-page-item-disabled',
            pagerLink: 'pager-page-link',
            pagerItemActive: 'pager-page-item-active',
            screenReaderOnly: 'screen-reader-only'
        }

        const maxSkip = 100000;
        const maxPage = count > 0 ? Math.ceil(count / top) : Math.ceil(maxSkip / top) + 1;
        const activePage = skip / top + 1;
        const enablePrevious = activePage > 1;
        const enableNext = activePage < maxPage;
 
        let onPreviousClick = () => {
            return enablePrevious && pageDown();
        };


        let getCssClass = (page, isActive, isDisabled) => {
            let cssClass = isActive ? css.pagerItemActive : css.pagerItem;
            cssClass = isDisabled ? css.pagerItemDisabled : cssClass;
            return cssClass;
        };

        let generateButton = (page, isActive = false, index, isDisabled = false) => {
            const pagesSeparator = page === '...'
            let onPageClick = () => {
                return !pagesSeparator ? loadPage(page) : null
            };
            const background = getStyle(teamsContext).pagination.backgroundColor;
            const color = getStyle(teamsContext).pagination.color;
            const buttonClass = style({
                backgroundColor: isActive ?  background : null,
                color: isActive ?  color : null,
                $nest: {
                  '&:hover': {
                    backgroundColor: background,
                    color: color,
                    },
                },
              });
            return (
                <li key={index}>
                    <div
                        className={`${buttonClass} ${getCssClass(page, isActive, isDisabled)}`} 
                        onClick={onPageClick}>
                        {page}
                    </div>
                </li>
            );
        };

        let onNextClick = () => {
            return enableNext && pageUp();
        };

        const makePager = (maxPage, currentPage) => {
        
            if (maxPage <= 6) {
                return Array.from(Array(maxPage),((_, i) => generateButton(i+1, i+1 === currentPage, i)))
            }

            const ellipsisLeft = currentPage > 3
            const ellipsisRight = currentPage < maxPage - 2
            const start = ellipsisLeft ? (ellipsisRight ? currentPage - 1 : maxPage - 4) : 3
            const visablePages = [
                1,
                ellipsisLeft ? '...' : 2, 
                start,
                start + 1, 
                start + 2, 
                ellipsisRight ? '...' : maxPage - 1,
                maxPage
            ]

            return visablePages.map((el, i) => generateButton(el, el === currentPage, i, el === '...'))
        }

        const paginationList = makePager(maxPage, activePage)
        
        if (!showPager) {
            return <div></div>;
        }

        return (
            <nav aria-label="Page navigation" className={css.pagerNav}>
                <ul className={css.pagerList}>
                    <li key='previous'>
                        <Button
                            iconOnly
                            icon={<ChevronStartIcon size="large"/>}
                            onClick={onPreviousClick}
                            title="Previous"
                        />
                    </li>
                    {paginationList}
                    <li key='next'>
                        <Button
                            iconOnly
                            icon={<ChevronEndIcon size="large" />}
                            onClick={onNextClick}
                            title="Next"
                        />
                    </li>
                </ul>
            </nav>
        );
    }
}

const Pager = connectTeamsTheme(PagerTemplate);
export { Pager };