import TimeFacet from './TimeFacetPresentation';
import moment from 'moment';
import { FacetLogic } from './Types';
import { Field } from '../../../types/Field';

const timePeriodDefinitions = [
    { displayName: "Anytime", period: moment.duration(100, 'years'), default: true, showAlways: true},
    { displayName: "Last year", period: moment.duration(1, 'years'), default: false, showAlways: true},
    { displayName: "Last month", period: moment.duration(1, 'months'), default: false, showAlways: true},
    { displayName: "Last week", period: moment.duration(1, 'weeks'), default: false, showAlways: false},
    { displayName: "Last 3 days", period: moment.duration(3, 'days'), default: false, showAlways: false},
    { displayName: "Last day", period: moment.duration(1, 'days'), default: false, showAlways: false},
    { displayName: "Last 3 hours", period: moment.duration(3, 'hours'), default: false, showAlways: false},
    { displayName: "Last hour", period: moment.duration(1, 'hours'), default: false, showAlways: false},
]

function getDefaultTimePeriodIndex() {
    let defaultIndex = 0;

    timePeriodDefinitions.forEach((item, index) => {
        if (item.default) {
            defaultIndex = index;
        }
    });
    return defaultIndex;
}

function getFromDateTimeValue (facetSelection: any) {
    if (facetSelection && facetSelection.fromDateTime) {
        return facetSelection.fromDateTime;
    }
    return moment().subtract(timePeriodDefinitions[getDefaultTimePeriodIndex()].period);
}

function getToDateTimeValue (facetSelection: any) {
    if (facetSelection && facetSelection.toDateTime) {
        return facetSelection.toDateTime;
    }
    return moment();
}

function getselectedIndex (facetSelection:any ) {
    if (facetSelection && facetSelection.selectedIndex !== undefined) {
        return facetSelection.selectedIndex;
    }
    return getDefaultTimePeriodIndex();
}

function checkForSelectedPeriod(fromDateTime: any, toDateTime: any, checkedIndex: any, facetValues: any) {
    facetValues.forEach((facetValue: any) => {
        let facetFromDateTime = moment().subtract(timePeriodDefinitions[facetValue.periodIndex].period);
        let facetToDateTime = moment().subtract(moment.duration(5, 'minutes'));
        if (fromDateTime > facetFromDateTime || toDateTime < facetToDateTime) {
            facetValue.displaycount = false;
        } else {
            facetValue.displaycount = true;
        }

        if (facetValue.periodIndex === checkedIndex){
            facetValue.checked = true;
        } else {
            facetValue.checked = false;
        }
    }); 
}

function getDefaultSelection() {
    return {
        fromDateTime: getFromDateTimeValue(undefined),
        toDateTime: getToDateTimeValue(undefined),
        selectedIndex: getselectedIndex(undefined),
    };
}

function createFilter(facetSelection: any, field: Field) {
    const fromDateTime  = facetSelection.fromDateTime;
    const toDateTime  = facetSelection.toDateTime;
    const selectedIndex = facetSelection.selectedIndex;

    if (!fromDateTime || !toDateTime) {
        return "";
    }

    const fromFilter = field.systemSettings.filterClause.replace('{{VALUE}}', fromDateTime.format()).replace('{{OPERATOR}}', 'gt')
    if (selectedIndex !== null) {
        return `(${fromFilter})`; 
    }

    const toFilter = field.systemSettings.filterClause.replace('{{VALUE}}', toDateTime.format()).replace('{{OPERATOR}}', 'lt')
    return `(${fromFilter} and ${toFilter})`;    
}

function createRequest(field: Field) {
    let values = "";

    for (let i = 0; i < timePeriodDefinitions.length; i++) {
        if (values !== "") {
            values = values + "|";
        }
        values = values + moment().subtract(timePeriodDefinitions[i].period).utc().format();
    }

    if (values.length > 0) {
        return field.name + ",values:" + values
    }

    return field.name;
}

function extractFacetValues(facetValuesFromSearch: any) {
    let periodValues: any[] = [];
    // Skip the first facet result (is count till last year)
    for (let i = 1, facetIndex = 0; i < facetValuesFromSearch.length; i++, facetIndex++) {
        periodValues.push({
            displayName: timePeriodDefinitions[facetIndex].displayName,
            periodIndex:  facetIndex,
            period: timePeriodDefinitions[facetIndex].period,
            count: facetValuesFromSearch[i].count,
            checked: false, //Default all periods are not checked (is updated in renderFacet)
            showAlways: timePeriodDefinitions[facetIndex].showAlways,
        })

        // Add count to all previous periods because they overlap 
        for (let j = 0; j < facetIndex; j++) {
            periodValues[j].count = periodValues[j].count + facetValuesFromSearch[i].count;
        }
    }

    return periodValues.reverse();
}

function renderFacet (field: Field, facetValues: any, facetSelection: any, onChanged: any, searchContext: any) {
    let fromDateTime = getFromDateTimeValue(facetSelection);
    let toDateTime = getToDateTimeValue(facetSelection);
    let selectedIndex = getselectedIndex(facetSelection)

    checkForSelectedPeriod(fromDateTime, toDateTime, selectedIndex, facetValues);

    return <TimeFacet 
      key={field.name}
      name={field.name} 
      displayName={field.userSettings.displayName}
      periodData={facetValues} 
      fromDateTime={fromDateTime}
      toDateTime={toDateTime}
      onChangeFacet={onChanged}
      searchContext={searchContext}
    />
}

// The facetValue contains an object with the new from and to date-time. 
// The returned selection of this facet type is not based upon the previous selections, 
// the received value is simply returned.
function createUpdatedSelection(facetValue: any, prevFacetSelection: any) {

    return facetValue;
}

export const timeFacetLogic : FacetLogic = {
    dataType: 'Edm.DateTimeOffset',
    getDefaultSelection: getDefaultSelection,
    createFilter: createFilter,
    createRequest: createRequest,
    extractFacetValues: extractFacetValues,
    renderFacet: renderFacet,
    createUpdatedSelection: createUpdatedSelection,
}