import React, { Component } from 'react'
import { Avatar, MenuButton } from '@fluentui/react-northstar'
import { AuthorizationContext } from '../context/AuthorizationContext';
import css from './UserLogin.module.scss';

export class UserLogin extends Component<{}, {}> {
    context!: React.ContextType<typeof AuthorizationContext>;    
    static contextType = AuthorizationContext;

    handleClick = () => {        
        this.context!.logoutRedirect();
    }

    render() {
        var inputProps: any = {
            className: css.Avatar,
            name:  this.context!.displayName,
            "aria-label": "Logged-in user",
        };

        if (this.context!.profilePicture) {
            inputProps['image'] = this.context!.profilePicture;
        }
          
        return (<div>
            <MenuButton
                   trigger={<Avatar {...inputProps} />}
                   menu={[
                       {
                           key: '1',
                           content: 'Sign out',
                           onClick: this.handleClick
                       },
                   ]}
                   on="click"                   
                 />           
         </div>);
    }
}