import React, { Component, Fragment } from 'react';
import './PlaySideBar.scss'
import { Redirect } from 'react-router-dom';
import { urls } from '../../logic/urls';
import { connectTeamsTheme } from "./../../context/connectTeamsTheme";
import { getStyle } from '../ThemeLogic';
import { RecordingInfoTab } from './RecordingInfoTab';
import { TranscriptionTab } from './TranscriptionTab';
import { LocationTab } from './LocationTab';
import { PeopleTab } from './PeopleTab'
import { Flex, Button, MenuIcon, CloseIcon } from '@fluentui/react-northstar'
import { Tabs } from "./../common/Tabs";
import { SummarizationTab } from './SummarizationTab';

class PlaySideBarTemplate extends Component {


    state = {
        backToSearch: false,
        selectedTab: 'a',
      };


    selectTab(selectedTab) {
        this.setState({ selectedTab });
    }
    
    getTabs = () => {
        const tabs = [
            {
                text: 'Recording info',
                onSelect: () => this.selectTab('a'),
                id: 'a',
            },                
        ];

        if (this.props.textItems.length > 0) {
            tabs.push(
                {
                    text: 'Transcription',
                    onSelect: () => this.selectTab('b'),
                    id: 'b',
                }        
            );
        }

        if (this.props.mediaInfo.hasLocationTrace()){
            tabs.push({
                text: 'Location',
                onSelect: () => this.selectTab('c'),
                id: 'c',
            });
        }        
        
        if (this.props.mediaInfo.canHavePeople()){
            tabs.push({
                text: 'People',
                onSelect: () => this.selectTab('d'),
                id: 'd',
            });
        }        

        if (this.props.mediaInfo.canHaveSummarization()){
            tabs.push({
                text: 'Summarization',
                onSelect: () => this.selectTab('e'),
                id: 'e',
            });
        }

        return tabs;
    }

  render() {

    const teamsContext = {...this.props.context.theme.siteVariables, style: this.props.context.style}

    if (!this.props.mediaInfo) {
        return null;
    }

    if (this.state.backToSearch) {
        return <Redirect push to={`${urls.search}`}/>      
    }
    const { selectedTab } = this.state;
    const { handleOpenFilter, isPanelOpen } = this.props;

    return (
        <Fragment>
            <Flex className="video-left-panel-header">
                <div className="support-buttons">
                    <Button
                    primary 
                    className="back-button" 
                    style={getStyle(teamsContext).button} 
                    variables={{padding: '0', minWidth: '6.4rem'}}
                    onClick={() => this.setState({ backToSearch: true })} >Back to search</Button>
                    <div className="support-dropdown-menu">
                        <Button
                        iconOnly
                        onClick={handleOpenFilter}
                        icon={isPanelOpen ? <CloseIcon/> : <MenuIcon/>}
                        />
                    </div>
                    <div className={`tab-list ${isPanelOpen && ('active')}`}>
                        <Tabs
                        selectedTabId={this.state.selectedTab}
                        tabs={this.getTabs()}
                        />
                    </div>
                </div>
            </Flex>
            <Flex className="video-left-panel-">
                {(function(props) {
                    switch (selectedTab) {
                        case 'a':
                            return <RecordingInfoTab 
                            mediaInfo={props.mediaInfo} 
                            isPanelOpen={isPanelOpen} 
                            textItems={props.textItems}/>;
                        case 'b':
                            return <TranscriptionTab
                                    isPanelOpen={isPanelOpen}                            
                                    showItemAtOffsetSeconds={props.showItemAtOffsetSeconds}
                                    textItems={props.textItems}
                                    participants={props.participants}
                                    initialSearchQuery={props.initialSearchQuery}
                                    onExecuteSearch={props.onExecuteSearch}
                                    onSetPositionRequest={props.onSetPositionRequest}
                                    />;
                        case 'c':
                            return <LocationTab 
                                    isPanelOpen={isPanelOpen} 
                                    timeOffSetNavigationPointer={props.showItemAtOffsetSeconds}
                                    mediaInfo={props.mediaInfo}
                                    />;
                        case 'd':
                            return <PeopleTab 
                                    isPanelOpen={isPanelOpen} 
                                    timeOffSetNavigationPointer={props.showItemAtOffsetSeconds}
                                    mediaInfo={props.mediaInfo}
                                    />;                                    
                        case 'e':
                            return <SummarizationTab 
                                    isPanelOpen={isPanelOpen} 
                                    mediaInfo={props.mediaInfo}
                                    />;                                    
                        default:
                            return <div>Unknown tab</div>;
                    }
                })(this.props)}
            </Flex>
        </Fragment>
    );
  }
}

export const PlaySideBar = connectTeamsTheme(PlaySideBarTemplate);