import * as microsoftTeams from "@microsoft/teams-js";
import * as misc from '../utils/misc';
import log from 'loglevel';

// Module is a small layer above the Microsoft Teams SDK. It contains the following logic:
// - It will read the initial Teams theme from the 'theme' URLS query variable which is populated by Teams 
//   (or manually added to the URL when the page runs outside Teams).
// - Allows multiple registrations on the event which is raised when the Teams theme has changed.

// The collection of callbacks registered which are invoked upon every change of the Teams theme
let themeChangeCallbacks = [];

let currentTheme = misc.getQueryVariableFromUrl('theme') || 'dark';

// For now always initialize the Microsoft Teams app, even through this will fail when the application is running
// in the browser. This is required for now, becaues the initialization is also needed in the authentication
// pop-up, which cannot easily be detected.
microsoftTeams.app.initialize().then(() => {
    console.log(`Initialization of Teams SDK successful`);
    microsoftTeams.app.registerOnThemeChangeHandler(reportThemeChange);
}).catch(error => {
    console.error(`Initialization of Teams SDK failed ${error}`)
})

// Method invokes all the registered callbacks to notify the current theme.
function reportThemeChange(newTheme) {
    log.trace(`[microsoftTeamsLogic, reportThemeChange] newTheme: ${newTheme}`);
    
    currentTheme = newTheme;

    // Invoke all the registered callbacks to inform the client-code about the state change.
    themeChangeCallbacks.forEach(callback => {
        callback(newTheme);
    });
}

// Method allows a callback method to be registered which is invoked upon every change of the authorization state.
// The signature of the method is: callback(state)
// The state parameter is a value from the 'authorizationStates' enum.
function registerThemeChangeCallback(callback) {
    if (!callback || !misc.isFunction(callback)) {
        return;
    }

    // Add the provided callback to the collection of callbacks.
    themeChangeCallbacks.push(callback);

    // Immediately invoke the callback so that the client code is in-sync with the state of this module.
    callback(currentTheme);
}

function unregisterThemeChangeCallback(callback) {
    themeChangeCallbacks = themeChangeCallbacks.filter((item) => item !== callback);
}

function changeTheme(newTheme) {
    reportThemeChange(newTheme);
}

export const microsoftTeamsLogic = {
    registerThemeChangeCallback : registerThemeChangeCallback,
    unregisterThemeChangeCallback : unregisterThemeChangeCallback,
    changeTheme: changeTheme
}