export default class MediaReference {
    constructor(mediaId, contentReference) {
        this.mediaId = mediaId;
        this.contentReference = contentReference;
    }

    equals(other) {
        if (!other) {
            return false;
        }

        return other.mediaId === this.mediaId && other.contentReference === this.contentReference;
    }

    toString() {
        return `${this.mediaId}|${this.contentReference}`;
    }

    static parse(mediaReferenceString) {
        if (!mediaReferenceString) {
            throw new Error("mediaReferenceString cannot be empty.");
        }

        const splitted = mediaReferenceString.split('|');

        if (splitted.length !== 2) {
            throw new Error("Invalid format provided.")
        }

        return new MediaReference(splitted[0], splitted[1]);
    }
}