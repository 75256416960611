import React from 'react'

const CyberTwiceLogo = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 580 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" xmlnsSerif="http://www.serif.com/" style={{fillRule:'evenodd', clipRule:'evenodd', strokeLinecap:'round', strokeLinejoin:'round', strokeMiterlimit:2}}>
            <g id="Artboard2_notext" transform="matrix(0.751295,0,0,0.438934,0,-210.543)">
                <rect x="0" y="479.669" width="772" height="227.825" style={{fill:'none'}} />
                <g transform="matrix(1.14042,0,0,1.95198,-0.304982,481.587)">
                    <g>
                        <g transform="matrix(2,0,0,2,0.86612,0.294863)">
                            <path d="M332.764,45.262L42.647,45.262C41.4,46.991 40.678,48.292 39.062,49.685C35.774,52.522 32.837,52.813 31.966,52.95C31.831,52.973 31.697,52.981 31.564,52.981C30.599,52.981 29.724,52.429 29.44,51.593L28.132,47.743C27.962,47.243 28.029,46.702 28.319,46.247C28.61,45.79 29.099,45.456 29.676,45.315C29.733,45.304 29.797,45.275 29.857,45.262L5.22,45.262C2.832,45.262 0.895,48.203 0.895,50.644C0.895,53.085 2.832,56.107 5.22,56.107L332.764,56.107C335.151,56.107 337.084,53.085 337.084,50.644C337.084,48.203 335.151,45.262 332.764,45.262Z" style={{fill:'rgb(237,28,36)', fillRule:'nonzero'}} />
                            <path d="M44.192,34.024C43.85,34.236 43.448,34.354 43.025,34.354L42.906,34.354C42.122,34.354 41.414,33.945 41.015,33.304L41.659,34.922C42.074,35.772 42.268,36.482 42.268,37.143C42.268,37.942 41.992,38.555 41.725,39.151L41.657,39.303C40.724,41.306 39.475,43.151 38.095,44.607L38.463,44.607C40.299,42.419 42.038,39.215 44.192,34.024Z" style={{fill:'white', fillRule:'nonzero'}} />
                            <path d="M28.072,37.832C26.416,38.688 22.938,39.543 18.551,39.543C8.387,39.543 0.79,33.125 0.79,21.249C0.79,9.907 8.44,2.31 19.62,2.31C24.061,2.31 26.949,3.273 28.179,3.917L27.004,7.712C25.29,6.857 22.776,6.216 19.78,6.216C11.33,6.216 5.711,11.619 5.711,21.089C5.711,29.968 10.793,35.586 19.512,35.586C22.401,35.586 25.29,34.998 27.164,34.088L28.072,37.832Z" style={{fill:'rgb(64,63,65)', fillRule:'nonzero'}} />
                            <path d="M28.072,37.832C26.416,38.688 22.938,39.543 18.551,39.543C8.387,39.543 0.79,33.125 0.79,21.249C0.79,9.907 8.44,2.31 19.62,2.31C24.061,2.31 26.949,3.273 28.179,3.917L27.004,7.712C25.29,6.857 22.776,6.216 19.78,6.216C11.33,6.216 5.711,11.619 5.711,21.089C5.711,29.968 10.793,35.586 19.512,35.586C22.401,35.586 25.29,34.998 27.164,34.088L28.072,37.832Z" style={{fill:'none', stroke:'rgb(64,63,65)', strokeWidth: 0.89}} />
                            <path d="M34.109,13.311L39.728,28.609C40.367,30.321 41.009,32.354 41.439,33.907L41.546,33.907C42.027,32.354 42.562,30.376 43.204,28.504L48.339,13.311L53.315,13.311L46.253,31.766C42.883,40.647 40.583,45.193 37.373,48.028C35.019,50.009 32.772,50.812 31.596,51.024L30.419,47.066C31.596,46.746 33.146,45.995 34.538,44.767C35.821,43.749 37.373,41.932 38.496,39.524C38.71,39.041 38.871,38.72 38.871,38.401C38.871,38.133 38.763,37.758 38.496,37.224L28.975,13.311L34.109,13.311Z" style={{fill:'rgb(64,63,65)', fillRule:'nonzero'}} />
                            <path d="M34.109,13.311L39.728,28.609C40.367,30.321 41.009,32.354 41.439,33.907L41.546,33.907C42.027,32.354 42.562,30.376 43.204,28.504L48.339,13.311L53.315,13.311L46.253,31.766C42.883,40.647 40.583,45.193 37.373,48.028C35.019,50.009 32.772,50.812 31.596,51.024L30.419,47.066C31.596,46.746 33.146,45.995 34.538,44.767C35.821,43.749 37.373,41.932 38.496,39.524C38.71,39.041 38.871,38.72 38.871,38.401C38.871,38.133 38.763,37.758 38.496,37.224L28.975,13.311L34.109,13.311Z" style={{fill:'none', stroke:'rgb(64,63,65)', strokeWidth: 0.89}}/>
                            <path d="M57.936,0.973L62.645,0.973L62.645,17.182L62.752,17.182C64.41,14.293 67.406,12.474 71.578,12.474C77.997,12.474 82.492,17.825 82.492,25.635C82.492,34.943 76.607,39.543 70.83,39.543C67.085,39.543 64.143,38.099 62.164,34.675L62.055,34.675L61.788,38.954L57.723,38.954C57.882,37.189 57.936,34.568 57.936,32.267L57.936,0.973ZM62.645,28.577C62.645,29.166 62.698,29.754 62.803,30.289C63.713,33.552 66.496,35.798 69.865,35.798C74.841,35.798 77.731,31.787 77.731,25.85C77.731,20.606 75.054,16.167 70.027,16.167C66.817,16.167 63.821,18.413 62.912,21.944C62.752,22.532 62.645,23.175 62.645,23.923L62.645,28.577Z" style={{fill:'rgb(64,63,65)', fillRule:'nonzero' }}/>
                            <path d="M57.936,0.973L62.645,0.973L62.645,17.182L62.752,17.182C64.41,14.293 67.406,12.474 71.578,12.474C77.997,12.474 82.492,17.825 82.492,25.635C82.492,34.943 76.607,39.543 70.83,39.543C67.085,39.543 64.143,38.099 62.164,34.675L62.055,34.675L61.788,38.954L57.723,38.954C57.882,37.189 57.936,34.568 57.936,32.267L57.936,0.973ZM62.645,28.577C62.645,29.166 62.698,29.754 62.803,30.289C63.713,33.552 66.496,35.798 69.865,35.798C74.841,35.798 77.731,31.787 77.731,25.85C77.731,20.606 75.054,16.167 70.027,16.167C66.817,16.167 63.821,18.413 62.912,21.944C62.752,22.532 62.645,23.175 62.645,23.923L62.645,28.577Z" style={{fill:'none', stroke:'rgb(64,63,65)', strokeWidth:0.89}}  />
                            <path d="M91.103,26.811C91.211,33.176 95.222,35.797 99.931,35.797C103.3,35.797 105.387,35.211 107.098,34.516L107.954,37.832C106.296,38.58 103.407,39.49 99.288,39.49C91.372,39.49 86.557,34.194 86.557,26.438C86.557,18.574 91.211,12.421 98.753,12.421C107.206,12.421 109.397,19.856 109.397,24.619C109.397,25.582 109.346,26.33 109.239,26.865L91.103,26.811ZM104.852,23.441C104.905,20.499 103.62,15.791 98.38,15.791C93.564,15.791 91.531,20.178 91.156,23.441L104.852,23.441Z" style={{fill:'rgb(64,63,65)', fillRule:'nonzero' }} />
                            <path d="M91.103,26.811C91.211,33.176 95.222,35.797 99.931,35.797C103.3,35.797 105.387,35.211 107.098,34.516L107.954,37.832C106.296,38.58 103.407,39.49 99.288,39.49C91.372,39.49 86.557,34.194 86.557,26.438C86.557,18.574 91.211,12.421 98.753,12.421C107.206,12.421 109.397,19.856 109.397,24.619C109.397,25.582 109.346,26.33 109.239,26.865L91.103,26.811ZM104.852,23.441C104.905,20.499 103.62,15.791 98.38,15.791C93.564,15.791 91.531,20.178 91.156,23.441L104.852,23.441Z" style={{fill:'none', stroke:'rgb(64,63,65)', strokeWidth: 0.89}} />
                            <path d="M115.285,21.141C115.285,18.09 115.231,15.469 115.068,13.063L119.191,13.063L119.402,18.145L119.564,18.145C120.741,14.668 123.629,12.475 126.785,12.475C127.269,12.475 127.642,12.529 128.07,12.635L128.07,17.022C127.535,16.967 127.053,16.914 126.412,16.914C123.096,16.914 120.741,19.43 120.1,22.906C119.991,23.603 119.939,24.351 119.939,25.152L119.939,38.955L115.231,38.955L115.285,21.141Z" style={{fill:'rgb(64,63,65)', fillRule:'nonzero' }} />
                            <path d="M115.285,21.141C115.285,18.09 115.231,15.469 115.068,13.063L119.191,13.063L119.402,18.145L119.564,18.145C120.741,14.668 123.629,12.475 126.785,12.475C127.269,12.475 127.642,12.529 128.07,12.635L128.07,17.022C127.535,16.967 127.053,16.914 126.412,16.914C123.096,16.914 120.741,19.43 120.1,22.906C119.991,23.603 119.939,24.351 119.939,25.152L119.939,38.955L115.231,38.955L115.285,21.141Z" style={{fill:'none', stroke:'rgb(64,63,65)', strokeWidth:0.89}}  />
                            <path d="M139.732,6.857L128.765,6.857L128.765,2.899L155.458,2.899L155.458,6.857L144.439,6.857L144.439,38.956L139.732,38.956L139.732,6.857Z" style={{fill:'rgb(64,63,65)', fillRule:'nonzero' }} />
                            <path d="M139.732,6.857L128.765,6.857L128.765,2.899L155.458,2.899L155.458,6.857L144.439,6.857L144.439,38.956L139.732,38.956L139.732,6.857Z" style={{fill:'none', stroke:'rgb(64,63,65)', strokeWidth:0.89}}  />
                            <path d="M159.056,13.063L162.534,26.17C163.228,29.059 163.923,31.788 164.406,34.461L164.566,34.461C165.154,31.84 166.01,29.059 166.865,26.224L171.093,13.063L175.051,13.063L179.064,26.009C180.026,29.059 180.776,31.84 181.365,34.461L181.524,34.461C181.953,31.84 182.647,29.112 183.502,26.063L187.194,13.063L191.848,13.063L183.502,38.955L179.224,38.955L175.266,26.597C174.356,23.656 173.606,21.087 172.965,18.039L172.857,18.039C172.217,21.141 171.414,23.87 170.504,26.651L166.333,38.955L162.051,38.955L154.241,13.063L159.056,13.063Z" style={{fill:'rgb(64,63,65)', fillRule:'nonzero' }} />
                            <path d="M159.056,13.063L162.534,26.17C163.228,29.059 163.923,31.788 164.406,34.461L164.566,34.461C165.154,31.84 166.01,29.059 166.865,26.224L171.093,13.063L175.051,13.063L179.064,26.009C180.026,29.059 180.776,31.84 181.365,34.461L181.524,34.461C181.953,31.84 182.647,29.112 183.502,26.063L187.194,13.063L191.848,13.063L183.502,38.955L179.224,38.955L175.266,26.597C174.356,23.656 173.606,21.087 172.965,18.039L172.857,18.039C172.217,21.141 171.414,23.87 170.504,26.651L166.333,38.955L162.051,38.955L154.241,13.063L159.056,13.063Z" style={{fill:'none', stroke:'rgb(64,63,65)', strokeWidth:0.89}}  />
                            <path d="M198.803,8.302C197.038,8.302 195.861,6.911 195.861,5.254C195.861,3.594 197.093,2.257 198.912,2.257C200.731,2.257 201.908,3.594 201.908,5.254C201.908,6.911 200.731,8.302 198.857,8.302L198.803,8.302Z" style={{fill:'rgb(237,28,36)', fillRule:'nonzero' }}/>
                            <rect x="196.557" y="13.063" width="4.707" height="25.892" style={{fill:'rgb(64,63,65)'}}/>
                            <path d="M198.803,8.302C197.038,8.302 195.861,6.911 195.861,5.254C195.861,3.594 197.093,2.257 198.912,2.257C200.731,2.257 201.908,3.594 201.908,5.254C201.908,6.911 200.731,8.302 198.857,8.302L198.803,8.302Z" style={{fill:'rgb(237,28,36)', fillRule:'nonzero' }} />
                            <rect x="196.557" y="13.063" width="4.707" height="25.892" style={{fill:'none', stroke:'rgb(64,63,65)', strokeWidth:0.89}} />
                            <path d="M227.533,38.045C226.302,38.634 223.574,39.543 220.095,39.543C212.286,39.543 207.203,34.248 207.203,26.277C207.203,18.306 212.66,12.475 221.111,12.475C223.894,12.475 226.355,13.171 227.64,13.865L226.568,17.45C225.444,16.861 223.68,16.219 221.111,16.219C215.176,16.219 211.965,20.66 211.965,26.063C211.965,32.055 215.818,35.746 220.952,35.746C223.627,35.746 225.392,35.103 226.731,34.515L227.533,38.045Z" style={{fill:'rgb(64,63,65)', fillRule:'nonzero' }} />
                            <path d="M227.533,38.045C226.302,38.634 223.574,39.543 220.095,39.543C212.286,39.543 207.203,34.248 207.203,26.277C207.203,18.306 212.66,12.475 221.111,12.475C223.894,12.475 226.355,13.171 227.64,13.865L226.568,17.45C225.444,16.861 223.68,16.219 221.111,16.219C215.176,16.219 211.965,20.66 211.965,26.063C211.965,32.055 215.818,35.746 220.952,35.746C223.627,35.746 225.392,35.103 226.731,34.515L227.533,38.045Z" style={{fill:'none', stroke:'rgb(64,63,65)', strokeWidth:0.89}} />
                            <path d="M235.403,26.811C235.513,33.176 239.525,35.797 244.231,35.797C247.603,35.797 249.689,35.211 251.399,34.516L252.257,37.832C250.598,38.58 247.708,39.49 243.588,39.49C235.672,39.49 230.856,34.194 230.856,26.438C230.856,18.574 235.513,12.421 243.054,12.421C251.507,12.421 253.698,19.856 253.698,24.619C253.698,25.582 253.647,26.33 253.539,26.865L235.403,26.811ZM249.151,23.441C249.206,20.499 247.922,15.791 242.682,15.791C237.866,15.791 235.832,20.178 235.458,23.441L249.151,23.441Z" style={{fill:'rgb(64,63,65)', fillRule:'nonzero' }} />
                            <path d="M235.403,26.811C235.513,33.176 239.525,35.797 244.231,35.797C247.603,35.797 249.689,35.211 251.399,34.516L252.257,37.832C250.598,38.58 247.708,39.49 243.588,39.49C235.672,39.49 230.856,34.194 230.856,26.438C230.856,18.574 235.513,12.421 243.054,12.421C251.507,12.421 253.698,19.856 253.698,24.619C253.698,25.582 253.647,26.33 253.539,26.865L235.403,26.811ZM249.151,23.441C249.206,20.499 247.922,15.791 242.682,15.791C237.866,15.791 235.832,20.178 235.458,23.441L249.151,23.441Z" style={{fill:'none', stroke:'rgb(64,63,65)', strokeWidth:0.89}}  />
                            <path d="M49.377,53.535C49.131,53.652 48.553,53.799 47.818,53.799C45.705,53.799 44.617,52.475 44.617,50.727C44.617,48.641 46.104,47.489 47.954,47.489C48.669,47.489 49.215,47.636 49.458,47.761L49.168,48.85C48.897,48.731 48.508,48.623 48.007,48.623C46.919,48.623 46.068,49.285 46.068,50.652C46.068,51.877 46.793,52.648 48.017,52.648C48.445,52.648 48.897,52.566 49.176,52.447L49.377,53.535Z" style={{fill:'white', fillRule:'nonzero'}} />
                            <path d="M55.112,47.59L56.49,47.59L56.49,52.538L58.918,52.538L58.918,53.699L55.112,53.699L55.112,47.59Z" style={{fill:'white', fillRule:'nonzero'}} />
                            <path d="M66.794,53.799C64.99,53.799 63.92,52.43 63.92,50.689C63.92,48.859 65.098,47.488 66.894,47.488C68.77,47.488 69.795,48.894 69.795,50.579C69.795,52.593 68.59,53.799 66.804,53.799L66.794,53.799ZM66.857,52.702C67.791,52.702 68.327,51.823 68.327,50.627C68.327,49.527 67.811,48.585 66.857,48.585C65.904,48.585 65.371,49.512 65.371,50.652C65.371,51.806 65.925,52.702 66.849,52.702L66.857,52.702Z" style={{fill:'white', fillRule:'nonzero'}} />
                            <path d="M76.888,47.59L76.888,51.098C76.888,52.158 77.296,52.692 77.995,52.692C78.72,52.692 79.119,52.185 79.119,51.098L79.119,47.59L80.495,47.59L80.495,51.016C80.495,52.901 79.544,53.797 77.949,53.797C76.408,53.797 75.51,52.937 75.51,50.998L75.51,47.59L76.888,47.59Z" style={{fill:'white', fillRule:'nonzero'}} />
                            <path d="M86.501,47.68C87.009,47.59 87.669,47.543 88.359,47.543C89.529,47.543 90.29,47.734 90.871,48.177C91.504,48.649 91.904,49.413 91.904,50.498C91.904,51.667 91.479,52.474 90.897,52.974C90.253,53.508 89.267,53.763 88.071,53.763C87.354,53.763 86.836,53.718 86.501,53.67L86.501,47.68ZM87.879,52.657C87.997,52.683 88.196,52.683 88.359,52.683C89.621,52.692 90.445,52.003 90.445,50.543C90.445,49.23 89.701,48.603 88.506,48.603C88.205,48.603 87.997,48.634 87.879,48.658L87.879,52.657Z" style={{fill:'white', fillRule:'nonzero'}} />
                            <path d="M104.263,47.68C104.705,47.599 105.359,47.544 106.102,47.544C107.007,47.544 107.643,47.68 108.081,48.033C108.45,48.325 108.649,48.751 108.649,49.311C108.649,50.083 108.098,50.618 107.571,50.806L107.571,50.833C107.997,51.007 108.232,51.405 108.386,51.966C108.577,52.666 108.757,53.462 108.877,53.699L107.463,53.699C107.37,53.528 107.217,53.027 107.046,52.275C106.872,51.504 106.608,51.307 106.038,51.298L105.631,51.298L105.631,53.699L104.263,53.699L104.263,47.68ZM105.631,50.3L106.174,50.3C106.863,50.3 107.272,49.955 107.272,49.421C107.272,48.867 106.889,48.577 106.257,48.577C105.922,48.577 105.731,48.597 105.631,48.623L105.631,50.3Z" style={{fill:'white', fillRule:'nonzero'}} />
                            <path d="M118.082,51.116L115.834,51.116L115.834,52.566L118.345,52.566L118.345,53.7L114.458,53.7L114.458,47.591L118.217,47.591L118.217,48.725L115.834,48.725L115.834,49.993L118.082,49.993L118.082,51.116Z" style={{fill:'white', fillRule:'nonzero'}}/>
                            <path d="M128.575,53.535C128.33,53.652 127.75,53.799 127.017,53.799C124.904,53.799 123.816,52.475 123.816,50.727C123.816,48.641 125.302,47.489 127.152,47.489C127.868,47.489 128.413,47.636 128.658,47.761L128.365,48.85C128.095,48.731 127.705,48.623 127.207,48.623C126.12,48.623 125.265,49.285 125.265,50.652C125.265,51.877 125.993,52.648 127.216,52.648C127.642,52.648 128.095,52.566 128.375,52.447L128.575,53.535Z" style={{fill:'white', fillRule:'nonzero'}} />
                            <path d="M136.615,53.799C134.811,53.799 133.74,52.43 133.74,50.689C133.74,48.859 134.921,47.488 136.714,47.488C138.589,47.488 139.615,48.894 139.615,50.579C139.615,52.593 138.411,53.799 136.625,53.799L136.615,53.799ZM136.679,52.702C137.613,52.702 138.147,51.823 138.147,50.627C138.147,49.527 137.63,48.585 136.679,48.585C135.727,48.585 135.191,49.512 135.191,50.652C135.191,51.806 135.746,52.702 136.67,52.702L136.679,52.702Z" style={{fill:'white', fillRule:'nonzero'}} />
                            <path d="M145.332,47.68C145.776,47.599 146.426,47.544 147.171,47.544C148.078,47.544 148.71,47.68 149.147,48.033C149.519,48.325 149.718,48.751 149.718,49.311C149.718,50.083 149.166,50.618 148.639,50.806L148.639,50.833C149.066,51.007 149.301,51.405 149.454,51.966C149.646,52.666 149.825,53.462 149.944,53.699L148.53,53.699C148.437,53.528 148.285,53.027 148.113,52.275C147.941,51.504 147.678,51.307 147.107,51.298L146.699,51.298L146.699,53.699L145.332,53.699L145.332,47.68ZM146.699,50.3L147.244,50.3C147.934,50.3 148.341,49.955 148.341,49.421C148.341,48.867 147.96,48.577 147.325,48.577C146.989,48.577 146.799,48.597 146.699,48.623L146.699,50.3Z" style={{fill:'white', fillRule:'nonzero'}} />
                            <path d="M155.515,47.68C156.025,47.59 156.686,47.543 157.374,47.543C158.543,47.543 159.303,47.734 159.885,48.177C160.519,48.649 160.918,49.413 160.918,50.498C160.918,51.667 160.492,52.474 159.912,52.974C159.269,53.508 158.28,53.763 157.084,53.763C156.367,53.763 155.851,53.718 155.515,53.67L155.515,47.68ZM156.894,52.657C157.012,52.683 157.209,52.683 157.374,52.683C158.633,52.692 159.46,52.003 159.46,50.543C159.46,49.23 158.717,48.603 157.52,48.603C157.22,48.603 157.012,48.634 156.894,48.658L156.894,52.657Z" style={{fill:'white', fillRule:'nonzero'}}/>
                            <rect x="166.645" y="47.59" width="1.376" height="6.109" style={{fill:'white'}}/>
                            <path d="M174.029,53.699L174.029,47.59L175.641,47.59L176.913,49.83C177.273,50.471 177.625,51.232 177.91,51.921L177.926,51.921C177.846,51.115 177.817,50.291 177.817,49.365L177.817,47.59L179.086,47.59L179.086,53.699L177.634,53.699L176.33,51.342C175.969,50.681 175.56,49.9 175.27,49.186L175.234,49.186C175.281,49.999 175.298,50.864 175.298,51.858L175.298,53.699L174.029,53.699Z" style={{fill:'white', fillRule:'nonzero'}} />
                            <path d="M190.197,53.418C189.769,53.563 188.963,53.763 188.156,53.763C187.042,53.763 186.235,53.481 185.673,52.946C185.12,52.411 184.813,51.616 184.813,50.717C184.821,48.687 186.299,47.527 188.304,47.527C189.091,47.527 189.696,47.68 189.998,47.816L189.707,48.931C189.37,48.776 188.963,48.669 188.293,48.669C187.14,48.669 186.262,49.32 186.262,50.644C186.262,51.913 187.058,52.648 188.191,52.648C188.502,52.648 188.755,52.61 188.864,52.566L188.864,51.281L187.922,51.281L187.922,50.201L190.197,50.201L190.197,53.418Z" style={{fill:'white', fillRule:'nonzero'}}/>
                            <path d="M204.14,52.132L203.713,53.699L202.283,53.699L204.149,47.59L205.953,47.59L207.857,53.699L206.371,53.699L205.9,52.132L204.14,52.132ZM205.69,51.098L205.308,49.802C205.21,49.439 205.102,48.977 205.009,48.623L204.983,48.623C204.901,48.985 204.802,49.448 204.712,49.802L204.339,51.098L205.69,51.098Z" style={{fill:'white', fillRule:'nonzero'}} />
                            <path d="M213.481,53.699L213.481,47.59L215.096,47.59L216.365,49.83C216.728,50.471 217.08,51.232 217.362,51.921L217.382,51.921C217.298,51.115 217.272,50.291 217.272,49.365L217.272,47.59L218.539,47.59L218.539,53.699L217.088,53.699L215.784,51.342C215.421,50.681 215.014,49.9 214.724,49.186L214.687,49.186C214.735,49.999 214.751,50.864 214.751,51.858L214.751,53.699L213.481,53.699Z" style={{fill:'white', fillRule:'nonzero'}} />
                            <path d="M224.546,47.68C225.055,47.59 225.714,47.543 226.405,47.543C227.575,47.543 228.334,47.734 228.915,48.177C229.548,48.649 229.946,49.413 229.946,50.498C229.946,51.667 229.523,52.474 228.944,52.974C228.299,53.508 227.31,53.763 226.114,53.763C225.397,53.763 224.88,53.718 224.546,53.67L224.546,47.68ZM225.926,52.657C226.043,52.683 226.24,52.683 226.405,52.683C227.664,52.692 228.491,52.003 228.491,50.543C228.491,49.23 227.748,48.603 226.55,48.603C226.251,48.603 226.043,48.634 225.926,48.658L225.926,52.657Z" style={{fill:'white', fillRule:'nonzero'}} />
                            <path d="M243.72,52.132L243.296,53.699L241.861,53.699L243.73,47.59L245.536,47.59L247.436,53.699L245.951,53.699L245.481,52.132L243.72,52.132ZM245.271,51.098L244.891,49.802C244.789,49.439 244.683,48.977 244.592,48.623L244.563,48.623C244.481,48.985 244.382,49.448 244.29,49.802L243.918,51.098L245.271,51.098Z" style={{fill:'white', fillRule:'nonzero'}} />
                            <path d="M253.063,47.68C253.507,47.599 254.161,47.544 254.904,47.544C255.809,47.544 256.441,47.68 256.879,48.033C257.25,48.325 257.449,48.751 257.449,49.311C257.449,50.083 256.897,50.618 256.37,50.806L256.37,50.833C256.799,51.007 257.032,51.405 257.188,51.966C257.377,52.666 257.56,53.462 257.677,53.699L256.262,53.699C256.171,53.528 256.019,53.027 255.846,52.275C255.674,51.504 255.411,51.307 254.838,51.298L254.431,51.298L254.431,53.699L253.063,53.699L253.063,47.68ZM254.431,50.3L254.977,50.3C255.665,50.3 256.072,49.955 256.072,49.421C256.072,48.867 255.692,48.577 255.057,48.577C254.721,48.577 254.533,48.597 254.431,48.623L254.431,50.3Z" style={{fill:'white', fillRule:'nonzero'}} />
                            <path d="M267.689,53.535C267.443,53.652 266.865,53.799 266.13,53.799C264.019,53.799 262.931,52.475 262.931,50.727C262.931,48.641 264.417,47.489 266.267,47.489C266.981,47.489 267.527,47.636 267.771,47.761L267.481,48.85C267.211,48.731 266.818,48.623 266.32,48.623C265.232,48.623 264.381,49.285 264.381,50.652C264.381,51.877 265.105,52.648 266.331,52.648C266.757,52.648 267.211,52.566 267.492,52.447L267.689,53.535Z" style={{fill:'white', fillRule:'nonzero'}}/>
                            <path d="M274.802,47.59L274.802,49.946L277.077,49.946L277.077,47.59L278.457,47.59L278.457,53.699L277.077,53.699L277.077,51.151L274.802,51.151L274.802,53.699L273.425,53.699L273.425,47.59L274.802,47.59Z" style={{fill:'white', fillRule:'nonzero'}} />
                            <rect x="284.482" y="47.59" width="1.377" height="6.109" style={{fill:'white'}} />
                            <path d="M293.305,53.699L291.347,47.59L292.858,47.59L293.605,50.173C293.813,50.908 294.005,51.595 294.149,52.358L294.174,52.358C294.33,51.625 294.52,50.897 294.729,50.2L295.507,47.59L296.977,47.59L294.918,53.699L293.305,53.699Z" style={{fill:'white', fillRule:'nonzero'}}/>
                            <rect x="302.447" y="47.59" width="1.378" height="6.109" style={{fill:'white'}} />
                            <path d="M309.833,53.699L309.833,47.59L311.445,47.59L312.717,49.83C313.08,50.471 313.43,51.232 313.71,51.921L313.731,51.921C313.649,51.115 313.622,50.291 313.622,49.365L313.622,47.59L314.891,47.59L314.891,53.699L313.439,53.699L312.134,51.342C311.773,50.681 311.364,49.9 311.073,49.186L311.038,49.186C311.084,49.999 311.104,50.864 311.104,51.858L311.104,53.699L309.833,53.699Z" style={{fill:'white', fillRule:'nonzero'}}/>
                            <path d="M326,53.418C325.575,53.563 324.768,53.763 323.96,53.763C322.847,53.763 322.04,53.481 321.476,52.946C320.922,52.411 320.616,51.616 320.616,50.717C320.625,48.687 322.104,47.527 324.105,47.527C324.896,47.527 325.502,47.68 325.799,47.816L325.511,48.931C325.177,48.776 324.768,48.669 324.096,48.669C322.946,48.669 322.068,49.32 322.068,50.644C322.068,51.913 322.864,52.648 323.998,52.648C324.306,52.648 324.558,52.61 324.667,52.566L324.667,51.281L323.725,51.281L323.725,50.201L326,50.201L326,53.418Z" style={{fill:'white', fillRule:'nonzero'}} />
                        </g>
                        <g transform="matrix(1,0,0,1,0.86612,0.294863)">
                            <rect x="86.966" y="91.655" width="572.793" height="18.568" style={{fill:'rgb(237,28,36) '}}/>
                        </g>
                        <g transform="matrix(0.225822,0,0,0.225822,517.552,15.2949)">
                            <path d="M80.568,77.195C100.012,70.373 145.545,54.394 207.759,52.354C267.291,50.406 320.573,57.516 402.119,98.944C411.073,103.493 414.58,109.595 415.009,110.91C416.063,114.798 416.488,119.026 416.591,120.739L416.591,121.086C416.591,135.461 428.244,147.113 442.617,147.113C456.991,147.113 468.645,135.461 468.645,121.086L468.631,119.269C468.595,118.164 468.195,108.161 465.249,97.294C460.395,79.384 445.609,62.652 425.695,52.536C346.646,12.377 285.063,-2.259 206.053,0.329C135.85,2.63 85.032,20.463 63.332,28.079C61.158,28.841 59.297,29.496 57.758,30.009C23.013,41.591 13.964,50.425 2.753,72.846C-3.674,85.703 1.536,101.336 14.392,107.765C27.249,114.192 42.882,108.981 49.311,96.126C51.857,91.033 53.161,88.627 54.159,87.823C56.613,85.847 63.362,83.01 74.219,79.392C75.972,78.807 78.093,78.065 80.568,77.195Z" style={{fill:'rgb(237,28,36)', fillRule:'nonzero' }} />
                            <path d="M249.905,139.102C240.012,148.446 239.065,163.71 247.369,174.175C251.663,182.982 260.698,188.79 270.733,188.79C271.953,188.79 273.186,188.704 274.427,188.527C288.659,186.506 298.556,173.331 296.534,159.098C295.883,154.515 293.648,147.507 286.697,140.149C276.827,129.7 260.355,129.229 249.905,139.102Z" style={{fill:'rgb(237,28,36)', fillRule:'nonzero' }} />
                            <path d="M182.648,128.853C170.643,132.187 161.311,139.887 156.372,150.534C151.699,160.605 151.459,172.65 155.746,181.969C160.132,191.505 169.555,197.128 179.407,197.127C183.045,197.127 186.744,196.359 190.267,194.74C197.431,191.444 202.386,185.304 204.407,178.277C205.048,176.049 206.287,174.058 207.932,172.425C214.401,166.005 217.303,156.371 214.69,146.965C210.844,133.115 196.496,125.008 182.648,128.853Z" style={{fill:'rgb(237,28,36)', fillRule:'nonzero' }} />
                            <path d="M535.726,162.512C539.615,174.865 540.995,185.917 538.406,199.935C538.401,199.964 538.395,200 538.39,200.03C484.027,183.016 425.82,189.524 388.602,195.644C390.703,157.468 377.541,118.708 366.822,97.653C360.302,84.841 344.63,79.746 331.82,86.266C319.011,92.787 313.913,108.458 320.434,121.267C331.289,142.59 344.005,186.858 331.917,214.876C326.855,226.61 317.869,234.019 303.639,238.193C272.18,247.422 228.085,249.505 188.559,243.63C146.202,237.336 126.77,224.732 124.41,219.952C113.65,197.201 119.444,161.885 142.125,111.988C148.073,98.904 142.287,83.473 129.201,77.525C116.115,71.578 100.686,77.363 94.737,90.449C75.979,131.715 53.77,192.343 77.354,242.209C93.08,275.455 142.092,289.294 180.438,295.048C198.091,297.698 216.319,299.001 234.267,299.001C264.461,299.001 293.85,295.311 318.29,288.142C340.749,281.554 358.528,269.124 370.615,251.809C374.899,251.01 379.501,250.143 381.195,249.77C411.64,244.277 472.344,233.564 523.761,250.002C523.089,250.716 522.392,251.328 521.661,251.796C491.082,271.372 493.479,289.684 493.479,289.684C513.784,282.434 546.589,276.165 561.455,273.851C576.321,271.537 602.613,266.452 623.292,260.122C669.69,245.919 688.77,211.518 688.77,211.518C633.026,223.408 535.726,162.512 535.726,162.512Z" style={{fill:'rgb(237,28,36)', fillRule:'nonzero' }} />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default CyberTwiceLogo;