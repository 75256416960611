const getStyle = (context) => {

    const { colors, style } = context;
    const styleProps = {};
    
 
    switch(style) {
        case 1:
            styleProps.color = colors.grey['0']
            styleProps.subSection = {
              backgroundColor: colors.grey['650'],
              backgroundColorHighlight: colors.brand['600']
            }
            styleProps.purpleColor = colors.brand['600']
            styleProps.pagination = colors.brand['600']
            styleProps.pagination =  {
              backgroundColor: colors.brand['600'],
              color: colors.grey['0']
            }
            styleProps.body = {
                backgroundColor: colors.grey['800']
            }
            styleProps.picker = {
              borderColor: colors.grey['0'],
              color: colors.grey['0']
            }
            styleProps.button = {
              color: colors.grey['0']
            }
            styleProps.paginationBackground = {
              backgroundColor: colors.grey['650']
            }
            break;
        case 2:
            styleProps.color = colors.grey['0']
            styleProps.body = {
                backgroundColor: colors.grey['1000']
            }
            styleProps.pagination =  {
              backgroundColor: colors.yellow['300'],
              color: colors.grey['1000']
            }
            styleProps.subSection = {
              backgroundColor: colors.grey['1000'],
              backgroundColorHighlight: colors.yellow['300']
            }
            styleProps.picker = {
              borderColor: colors.grey['0'],
              color: colors.grey['0']
            }
            styleProps.button = {
              color: colors.grey['1000']
            }
            styleProps.paginationBackground = {
              backgroundColor: colors.grey['1000']
            }
            break;
        case 0:
        default:
          styleProps.body = {
            backgroundColor: colors.grey['100']
          }
          styleProps.pagination = colors.grey['100']
          styleProps.pagination =  {
            backgroundColor: colors.grey['100'],
            color: colors.grey['750']
          }
          styleProps.color = colors.grey['750']
          styleProps.purpleColor = colors.brand['600']
          styleProps.subSection = {
            backgroundColor: colors.grey['100'],
            backgroundColorHighlight: colors.brand['600']
          }
          styleProps.picker = {
            borderColor: colors.grey['450'],
            color: colors.grey['750']
          }
          styleProps.button = {
            color: colors.grey['0']
          }
          styleProps.paginationBackground = {
            backgroundColor: colors.grey['0']
          }
    }

    return styleProps;
  }

  /**
   * Object containing all the logic for autosuggest dropdown
   */

  const autoSuggestTheme = (background, highlightColor, style) => {    
    return {
      suggestionsContainer: {
          backgroundColor: background,
          position: 'absolute',
          boxShadow: style === 0 ? '0px 0.13333333333333333rem 0.8rem 0px rgba(22, 35, 58, 0.36)' : style === 1 ? '0px 0.13333333333333333rem 0.8rem 0px rgba(0, 0, 0, 0.4)' : null,
          border: style === 2 ? '0.06666666666666667rem solid #FFFFFF' : '0',
          borderRadius: '0.2rem',
          zIndex: '1',
      },
      suggestion: {
        padding: '5px 10px 5px 10px',
          cursor: 'pointer',
          ':lastChild': {
              marginBottom: '0',
          },
      },
      suggestionHighlighted: {
        color: style === 2 ? '#000000' : '#FFFFFF',
        backgroundColor: highlightColor,
      }
    };
  }
  

  export { getStyle, autoSuggestTheme };