// Base class for errors thrown in the application. Error can contain an inner error with is the reason this error was thrown.
export class AppError extends Error {
    constructor(innerError, ...params) {
        super(...params);

        this.name = "AppError";
        this.innerError = innerError;
    }

    toDetailedMessage() {
        // Walk up the inner error chain and aggregate the error messages.
        let innerErrorsAggregated = "";
        if (this && this.innerError) {
            let currentInnerError = this.innerError;
            while (currentInnerError) {
                const currentInnerErrorText = `\t${currentInnerError.name}: ${currentInnerError.message}\r\n`
                innerErrorsAggregated = innerErrorsAggregated + currentInnerErrorText;
                currentInnerError = currentInnerError.innerError;
            }
        }

        let innerErrorText = innerErrorsAggregated.length > 0 ? `\r\nInner errors:\r\n${innerErrorsAggregated}` : "";
        return super.toString() + innerErrorText;
    }
}

// Error used when the retrieval of a token for an API failed.
export class TokenRetrievalError extends AppError {
    constructor(innerError, ...params) {
        super(innerError, ...params);

        this.name = "TokenRetrievalError";
        this.message = "Error occurred while retrieving an access token."
    }
}

export class CommunicationError extends AppError {
    constructor(innerError, ...params) {
        super(innerError, ...params);

        this.name = "CommunicationError";
        this.message = "Communication error occurred.";
    }
}

export class ServerErrorResponse extends AppError {
    constructor(innerError, statusCode, statusText, responseBody, ...params) {
        super(innerError, ...params);

        this.name = "ServerErrorResponse";
        this.statusCode = statusCode;
        this.statusText = statusText;
        this.responseBody = responseBody;

        const messageDetail = `Error detail: ${responseBody}` ? responseBody : "";
        this.message = `Server responded with ${statusCode} '${statusText}'. ${messageDetail}`;
    }
}

export const extractErrorMessage = (error) => {
    if (!error) {
        return "No error details available.";
    }

    const message = error.toDetailedMessage ? error.toDetailedMessage() : error.message;
    const combinedInfo = `Message:\r\n${message}\r\n\r\nStacktrace:\r\n${error.stack}`;

    // Ensure no Authorization header with the bearer token is shown.
    const filteredInfo = combinedInfo.replace(/^Authorization: .*$/gm, "");

    return filteredInfo;
}