import React, { Component } from 'react';
import * as utils from '../utils/misc';

const interClickTimeoutMs = 1000;
const clickCountThreshold = 7;
const size = 30;

export class MagicPixel extends Component {
    constructor(props) {
        super(props);
    
        this.magicPixelClickCount = 0;
        this.magicPixelTimeoutId = -1;
    }

    handleMagicPixelClick = () => {
        this.magicPixelClickCount++;
    
        clearTimeout(this.magicPixelTimeoutId);
    
        if (this.magicPixelClickCount === clickCountThreshold) {
            utils.toggleDebug();

            if (this.props.debugChanged) {
                this.props.debugChanged();
            }
        }
    
        this.magicPixelTimeoutId = setTimeout(() => this.magicPixelClickCount = 0, interClickTimeoutMs);
    }

    render() {
        const style = {
            position: 'absolute',
            width: size,
            height: size,
            left: 0,
            top: 0,            
        }

        return (
            <div style={style} onClick={this.handleMagicPixelClick}/>    
        )
    }
}