import {useState, MouseEvent} from 'react';
import css from './Faces.module.scss';
import { Face } from './types';

export interface FaceButtonListItemClickedEventArgs{
    id: string;
}

interface FaceButtonListItemProps {
    faceDate: Face;
    selected: boolean
    handleClick?: (e: FaceButtonListItemClickedEventArgs) => void;
}

export const FaceButtonListItem = (props: FaceButtonListItemProps) => {

    const [id] = useState(props.faceDate.id);

    const toggleCheck = (event: MouseEvent) => {    
        event.preventDefault();    
        if(props.handleClick){
            props.handleClick({id: id});
        }
    }

    let xPosition: number = -((props.faceDate.imageIndex) * 40)
    let backgroundPosition: string = xPosition.toString() + 'px 0px'

    const faceButtonClasses = props.selected ? 
        [css["face-button"], css["face-button-selected"]].join(" ") :
        css["face-button"];
    const imageClasses = css["face-button-image"];

    return (
        <div className={faceButtonClasses} title={props.faceDate.title}>
            <div key={props.faceDate.id} className={imageClasses} 
                onClick={toggleCheck}
                style={{ backgroundPosition: backgroundPosition, backgroundImage: `url(${props.faceDate.imageUrl})`}}>
            </div>
        </div>
    )
}
