export type Position = {
    latitude: number, 
    longitude: number
};

export type TimeCoordinate = {
    date: Date,
    timeOffset: number,
    coordinates: Position
};

    /**
     * Gets the TimeCoordinate's from the specified GeoJson.
     * @param geoJson: the GeoJson.
     * @param startTime: the startTime of the media. Used to calculate the timeOffset for each TimeCoordinate.
     */
export function getTimeCoordinates(geoJson: any, startTime: Date) : TimeCoordinate[] {

    if (!geoJson || !geoJson.features || geoJson.features.length === 0){
        return [];
    }    
    
    let timeCoordinates: TimeCoordinate[] = geoJson.features.map((feature: any)=>{        
        let date = new Date(feature.properties.time);
        return {
            date: date,
            timeOffset: getDifferenceInSeconds(startTime, date),
            coordinates: {
                longitude: feature.geometry.coordinates[0],
                latitude: feature.geometry.coordinates[1]                
            }
        }
    })

    return timeCoordinates;
};

function getDifferenceInSeconds(date1: Date, date2: Date) {
    const diffInMs = Math.abs(date2.getTime() - date1.getTime());
    return diffInMs / 1000;
}

export function getNearestTimeCoordinate(startIndex: number, timeOffset: number, coordinates: TimeCoordinate[]): number {

    let indexBestMatch: number = -1;

    if(coordinates.length === 0){
        return -1;
    }

    for (let index = startIndex; index < coordinates.length; index++) {
        const timeCoordinate = coordinates[index];
    
        if (timeCoordinate.timeOffset < timeOffset){
            indexBestMatch = index;
        }
        else {            
            break;
        }
    }
    
    if(indexBestMatch === -1){
        indexBestMatch = startIndex;
    }

    return indexBestMatch;
}