import React, { createContext } from 'react';
import * as apiCalls from '../logic/apiCalls';
import { facets } from '../components/search/facets/facetLogic';
import log from 'loglevel';
import { Field, FieldConfiguration } from '../types/Field';
import { AutoCompleteRequest, SearchRequest } from '../logic/apiCalls';
import moment from 'moment';

const facetSelections = 'facetSelections'

export const SearchContext = createContext<SearchContextProviderState>({
    isExecutingSearch: false,
    isInitialized: false,
    filterableUIFields: undefined,
    stringFacetsData: {},
    stringFacetsFields: [],
    facetValues: {},
    facetSelections: {},
    fieldConfiguration: undefined,
    defaultFacetSelections: {},
    sorterConfig: [],
    searchSettings: {
        top: 50,
        skip: 0,
        query: "",
        sortBy: "TimeStart",
        sortOrder: "desc",
        orderBy: "TimeStart asc",
        searchFields: []
    },
    searchResult: {},
    autoCompleteResult: {},
    locationFeatureEnabled: false,
    executeAutoCompleteQuery:  (autoCompleteQuery: string, searchFields: string[]) => {throw new Error('Should be implemented by TimelineContextProvider')},
    triggerInitialisation: () => {throw new Error('Should be implemented by TimelineContextProvider')},
    triggerExecuteSearch: () => {throw new Error('Should be implemented by TimelineContextProvider')},
    updateSearchQuery:(searchQuery: string, searchFields: string[]) => {throw new Error('Should be implemented by TimelineContextProvider')},
    updateFacetSelection:(facetName: string, facetValue:any, createUpdatedSelection:any) => {throw new Error('Should be implemented by TimelineContextProvider')},
    updateAutoCompleteClear:() => {throw new Error('Should be implemented by TimelineContextProvider')},
    updateSorting: (sortBy: any, sortOrder: any) => {throw new Error('Should be implemented by TimelineContextProvider')},
    pageUp: () => {throw new Error('Should be implemented by TimelineContextProvider')},
    pageDown:() => {throw new Error('Should be implemented by TimelineContextProvider')},
    loadPage:(pageNumber: number) => {throw new Error('Should be implemented by TimelineContextProvider')},
});


const loadActiveFiltersFromLS = () => {

    const cashedFilters = localStorage.getItem(facetSelections);

    if (!cashedFilters) {
        return null;
    }

    const activeFilters = JSON.parse(cashedFilters)
    const { fromDateTime, toDateTime, selectedIndex } = activeFilters.TimeStart

    return { ...activeFilters, TimeStart: {fromDateTime: moment(fromDateTime), toDateTime: moment(toDateTime), selectedIndex: selectedIndex }}
}

function createFacetSelectionDefault(fields: Field[]) {
    let facetSelection: any = {};

    for (let i = 0; i < fields.length; i++) {
        const field = fields[i];

        const facetLogic: any = facets.getFacetLogicByType(field.systemSettings.type);

        if (!facetLogic) {
            log.error(`[createFacetRequestFromFacetConfig] Unsupported data type found: ${field.systemSettings.type}`);
            continue;
        }

        if (facetLogic.getDefaultSelection) {
            facetSelection[field.name] = facetLogic.getDefaultSelection();
        }
    }
    return facetSelection;
}

function createFilterFromFacetSelections(facetSelections: any, fields: Field[]) {
    const facetNames = Object.keys(facetSelections);

    let filterClauses: any[] = [];
    for (let i = 0; i < facetNames.length; i++) {
        const currentFacetName = facetNames[i];
        const currentFacetSelection = facetSelections[currentFacetName];

        const field: Field | undefined = fields.find(f => f.name === currentFacetName);
        if(!field){
            throw new Error(`There is no field configuration for the field ${currentFacetName}.`);
        }

        const facetLogic = facets.getFacetLogicByType(field.systemSettings.type);

        if (!facetLogic) {
            log.error(`[createFilterFromFacetSelections] Unsupported data type found: ${field.systemSettings.type}`);
            continue;
        }

        filterClauses.push(facetLogic.createFilter(currentFacetSelection, field));
    }

    // Remove all empty entries from the filter clauses.
    filterClauses = filterClauses.filter(entry => entry !== "");
    if (filterClauses.length === 0) {
        return "";
    }

    // Create one filter clause that contains all the filters from the facets
    // by combining them with the 'and' operator.
    const filterClausesString = filterClauses.join(' and ');
    return filterClausesString;
}

function createFacetRequestFromFacetConfig(fields: Field[]) {
    let facetableFields = fields.filter(f => f.systemSettings.isFacetable);

    let facetRequest: any[] = [];
    for (let i = 0; i < facetableFields.length; i++) {
        const field = facetableFields[i];

        const facetLogic = facets.getFacetLogicByType(field.systemSettings.type);

        if (!facetLogic) {
            log.error(`[createFacetRequestFromFacetConfig] Unsupported data type found: ${field.systemSettings.type}`);
            continue;
        }

        const request = facetLogic.createRequest(field);
        if(request && request !== "" ){ //Some fields. like Locations, shown in the side-bar are not facet-able and can not be requested. Those field  return undefined of empty string.
            facetRequest.push(request);
        }
    }

    return facetRequest;
}


function extractFacetValues(facetValuesFromSearch: any, fields: Field[]) {
    const facetNames = Object.keys(facetValuesFromSearch);

    let extractedFacetValues:any = {};
    for (let i = 0; i < facetNames.length; i++) {
        const currentFacetName = facetNames[i];
        const currentFacetValues = facetValuesFromSearch[currentFacetName];
        const field: Field | undefined = fields.find(f => f.name === currentFacetName);

        if(!field){
            throw new Error(`There is no field configuration for the field ${currentFacetName}.`);
        }
        const facetLogic = facets.getFacetLogicByType(field.systemSettings.type)

        if (!facetLogic) {
            log.error(`[extractFacetValues] Unexpected facet type found: ${field.systemSettings.type}`);
            continue;
        }

        extractedFacetValues[currentFacetName] = facetLogic.extractFacetValues(currentFacetValues);
    }

    return extractedFacetValues;
}

interface SearchSettings{
    top: number;
    skip: number;
    query: string;
    sortBy: string;
    orderBy: string;
    sortOrder: string;
    searchFields: string[];
}
interface SearchContextProviderProps {
    children?: React.ReactNode
}
interface SearchContextProviderState {
    isInitialized: boolean;
    isExecutingSearch: boolean;
    filterableUIFields?: Field[];
    fieldConfiguration?: FieldConfiguration;
    stringFacetsData: any;
    stringFacetsFields: any;
    facetValues: any;
    facetSelections: any;
    defaultFacetSelections: any;
    sorterConfig: any;
    searchSettings: SearchSettings;
    searchResult: any;
    autoCompleteResult: any;
    locationFeatureEnabled: boolean,
    triggerInitialisation() : void;
    triggerExecuteSearch() : void;
    updateSearchQuery(searchQuery: string, searchFields: string[]): void;
    executeAutoCompleteQuery(autoCompleteQuery: string, searchFields: string[]): Promise<void>;
    updateFacetSelection(facetName: string, facetValue:any, createUpdatedSelection:any): void;
    updateAutoCompleteClear(): void;
    updateSorting(sortBy: any, sortOrder: any): void;
    pageUp(): void;
    pageDown(): void;
    loadPage(pageNumber: number): void;
}

export class SearchContextProvider extends React.Component<SearchContextProviderProps, SearchContextProviderState> {
    constructor(props: SearchContextProviderProps) {
        super(props);

        const urlParams = new URLSearchParams(window.location.search);
        const locationFeatureEnabled = !!urlParams.get('LocationFeature');

        const cacheState = localStorage.getItem('cacheTopBarFilter')
        const cached =  cacheState ? JSON.parse(cacheState) : '';

        this.state = {
            isExecutingSearch: false,
            isInitialized: false,
            filterableUIFields: undefined,
            stringFacetsData: {},
            stringFacetsFields: [],
            facetValues: {},
            facetSelections: {},
            fieldConfiguration: undefined,
            defaultFacetSelections: {},
            sorterConfig: [],
            searchSettings: {
                top: 50,
                skip: 0,
                query: cached.searchQuery || "",
                sortBy: "TimeStart",
                sortOrder: "desc",
                orderBy: "TimeStart asc",
                searchFields: cached.searchFields || []
            },
            searchResult: {},
            autoCompleteResult: {},
            locationFeatureEnabled: locationFeatureEnabled,
            triggerInitialisation: async () => {

                if (this.state.isInitialized) {
                    log.info("[SearchContextProvider] Search context was already initialized...");
                    return;
                }

                log.info("[SearchContextProvider] Starting initialization of the facets and sorters...");

                try {
                    const fieldConfiguration = await apiCalls.getFields();
                    const filterableUIFields:Field[] = fieldConfiguration.fields
                        .filter(f => f.systemSettings.isFilterable && f.userSettings.showInFacetArea)
                        .sort((f1, f2) => {return f1.userSettings.orderInFacetArea > f2.userSettings.orderInFacetArea ? 1 : -1});

                    const sorterResponse = await apiCalls.getSorters();

                    const cashedActiveFilters = loadActiveFiltersFromLS()

                    const facetSelectionDefault = createFacetSelectionDefault(filterableUIFields);

                    const initialSearchResult = await this.executeSearch(!!cashedActiveFilters ? cashedActiveFilters : facetSelectionDefault, filterableUIFields);
                    
                    const facetValues = extractFacetValues(initialSearchResult.facets, filterableUIFields);

                    const strFacetsFields = Object.keys(facetValues).filter(el => el !== 'TimeStart' && el !== 'Duration' && el !== 'ParticipantCount')
                    
                    const stringFacetsWithZeroCount = strFacetsFields.reduce((acc: any, field) => {
                        const currentObj = {...facetValues[field]}
                        for (let key in currentObj) {
                            currentObj[key] = {count: 0}
                        }
                        acc[field] = currentObj
                        return acc
                    }, {})

                    const stringFacetsFields = strFacetsFields.reduce((acc,el) => ({...acc, [el]: el}), {})

                    this.setState({
                        isInitialized: true,
                        filterableUIFields: filterableUIFields,
                        sorterConfig: sorterResponse.sorters,
                        searchResult: initialSearchResult,
                        stringFacetsData: stringFacetsWithZeroCount,
                        stringFacetsFields,
                        facetValues: facetValues,
                        facetSelections: cashedActiveFilters ?? facetSelectionDefault,
                        defaultFacetSelections: facetSelectionDefault,
                        fieldConfiguration: fieldConfiguration
                    });

                    log.info("[SearchContextProvider] Successfully initialized the facets and sorters.");
                }
                catch (error: any) {
                    log.error(`[SearchContextProvider] Error during initialization of the search: ${error.message}`);
                }
            },
            triggerExecuteSearch: async () => {
                try {
                    if(!this.state.filterableUIFields){
                        log.error(`triggerExecuteSearch executed before field configuration was available in the state.`);
                        return ;
                    }

                    const cashedActiveFilters = loadActiveFiltersFromLS()

                    const searchResult = await this.executeSearch(!!cashedActiveFilters ? cashedActiveFilters : this.state.facetSelections, this.state.filterableUIFields);
                    const facetValues = extractFacetValues(searchResult.facets, this.state.filterableUIFields);

                    this.setState(({facetSelections, stringFacetsData, stringFacetsFields}) => {

                        const facetSelectionField = Object.keys(facetSelections).filter(el => stringFacetsFields[el])

                        facetSelectionField.forEach(facet => {
                            const newData = {...stringFacetsData[facet], ...facetValues[facet]}
                            facetValues[facet] = {...newData}
                        })

                        return {
                        searchResult: searchResult,
                        facetValues: facetValues,
                    }});
                }
                catch (error: any) {
                    // ToDo: Implement proper error handling...
                    this.setState({
                        searchResult: null,
                        facetValues: null
                    });
                    log.error(`[SearchContextProvider] Error during execution of the search: ${error.message}`);
                }
            },
            updateSearchQuery: (searchQuery: any, searchFields: string[]) => {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        facetSelections: {
                            ...prevState.defaultFacetSelections,
                        },
                        searchSettings: {
                            ...prevState.searchSettings,
                            skip: 0,
                            query: searchQuery,
                            searchFields: searchFields
                        },
                    }
                }, this.state.triggerExecuteSearch);
            },
            executeAutoCompleteQuery: async (autoCompleteQuery: string, searchFields: string[]) => {
                if (!autoCompleteQuery || autoCompleteQuery.length === 0) {
                    this.setState({
                        autoCompleteResult: []
                    });

                    return
                }

                const autoCompleteSettings: AutoCompleteRequest=  {
                    top: 5,
                    query: autoCompleteQuery,
                    searchFields: searchFields
                }

                var autoCompleteResult = await apiCalls.autoComplete(autoCompleteSettings);

                this.setState({
                    autoCompleteResult: autoCompleteResult
                })
            },
            updateAutoCompleteClear: () => {
                this.setState({
                    autoCompleteResult: ""
                });
            },
            updateFacetSelection: (facetName: string, facetValue: any, createUpdatedSelection: any) => {
                if (this.state.isExecutingSearch) {
                    return;
                }

                this.setState(prevState => {
                    const currentSelectionOfFacet = prevState.facetSelections[facetName];
                    const updatedFacetSelection = createUpdatedSelection(facetValue, currentSelectionOfFacet)

                    const newFacetsSelections = !Array.isArray(updatedFacetSelection) ||  updatedFacetSelection.length
                    ? {...prevState.facetSelections, [facetName]: updatedFacetSelection} 
                    : Object.keys(prevState.facetSelections).filter(el => el !== facetName).reduce((acc: any, el) => ({...acc, [el] : prevState.facetSelections[el]}), {})

                    return {
                        ...prevState,
                        facetSelections: newFacetsSelections,
                        searchSettings: {
                            ...prevState.searchSettings,
                            skip: 0
                        }
                    }
                }, () => {
                    localStorage.setItem(facetSelections, JSON.stringify(this.state.facetSelections))
                    this.state.triggerExecuteSearch()
                });
            },
            updateSorting: (sortBy: any, sortOrder: any) => {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        searchSettings: {
                            ...prevState.searchSettings,
                            skip: 0,
                            sortBy: sortBy,
                            sortOrder: sortOrder,
                        }
                    }
                }, this.state.triggerExecuteSearch);
            },
            pageUp: () => {
                this.setState(prevState => {
                    const newSkipValue = prevState.searchSettings.skip + prevState.searchSettings.top;

                    return {
                        ...prevState,
                        searchSettings: {
                            ...prevState.searchSettings,
                            skip: newSkipValue
                        }
                    }
                }, this.state.triggerExecuteSearch);
            },
            pageDown: () => {
                this.setState(prevState => {
                    const newSkipValue = prevState.searchSettings.skip - prevState.searchSettings.top;

                    return {
                        ...prevState,
                        searchSettings: {
                            ...prevState.searchSettings,
                            skip: newSkipValue
                        }
                    }
                }, this.state.triggerExecuteSearch);
            },
            loadPage: (pageNumber: number) => {
                this.setState(prevState => {
                    const zeroBasedPageNumber = pageNumber - 1;
                    const newSkipValue = zeroBasedPageNumber * prevState.searchSettings.top;

                    return {
                        ...prevState,
                        searchSettings: {
                            ...prevState.searchSettings,
                            skip: newSkipValue
                        }
                    }
                }, this.state.triggerExecuteSearch);
            }
        }
    }

    executeSearch = async (facetSelections: any, fields: Field[]) => {
        this.setState({
            isExecutingSearch: true
        });

        try {
            let searchSettings = this.state.searchSettings;

            let facetNames = createFacetRequestFromFacetConfig(fields);
            let filterClause = createFilterFromFacetSelections(facetSelections, fields);

            let query: string = "*";
            if (searchSettings.query.startsWith("$filter:")){
                query = "";
                let queryFilter = searchSettings.query.substr(8);
                log.warn("Using filter query in search bar. Is an experimental feature. Filter:", queryFilter)
                if(filterClause === ""){
                    filterClause = queryFilter;
                }
                else{
                    filterClause = "(" + filterClause + ") and (" + queryFilter + ")";
                }
            }
            else if (searchSettings.query!=="") {
                query = searchSettings.query;
            }

            // Select only fields that are needed by the frontend
            const fieldsToSelect = [
                "MediaId",
                "Owner/DisplayName",
                "Owner/ParticipantId",
                "Owner/PhoneNumber",
                "Participants/DisplayName",
                "Participants/ParticipantId",
                "Participants/PhoneNumber",
                "Direction",
                "TimeStart",
                "Duration",
                "SourceType",
                "MediaTypes",
                "ContentReferences"
            ];

            let searchRequest:SearchRequest = {
                facets: facetNames,
                filter: filterClause,
                top: searchSettings.top,
                skip: searchSettings.skip,
                orderBy: [`${searchSettings.sortBy} ${searchSettings.sortOrder}`],
                searchFields: searchSettings.searchFields,
                includeTotalResultCount: true,
                query: query,
                searchMode: "All",
                select: fieldsToSelect,
                sortBy: searchSettings.sortBy,
                sortOrder: searchSettings.orderBy
            };

            return await apiCalls.search(searchRequest);
        }
        finally {
            this.setState({
                isExecutingSearch: false
            });
        }
    }

    render() {
        return (
            <SearchContext.Provider value={this.state}>
                {this.props.children}
            </SearchContext.Provider>
        )
    }
}
