import React, { Component, createRef } from 'react';
import './TranscriptionTab.scss'
import { connectTeamsTheme } from "./../../context/connectTeamsTheme";
import { TranscriptionList } from './TranscriptionList';
import { Participant, TextItem } from '../../types/transcription';
import { Checkbox, Button, Input, ArrowDownIcon, ArrowUpIcon, SearchIcon} from '@fluentui/react-northstar';


// The number of seconds that the video starts playing before the selected highlight.
const PREPLAY_INTERVAL_SECONDS = 2;

type OnExecuteSearchCallback = (query: string) => void;

type OnSetPositionRequestCallback = (position: number) => void;

type TranscriptionTabProps = {
    showItemAtOffsetSeconds: number,
    textItems: TextItem[],
    participants: Participant[],    
    initialSearchQuery: string,
    isPanelOpen: boolean, // ToDo: Is this still required?
    onExecuteSearch: OnExecuteSearchCallback,
    onSetPositionRequest: OnSetPositionRequestCallback
}

type TranscriptionTabState = {
    backToSearch: boolean,    
    searchQuery: string,
    currentHighlightIndex: number,
    followPlayer: boolean    
}

class TranscriptionTabInternal extends Component<TranscriptionTabProps, TranscriptionTabState> {

    transcriptionListRef: React.RefObject<TranscriptionList> = createRef();
    inputRef: React.RefObject<HTMLInputElement> = createRef();

    state = {
        backToSearch: false,        
        searchQuery: this.props.initialSearchQuery,
        currentHighlightIndex: -1,
        followPlayer: true        
    };

    componentDidMount() {
        this.executeShowItemAtOffsetSeconds(this.props.showItemAtOffsetSeconds);
    }

    componentDidUpdate(prevProps: TranscriptionTabProps) {
        if (prevProps.textItems !== this.props.textItems && prevProps.textItems.length > 0) {
            // Reset the current selected highlight when the textitems have changed because a new search query was entered.
            this.setState({
                currentHighlightIndex: -1
            })
        }

        if (prevProps.showItemAtOffsetSeconds !== this.props.showItemAtOffsetSeconds) {                        
            this.executeShowItemAtOffsetSeconds(this.props.showItemAtOffsetSeconds);
        }
    }

    executeShowItemAtOffsetSeconds = (offset: number) => {
        const transcriptionList = this.transcriptionListRef.current;
        if (transcriptionList) {
            transcriptionList.selectItemAtOffset(offset);
        }
    }

    getHighlightOffsets = () => {
        let offsets: number[] = [];
        for (const textItem of this.props.textItems) {
            for (const highlight of textItem.highlights) {
                offsets.push(textItem.offsetSeconds + highlight.offsetSeconds);
            }
        }

        return offsets;
    }

    trySetNewHighlightIndex = (newIndex: number) => {
        const highlightOffsets = this.getHighlightOffsets();
        if (newIndex < 0 || newIndex > highlightOffsets.length - 1) {
            return;
        }
        
        const correspondingOffset = highlightOffsets[newIndex];

        let newPosition = correspondingOffset - PREPLAY_INTERVAL_SECONDS;
        if (newPosition < 0) {
            newPosition = 0;
        }

        this.setState({
            currentHighlightIndex: newIndex            
        });

        // Ensure the transcription shows the correct item immediately without wating for the update from the media player.        
        this.executeShowItemAtOffsetSeconds(newPosition);
        
        // Report the change of position so the media player can update its position.
        this.props.onSetPositionRequest(newPosition);         
    }

    handleSearchQueryOnChange = (value: string) => {
        this.setState({
            searchQuery: value
        });        
    }

    handleSearchIconClick = () => {
        this.props.onExecuteSearch(this.state.searchQuery);
    }

    handlePreviousButtonClick = () => {
        this.trySetNewHighlightIndex(this.state.currentHighlightIndex - 1);
    }

    handleNextButtonClick = () => {
        this.trySetNewHighlightIndex(this.state.currentHighlightIndex + 1);
    }

    handleFollowPlayerChecked = (isChecked: boolean) => {
        this.setState({
           followPlayer: isChecked
        });
    }

    onEnterPressSearch = (e: React.KeyboardEvent) => {
        if (e.keyCode === 13 && this.state.searchQuery) {
            this.props.onExecuteSearch(this.state.searchQuery)
            this.inputRef.current?.blur()
        }
    }

    render() {
        const { isPanelOpen } = this.props;
        const { followPlayer } = this.state;

        const highlightCount = this.getHighlightOffsets().length; // ToDo: Add memoization for performance improvement
        let currentHighlightDisplay = this.state.currentHighlightIndex + 1;        
        if (currentHighlightDisplay > highlightCount) {
            currentHighlightDisplay = highlightCount;
        }

        const transcriptionData = {
            textItems: this.props.textItems,
            participants: this.props.participants
        }

        return (
            <div className={`video-side-bar transcription ${isPanelOpen && ('active')}`}>
                <div className='header-filters'>
                    <div className="support-search-input width-100">
                        <div className="search-title">Query</div>
                        <Input
                            fluid
                            ref={this.inputRef}
                            onKeyDown={this.onEnterPressSearch}
                            value={this.state.searchQuery}
                            onChange={(_, option: any) => this.handleSearchQueryOnChange(option?.value)}
                        />
                        <Button
                            iconOnly
                            onClick={this.handleSearchIconClick}
                            icon={<SearchIcon/>}
                            title="Search"
                        />
                    </div>
                    <div className="support-arrows">
                        <div className="content-checkbox">
                            <Checkbox
                                label="Follow player"
                                defaultChecked={followPlayer}
                                onClick={(_, options: any) => this.handleFollowPlayerChecked(options.checked)} />
                        </div>
                        <div className="content-arrows">
                            <div className="text">{currentHighlightDisplay} / {highlightCount}</div>
                            <Button
                                iconOnly
                                onClick={this.handlePreviousButtonClick}
                                icon={<ArrowUpIcon/>}
                                title="Previous"
                            />
                            <Button
                                iconOnly
                                onClick={this.handleNextButtonClick}
                                icon={<ArrowDownIcon/>}
                                title="Next"
                            />
                        </div>
                    </div>
                </div>
                <div className="transcriptionContent">
                    <TranscriptionList
                        ref={this.transcriptionListRef}
                        enableAutoScroll={this.state.followPlayer}
                        data={transcriptionData}     
                        onSetPositionRequest={this.props.onSetPositionRequest}
                    />
                </div>
            </div>
        );
    }
}

export const TranscriptionTab = connectTeamsTheme(TranscriptionTabInternal as any);