import React, { Fragment, Component } from 'react';
import './TopBarSearch.scss';

import { Button } from "@fluentui/react-northstar";
import { SearchContext } from '../../context/SearchContext';
import SearchBox from './SearchBox';
import { getStyle } from '../ThemeLogic';
import { UserLogin } from '../UserLogin';
import SearchInDropDown, { SearchInDropDownChangedEventArgs } from './SearchInDropDown';
import { connectTeamsTheme } from "./../../context/connectTeamsTheme";
import * as misc from "../../utils/misc";

interface AutoCompleteResult {
    values: string[];
}

interface SearchSettings {
    query: string;
}

interface TopBarSearchProps {
    autoCompleteResult: AutoCompleteResult;
    searchSettings: SearchSettings;
    /** context is MS Teams Theme context. You don't need to set this property, because is't automatically set by HOC connectTeamsComponent. */
    context?: any
}

interface TopBarSearchState {
    searchQuery: string
    searchFields: string[];
}

const cacheTopBarFilter = 'cacheTopBarFilter'

class TopBarSearchTemplate extends Component<TopBarSearchProps, TopBarSearchState> {
    context!: React.ContextType<typeof SearchContext>;    
    timeoutId!: number;
    constructor(props: TopBarSearchProps) {
        super(props);
        this.state = {
            searchQuery: this.props.searchSettings.query,
            searchFields: [],
        }
    }

    componentDidUpdate(prevProps: TopBarSearchProps, prevState: TopBarSearchState) {
        if (prevState !== this.state) {
            localStorage.setItem(cacheTopBarFilter, JSON.stringify(this.state))
        }
    }

    getSearchContext = (): React.ContextType<typeof SearchContext> => {
        return this.context as React.ContextType<typeof SearchContext>;
    }

    startAutoComplete = (suggestQuery: string, searchFields: string[]) => {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }

        this.timeoutId = window.setTimeout(() => this.executeAutoComplete(suggestQuery, searchFields), 200);
    }

    executeAutoComplete = (suggestQuery: string, searchFields: string[]) => {
        const searchContext = this.getSearchContext();
        searchContext.executeAutoCompleteQuery(suggestQuery, searchFields);
    }

    onSuggest = (suggestQuery: string) => {
        if (this.canShowSuggestions(this.state.searchFields)) {
            this.startAutoComplete(suggestQuery, this.state.searchFields);
        }
    }

    canShowSuggestions = (searchFields: string[]): boolean => {
        if (searchFields.length === 0) {
            return true;
        }
        let field = this.getSearchContext().fieldConfiguration?.fields.find(fc => searchFields.find(fieldName => fc.name === fieldName && fc.systemSettings.supportSuggestions));

        return field ? true : false;
    }

    onSuggestClear = () => {
        const searchContext = this.context;
        searchContext.updateAutoCompleteClear();
    }

    executeSearch = (query: string, fields: string[]) => {
        const searchContext = this.context;
        localStorage.removeItem("facetSelections")
        searchContext.updateSearchQuery(query, fields);
    }

    onSearch = () => {
        this.executeSearch(this.state.searchQuery, this.state.searchFields);
    }

    onSearchByQuery = (query: string) => {
        this.executeSearch(query, this.state.searchFields);
    }

    onSearchByFields = (fields: string[]) => {
        this.executeSearch(this.state.searchQuery, fields);
    }

    onQueryChanged = (query: string) => {
        this.setState({ searchQuery: query });
    }

    onSearchInDropDownChanged = (e: SearchInDropDownChangedEventArgs) => {
        let fields: string[] = []

        if (e.selectedItem !== undefined) {
            fields = [e.selectedItem];
        }

        this.setState({ searchFields: fields });

        this.onSearchByFields(fields);
    }

    onSuggestionsFetchRequested = (query: any) => {
        //console.log(`[onSuggestionsFetchRequested] Reason ${query.reason}, value: ${query.value}`);
    }

    render() {
        let suggestions: string[] = [];

        if (this.props.autoCompleteResult.values && this.props.autoCompleteResult.values.length > 0) {
            suggestions = this.props.autoCompleteResult.values.map((autoComplete, index) => {
                return autoComplete;
            });
        }

        const teamsContext = { ...this.props.context.theme.siteVariables, style: this.props.context.style }
        const searchButtonStyle = {
            ...getStyle(teamsContext).button,
            height: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: 'none'
        }

        const userLoginRendered = !misc.isRunningInIframe() ? 
            <UserLogin /> : 
            <></>;

        return (
            <Fragment>
                <div className="wrapper-input width-100">
                    <div className="search-field">
                        <SearchBox searchQuery={this.state.searchQuery} suggestionsFetchRequested={this.onSuggestionsFetchRequested} suggestions={suggestions} suggest={this.onSuggest} suggestClear={this.onSuggestClear} search={this.onSearchByQuery} queryChanged={this.onQueryChanged} />
                    </div>
                    <div className="searchin-dropdown">
                        <SearchInDropDown
                            fields={this.context.fieldConfiguration === undefined ? [] : this.context.fieldConfiguration.fields}
                            onChange={this.onSearchInDropDownChanged}
                        />
                    </div>
                    <Button
                        primary
                        className="search-button"
                        fluid
                        style={searchButtonStyle}
                        onClick={() => this.onSearch()}>
                        Search
                    </Button>
                    {userLoginRendered}
                </div>
            </Fragment>
        );
    }
}

TopBarSearchTemplate.contextType = SearchContext;
const TopBarSearch = connectTeamsTheme(TopBarSearchTemplate as any);
export default TopBarSearch;