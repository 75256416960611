// @ts-nocheck

import {TraceMapControl} from '../MapControl';
import { Position } from '../TraceData';
import * as atlasMapControl from 'azure-maps-control';
import * as atlasSpatial from 'azure-maps-spatial-io';
import {CentreControl} from './controls/centre/FollowControl'
import { AzureMapControl } from './AzureMap';

declare const atlas: any;

export interface AzureTraceMapProps {
    elementId: string;
    onMapRead?: () => void;
}

class AzureTraceMap extends AzureMapControl implements TraceMapControl {
    dataSource: any;
    pinDataSource: any;
    animation: any;
    props: AzureTraceMapProps;
    navigationPin: any;
    follow: boolean = false;
    currentPositionNavigationPin: any;

    public constructor(props: AzureTraceMapProps) {
        super({
            elementId: props.elementId
        });
        this.props = props;
    }               

    onMapReady(evt: any): void {
         //Create a data source and add it to the map.
         this.dataSource = new atlasMapControl.source.DataSource();

         //Create a second data source for the animated pin.
         this.pinDataSource = new atlasMapControl.source.DataSource();

        let azMap = this.azMap;
        let dataSource = this.dataSource;
        let pinDataSource = this.pinDataSource;
        
        this.addControls();

        //Load a custom image icon into the map resources.
        azMap.imageSprite.createFromTemplate('arrow-icon', 'marker-arrow', 'teal', '#fff').then(function () {
            azMap.sources.add([dataSource, pinDataSource]);
            
            //Add a simple data layer for rendering the data.
            let layer = new atlasSpatial.layer.SimpleDataLayer(dataSource);
            azMap.layers.add(layer);

            //Create a layer to render a symbol which we will animate.
            azMap.layers.add(new atlasMapControl.layer.SymbolLayer(pinDataSource, undefined, {
                iconOptions: {
                    //Pass in the id of the custom icon that was loaded into the map resources.
                    image: 'arrow-icon',

                    //Anchor the icon to the center of the image.
                    anchor: 'center',

                    //Rotate the icon based on the rotation property on the point data.
                    //The arrow icon being used in this case points down, so we have to rotate it 180 degrees.
                    rotation: ['+', 180, ['get', 'heading']],

                    //Have the rotation align with the map.
                    rotationAlignment: 'map',

                    //For smoother animation, ignore the placement of the icon. This skips the label collision calculations and allows the icon to overlap map labels. 
                    ignorePlacement: true,

                    //For smoother animation, allow symbol to overlap all other symbols on the map.
                    allowOverlap: true
                },
                textOptions: {
                    //For smoother animation, ignore the placement of the text. This skips the label collision calculations and allows the text to overlap map labels.
                    ignorePlacement: true,

                    //For smoother animation, allow text to overlap all other symbols on the map.
                    allowOverlap: true
                }
            }));        
        });  
        
        if (this.props.onMapRead) {
            this.props.onMapRead();
        }
    }

    addControls(): void{
        let controls = [
            new CentreControl({
                onclick: this.toggleFollow
            }),
            new atlasMapControl.control.ZoomControl(),
            new atlasMapControl.control.CompassControl(),
            new atlasMapControl.control.PitchControl(),
            new atlasMapControl.control.StyleControl()            
        ];
        let position: atlasMapControl.ControlPosition = atlasMapControl.ControlPosition.BottomRight;

        controls.forEach((control) => {
            /* Add the zoom control to the map*/
            this.azMap.controls.add(control, {position: position});
        });

    }

    async loadTraceData(traceData: any): Promise<void> {

        //let uri = "https://raw.githubusercontent.com/Azure-Samples/AzureMapsCodeSamples/master/AzureMapsCodeSamples/Common/data/geojson/GpsTrace.json"        
        await atlasSpatial.io.read(JSON.stringify(traceData), {
          //If the dataset is a GPX file, track and routes will capture waypoints for each point in the path.
          capturePathWaypoints: true
        }).then((r: { bbox: any; features: any }) => {
    
            if (r) {
                //Add the feature data to the data source.
                this.dataSource.add(r);

                //If bounding box information is known for data, set the map view to it.
                if (r.bbox) {
                    this.azMap.setCamera({ bounds: r.bbox, padding: 50 });
                }
                
                if(r.features && r.features.length !== 0){
                    //Create a pin and wrap with the shape class and add to data source.    
                    const feature = r.features[0];
                    this.navigationPin = new atlasMapControl.Shape(feature);
                    this.pinDataSource.add(this.navigationPin);
                    
                    this.setCoordinatesNavigationPointer({longitude: feature.geometry.coordinates[0], latitude: feature.geometry.coordinates[1]})
                }                
            }
        });    
    }  

    toggleFollow = (value?: boolean): void => {
        let changed: boolean = false;
        if(value === undefined){
            this.follow = !this.follow;
            changed = true;
        }
        else if (this.follow !== value){
            this.follow = value;
            changed = true;
        }
        
        if(changed && this.currentPositionNavigationPin){
            this.setCoordinatesNavigationPointer({
                longitude: this.currentPositionNavigationPin[0],
                latitude: this.currentPositionNavigationPin[1],
            })
        }
    }

    setCoordinatesNavigationPointer = (location: Position, nextLocation?: Position): void => {
        this.currentPositionNavigationPin = [location.longitude, location.latitude];
        let heading;

        // Set the heading of the navigation arrow
        if(nextLocation !== undefined){
            let nextPosition = [nextLocation.longitude, nextLocation.latitude];
            heading = atlasMapControl.math.getHeading(this.currentPositionNavigationPin, nextPosition);
            this.navigationPin.setProperties({heading: heading});            
        }

        atlas.animations.setCoordinates(this.navigationPin, this.currentPositionNavigationPin, { 
            duration: 0, 
            autoPlay: true ,
            captureMetadata: true, 

            //If following enabled, add a map to the animation.
            map: (this.follow)? this.azMap : null,

            //Camera options to use when following.
            zoom: 17,
            pitch: 45
        });           
    }    
}

const createAzMap = (props: AzureTraceMapProps) : TraceMapControl => {
    return new AzureTraceMap(props);
}

export {
    createAzMap
}