import React, { Fragment, Component, useState } from 'react';
import './SideBarSearch.scss'
import { SearchContext } from '../../context/SearchContext';
import { facets } from './facets/facetLogic';
import log from 'loglevel';
import { Button, MenuIcon, CloseIcon, ArrowLeftIcon, Input, SearchIcon } from '@fluentui/react-northstar'
import { Anchor } from "./../common/Anchor";
import FacetFieldCheckboxes from "./facets/FacetFieldCheckboxes"


class SideBarSearch extends Component {
  state = {
    isPanelOpen: false,
    facetExpanded: ''
  };
  static contextType = SearchContext;

  setFacetExpanded = (facetName) => this.setState({facetExpanded: facetName})

  clearSelectedFacet = () => this.setState({facetExpanded: ''})

  renderSelectedFacet = (allFacetsField) => {
    if(!allFacetsField || !this.state.facetExpanded) return 

    const faceData = allFacetsField.filter(el => el.name === this.state.facetExpanded)[0].name

    return faceData 
  }

  
  facetChanged = (facetName, valueChanged) => {
    log.debug(`[facetChanged] ${facetName}: ${valueChanged}`);

    // Find the corresponding facet configuration by the name of the facet.
    const field = this.context.filterableUIFields.find(f => f.name === facetName);

    const facetLogic = facets.getFacetLogicByType(field.systemSettings.type);
    if (!facetLogic) {
      log.error(`[facetChanged] Unexpected data type found: ${field.systemSettings.type}`);
      return;
    }

    this.context.updateFacetSelection(facetName, valueChanged, facetLogic.createUpdatedSelection);
  }
  handleOpenFilter = () => {
    this.setState(prevState => ({ isPanelOpen: !prevState.isPanelOpen }));
  }
  renderFacets = (fields, facetValues, facetSelections) => {
    if (!fields || fields.length === 0 || !facetValues || !facetSelections) {
      return null;
    }

    let renderedOutput = [];

    // The facets are rendered in the order they are returned in the facet configuration retrieved once from the back-end.
    fields.forEach((field) => {
      let facetValue = facetValues[field.name];
      let facetSelection = facetSelections[field.name];

      if (!field.userSettings.showInFacetArea) {
        return;
      }

      const facetLogic = facets.getFacetLogicByType(field.systemSettings.type);

      if (!facetLogic) {
        log.error(`[renderFacets] Unexpected data type found: ${field.systemSettings.type}`);
        return;
      }

      renderedOutput.push(facetLogic.renderFacet(field, facetValue, facetSelection, this.facetChanged, this.setFacetExpanded));
    });

    return renderedOutput;
  }

  render() {
    // The facets are rendered based upon the configuration retrieved once from the API and the current search results.
    const renderedFacets = this.renderFacets(this.context.filterableUIFields, this.context.facetValues, this.context.facetSelections);
    const selectedFacets = this.renderSelectedFacet(this.context.filterableUIFields)
    const facetData = this.context.facetValues[selectedFacets]
    const facetSelection = this.context.facetSelections[this.state.facetExpanded]


    const {
      isPanelOpen, facetExpanded
    } = this.state;
    return (
        <Fragment>
          <div className="side-header">
            {!facetExpanded && <header className="wrapper-header display--flex">
              <p className="title">Filters</p>
              <Anchor onClick={() => {
                localStorage.removeItem("facetSelections")
                this.context.updateSearchQuery(this.context.searchSettings.query);
              }}>Clear filters</Anchor>
              <div className="support-dropdown-menu">
              <Button
                iconOnly
                onClick={this.handleOpenFilter}
                icon={isPanelOpen ? <CloseIcon /> : <MenuIcon />}
              />
              </div>
            </header>}
          </div>
          <div className='support-bar'>
            <section className={`support-search ${isPanelOpen && ('active')}`}>
              {!this.state.facetExpanded ? renderedFacets 
              : <StringFacetFieldFilters 
              clearSelectedFacet={this.clearSelectedFacet}
              name={selectedFacets}
              facetData={facetData}
              onChangeFacet={this.facetChanged}
              facetSelection={facetSelection || []}
              />}
            </section>
          </div>
        </Fragment>
    );
  }
}

export default SideBarSearch;

const StringFacetFieldFilters = ({clearSelectedFacet, name, facetData, onChangeFacet, facetSelection}) => {
 
    const [term, setTerm] = useState('')

    const filterFacetData = Object.keys(facetData).filter(el => el.toLowerCase().includes(term.toLowerCase()))
    const filtredData = filterFacetData.reduce((acc, el) => ({...acc, [el]: facetData[el]}), {})

    return (
        <div>
            <ArrowLeftIcon onClick={clearSelectedFacet} style={{ marginBottom: 20 }} />
            <h3 style={{marginTop: 0}}>{name}</h3>
            <Input icon={<SearchIcon />} value={term} clearable placeholder="Search..." onChange={(e) => setTerm(e.target.value === undefined ? '' : e.target.value)}/>
            <ul style={{margin: '10px 0px'}}>
            {!filterFacetData.length ? 'Sorry, there are no filters available for your search' : <FacetFieldCheckboxes facetData={filtredData} onChangeFacet={onChangeFacet} name={name} facetSelection={facetSelection}/>}
            </ul>
        </div>
    )
}