import DurationFacet from './DurationFacetPresentation';
import * as helper from '../../../utils/misc';
import { FacetLogic } from './Types';
import { Field } from '../../../types/Field';

const minValue = 0;
const maxValue = 24 * 60 * 60;

const durationRangeDefinitions = [
    { displayName: "Short", lowerValue: minValue, upperValue: 5 * 60},
    { displayName: "Standard", lowerValue: 5 * 60, upperValue: 60 * 60},
    { displayName: "Long", lowerValue: 60 * 60, upperValue: maxValue},
]

function getLowerValue(facetSelection: any) {
    return facetSelection ? facetSelection.lowerValue : minValue;
}

function getUpperValue(facetSelection: any) {
    return facetSelection ? facetSelection.upperValue : maxValue;
}

function checkForCheckedRange(lowerValue: any, upperValue: any, facetValues: any) {
    facetValues.forEach((facetValue: any) => {
        let rangeDef = durationRangeDefinitions[facetValue.periodIndex];

        if (lowerValue === rangeDef.lowerValue && upperValue === rangeDef.upperValue){
            facetValue.checked = true;
        } else {
            facetValue.checked = false;
        }
    }); 
}

function createFilter(facetSelection: any, field: Field) {
    const lowerValue = facetSelection.lowerValue;
    const upperValue = facetSelection.upperValue;

    if (helper.isEmpty(lowerValue) || helper.isEmpty(upperValue)) {
        return "";
    }
    
    const fromFilter = field.systemSettings.filterClause.replace('{{VALUE}}', lowerValue).replace('{{OPERATOR}}', 'gt')
    const toFilter = field.systemSettings.filterClause.replace('{{VALUE}}', upperValue).replace('{{OPERATOR}}', 'lt')

    return `(${fromFilter} and ${toFilter})`;
}

function createRequest(field: Field) {
    let values = "";

    for (let i = 0; i < durationRangeDefinitions.length; i++) {
        if (values !== "") {
            values = values + "|";
        }
        values = values + durationRangeDefinitions[i].upperValue;
    }

    if (values.length > 0) {
        return field.name + ",values:" + values
    }

    return field.name;
}

function extractFacetValues(facetValuesFromSearch: any) {
    let rangeValues: any[] = [];
    // Skip the last facet result (more than 24h is not presented)
    for (let i = 0, facetIndex = 0; i < facetValuesFromSearch.length - 1; i++, facetIndex++) {
        rangeValues.push({
            displayName: durationRangeDefinitions[facetIndex].displayName,
            periodIndex:  facetIndex,
            lowerValue: durationRangeDefinitions[facetIndex].lowerValue,
            upperValue: durationRangeDefinitions[facetIndex].upperValue,
            count: facetValuesFromSearch[i].count,
            checked: false, //Default all periods are not checked (is updated in renderFacet)
        })
    }

    return rangeValues;
}

function renderFacet (field: Field, facetValues: any, facetSelection: any, onChange: any) {
    let lowerValue = getLowerValue(facetSelection);
    let upperValue = getUpperValue(facetSelection);
    
    checkForCheckedRange(lowerValue, upperValue, facetValues);

    return <DurationFacet 
        key={field.name} 
        name={field.name} 
        displayName={field.userSettings.displayName} 
        rangeData={facetValues} 
        lowerValue={lowerValue}
        upperValue={upperValue}
        minValue={minValue} 
        maxValue={maxValue} 
        onChangeFacet={onChange}/>
}

// The facetValue contains an object with the new lower and upper value. 
// The returned selection of this facet type is not based upon the previous selections, 
// the received value is simply returned.
function createUpdatedSelection(facetValue: any, prevFacetSelection: any) {
    return facetValue;
}

export const durationFacetLogic: FacetLogic = {
    dataType: 'Edm.Duration',
    createFilter: createFilter,
    createRequest: createRequest,
    extractFacetValues: extractFacetValues,
    renderFacet: renderFacet,
    createUpdatedSelection: createUpdatedSelection,
}