import React, { Component } from 'react';
import { TextItem, Participant, Transcription, TextDiarizationSegments, AudioSearchHits } from '../../types/transcription';
import * as transcription from '../../logic/transcription';
import { TranscriptionData, TranscriptionList } from '../play/TranscriptionList'
import { Button } from "@fluentui/react-northstar";

import css from './TestPage.module.scss';

type State = {
    participants: Participant[],
    textItems: TextItem[],    
    offsetSeconds: number,
    selectedItemIndex: number,
    isAutoScrollEnabled: boolean,
    tabIndex : number
}

export class TestPage extends Component<{}, State> {
    state = {
        participants: [],
        textItems: [],        
        offsetSeconds: 0,
        selectedItemIndex: 0,
        isAutoScrollEnabled: true,
        tabIndex: 0
    }

    listRef: React.RefObject<TranscriptionList> = React.createRef();

    async componentDidMount() {
        console.log("[TestPage] componentDidMount start.");

        const mediaTranscription: Transcription = await this.fetchFromServer('./temp/transcription.json');
        const textDiarizarion: TextDiarizationSegments = await this.fetchFromServer('./temp/textdiarization.json');
        const audioSearchHits: AudioSearchHits = await this.fetchFromServer('./temp/audiosearchhits.json');
        const mediaInfo: any = await this.fetchFromServer('./temp/mediainfo.json');

        let participants = transcription.createParticipants(mediaInfo);
        let textItems = await transcription.createTextItems(mediaTranscription, textDiarizarion, audioSearchHits);          

        this.setState({
            participants: participants,
            textItems: textItems
        });

        console.log("[TestPage] componentDidMount end.");
    }

    fetchFromServer = async (url: string) : Promise<any> => {
        const response = await fetch(url);
        return await response.json();
    }

    handleSelectItemWithIndexClick = () => {        
        const newIndex = this.state.selectedItemIndex + 1;
        this.setState({ selectedItemIndex: newIndex });
        this.listRef.current?.selectItemAtIndex(newIndex);
    }

    handleToggleAutoScrollClick = () => {
        this.setState((prevState: State) => { return { isAutoScrollEnabled: !prevState.isAutoScrollEnabled }});
    }

    handleSelectItemAtOffsetClick = () => {
        const newOffset = this.state.offsetSeconds + 10;
        this.setState({
            offsetSeconds: newOffset
        });
        this.listRef.current?.selectItemAtOffset(newOffset);
    }

    handleOnSetPositionRequest = (position: number) => {
        console.log(`[handleOnSetPositionRequest] New position: ${position}`);
    }

    handleToggleTab = () => {
        let newTabIndex = 0;
        if (this.state.tabIndex === 0) {
            newTabIndex = 1;
        }

        this.setState(
            {
                tabIndex: newTabIndex
            }
        );
    }

    render() {
        console.log(`[TestPage] Rendering. TextItem length: ${this.state.textItems.length}`);   
        
        const data: TranscriptionData = {
            textItems: this.state.textItems,
            participants: this.state.participants            
        }

        const renderedTab = this.state.tabIndex === 0 ? 
            <div>RecordingInfo</div> : 
            <div className={css.transcriptionContainer}>
                    <TranscriptionList ref={this.listRef} data={data} enableAutoScroll={this.state.isAutoScrollEnabled} onSetPositionRequest={this.handleOnSetPositionRequest} />                 
                </div>

        return (
            <div className={css.testpage}>
                {renderedTab}
                <Button primary className={css.button} onClick={this.handleSelectItemWithIndexClick}>Select item with index ({this.state.selectedItemIndex})</Button>  
                <Button primary className={css.button} onClick={this.handleSelectItemAtOffsetClick}>Select item at offset ({this.state.offsetSeconds})</Button>  
                <Button primary className={css.button} onClick={this.handleToggleAutoScrollClick}>{this.state.isAutoScrollEnabled ? "Disable autoscroll" : "Enable autoscroll"}</Button>  
                <Button primary className={css.button} onClick={this.handleToggleTab}>{this.state.tabIndex}</Button>  
            </div>
            )
    }
}
