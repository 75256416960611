import React, { Component } from 'react';
import ISO6391 from 'iso-639-1';
import { getReadableTimestamp } from '../../logic/timeDisplay';
import { Loader } from '@fluentui/react-northstar'
import { ActivityMeasurement } from '../ActivityMeasurement';
import { urls } from '../../logic/urls';
import { PlayerControls } from './PlayerControls';
import { connectTeamsTheme } from "./../../context/connectTeamsTheme";

import log from 'loglevel';
import css from './VideoPlayer.module.scss';
import { TimelineEvent } from '../../context/TimelineContext';

const offSurfaceVideoControlsFadeOutHoldOffMs = 300;
const onSurfaceVideoControlsFadeOutHoldOffMs = 2500;

declare global {
    interface Document {
        mozCancelFullScreen?: () => Promise<void>;
        msExitFullscreen?: () => Promise<void>;
        webkitExitFullscreen?: () => Promise<void>;
        mozFullScreenElement?: Element;
        msFullscreenElement?: Element;
        webkitFullscreenElement?: Element;
    }

    interface HTMLElement {
        msRequestFullscreen?: () => Promise<void>;
        mozRequestFullScreen?: () => Promise<void>;
        webkitRequestFullscreen?: () => Promise<void>;
        webkitEnterFullscreen?: () => Promise<void>;
    }
}

type positionRequest = {
    position: number,
}

type OnTimeUpdateCallback = (currentTime: number) => void;

interface VideoPlayerProps {    
    contentUrl: string;
    subtitleUrl: string;
    code: string;
    timelineEvents: TimelineEvent[];
    positionRequest: positionRequest;
    onTimeUpdate: OnTimeUpdateCallback;
    context?: any;
    onDownload: () => void;
}

interface VideoPlayerInternalProps extends VideoPlayerProps { }

interface VideoPlayerState {
    isUserActive: boolean;
    isVideoPlaying: boolean;
    isVideoLoading: boolean;            
    isMuted: boolean;
    volume: number;
    isSubtitleActive: boolean;
    position: number;
    duration: number;
}


class VideoPlayerInternal extends Component<VideoPlayerInternalProps, VideoPlayerState> {
    videoContainerRef: React.RefObject<HTMLDivElement> = React.createRef();
    videoElementRef: React.RefObject<HTMLVideoElement> = React.createRef();

    state = {
        isUserActive: true,
        isVideoPlaying: false,
        isVideoLoading: true,            
        isMuted: false,
        volume: 100,
        isSubtitleActive: false,
        position: 0,
        duration: 0            
    }
    
    componentDidMount() {
        log.debug(`[VideoPlayer componentDidMount] ${getReadableTimestamp()}`);

        this.registerVideoElementEvents();        
        this.syncVolumeFromVideoPlayerToState();
        // this.enableSubtitles();
    }

    componentDidUpdate(prevProps: VideoPlayerProps) {
        // Note: The position is wrapped in an object to be able to detect a position request with the same value as the previous one.
        if (prevProps.positionRequest !== this.props.positionRequest) {
            const requestedPosition = this.props.positionRequest.position;
            if (!requestedPosition) {
                return;
            }
            
            this.setPlayerPosition(requestedPosition);
        }
    }

    componentWillUnmount() {
        this.unregisterVideoElementEvents()
    }

    getVideoElementOrThrow = () => {
        if (!this.videoElementRef.current) {
            log.error("[getVideoElementOrThrow] Video element reference is unexpectedly empty.");
            throw new Error("Video element reference is unexpectedly empty.");
        }

        return this.videoElementRef.current;
    }

    registerVideoElementEvents = () => {
        const videoElement = this.getVideoElementOrThrow();

        videoElement.addEventListener('timeupdate', this.handleVideoOnTimeUpdate);
        videoElement.addEventListener('play', this.handleVideoOnPlay);
        videoElement.addEventListener('pause', this.handleVideoOnPause);
        videoElement.addEventListener('ended', this.handleVideoOnEnded);
        videoElement.addEventListener('volumechange', this.handleVideoVolumeOnChange);
        videoElement.addEventListener('canplay', this.handleVideoOnCanPlay);
        videoElement.addEventListener('canplayThrough', this.handleVideoOnCanPlayThrough);
        videoElement.addEventListener('complete', this.handleVideoOnComplete);
        videoElement.addEventListener('durationchange', this.handleVideoOnDurationChange);
        videoElement.addEventListener('emptied', this.handleVideoOnEmptied);
        videoElement.addEventListener('loadeddata', this.handleVideoOnLoadedData);
        videoElement.addEventListener('loadedmetadata', this.handleVideoOnLoadedMetaData);
        videoElement.addEventListener('playing', this.handleVideoOnPlaying);
        videoElement.addEventListener('progress', this.handleVideoOnProgress);
        videoElement.addEventListener('seeked', this.handleVideoOnSeeked);
        videoElement.addEventListener('seeking', this.handleVideoOnSeeking);
        videoElement.addEventListener('stalled', this.handleVideoOnStalled);
        videoElement.addEventListener('suspend', this.handleVideoOnSuspend);
        videoElement.addEventListener('waiting', this.handleVideoOnWaiting);
        videoElement.addEventListener('webkitbeginfullscreen', this.handleVideoOnWebkitBeginFullScreen)
        videoElement.addEventListener('webkitendfullscreen', this.handleVideoOnWebkitEndFullScreen)
    }

    unregisterVideoElementEvents = () => {
        const videoElement = this.getVideoElementOrThrow();

        videoElement.removeEventListener('timeupdate', this.handleVideoOnTimeUpdate);
        videoElement.removeEventListener('play', this.handleVideoOnPlay);
        videoElement.removeEventListener('pause', this.handleVideoOnPause);
        videoElement.removeEventListener('ended', this.handleVideoOnEnded);
        videoElement.removeEventListener('volumechange', this.handleVideoVolumeOnChange);
        videoElement.removeEventListener('canplay', this.handleVideoOnCanPlay);
        videoElement.removeEventListener('canplaythrough', this.handleVideoOnCanPlayThrough);
        videoElement.removeEventListener('complete', this.handleVideoOnComplete);
        videoElement.removeEventListener('durationchange', this.handleVideoOnDurationChange);
        videoElement.removeEventListener('emptied', this.handleVideoOnEmptied);
        videoElement.removeEventListener('loadeddata', this.handleVideoOnLoadedData);
        videoElement.removeEventListener('loadedmetadata', this.handleVideoOnLoadedMetaData);
        videoElement.removeEventListener('playing', this.handleVideoOnPlaying);
        videoElement.removeEventListener('progress', this.handleVideoOnProgress);
        videoElement.removeEventListener('seeked', this.handleVideoOnSeeked);
        videoElement.removeEventListener('seeking', this.handleVideoOnSeeking);
        videoElement.removeEventListener('stalled', this.handleVideoOnStalled);
        videoElement.removeEventListener('suspend', this.handleVideoOnSuspend);
        videoElement.removeEventListener('waiting', this.handleVideoOnWaiting);
        videoElement.removeEventListener('webkitbeginfullscreen', this.handleVideoOnWebkitBeginFullScreen)
        videoElement.removeEventListener('webkitendfullscreen', this.handleVideoOnWebkitEndFullScreen)
    }

    lastOnTimeUpdateValue = -1;
    handleVideoOnTimeUpdate = () => {
        const videoCurrentTime = this.getVideoElementOrThrow().currentTime;
        const videoDuration = this.getVideoElementOrThrow().duration;

        // Limit the number of onTimeUpdate event to 1 per second.
        if (Math.floor(videoCurrentTime) !== this.lastOnTimeUpdateValue) {
            this.props.onTimeUpdate(videoCurrentTime);
            this.lastOnTimeUpdateValue = Math.floor(videoCurrentTime);
        }
        
        this.updateDisplayPlayerProgress(videoCurrentTime, videoDuration);
    }

    handleVideoOnPlay = () => {        
        log.debug(`[handleVideoOnPlay] ${getReadableTimestamp()}`);
        log.debug("[play]");
        this.setState({ isVideoPlaying: true });
    }

    handleVideoOnPause = () => {
        log.debug("[pause]");
        this.setState({ isVideoPlaying: false });
    }

    handleVideoOnEnded = () => {
        log.debug("[ended]");
        this.setState({ isVideoPlaying: false });
        // Update the player progress one last time to ensure the progress bar is at the end.
        const videoElement = this.getVideoElementOrThrow();
        this.updateDisplayPlayerProgress(videoElement.currentTime, videoElement.duration);
    }

    // Event is invoked whenever the volume of the video has changed, but also when the mute state of the audio has changed.
    handleVideoVolumeOnChange = (e: Event) => {        
        log.debug("[volumeChange]");
        this.syncVolumeFromVideoPlayerToState();
    }

    handleVideoOnCanPlay = (e: Event) => {
        this.setState({isVideoLoading: false});
        log.debug("[canPlay]");
    }

    handleVideoOnCanPlayThrough = (e: Event) => {
        this.setState({isVideoLoading: false});
        log.debug("[canPlayThrough]");
    }

    handleVideoOnComplete = (e: Event) => {
        log.debug("[complete]");
    }

    handleVideoOnDurationChange = (e: Event) => {
        log.debug("[durationChange]");
        this.updateDisplayPlayerProgress(this.getVideoElementOrThrow().currentTime, this.getVideoElementOrThrow().duration);        
    }

    handleVideoOnEmptied = (e: Event) => {
        log.debug("[emptied]");
    }

    handleVideoOnLoadedData = (e: Event) => {
        log.debug("[loadedData]");
    }

    handleVideoOnLoadedMetaData = (e: Event) => {
        this.updateDisplayPlayerProgress(this.getVideoElementOrThrow().currentTime, this.getVideoElementOrThrow().duration);        
        log.debug("[loadedMetaData]");
    }

    handleVideoOnProgress = (e: Event) => {
        log.debug("[progress]");
    }

    handleVideoOnSeeked = (e: Event) => {      
        this.setState({isVideoLoading: false});  
        log.debug("[seeked]");
    }

    handleVideoOnSeeking = (e: Event) => {
        this.setState({isVideoLoading: true});    
        log.debug("[seeking]");
    }

    handleVideoOnStalled = (e: Event) => {
        log.debug("[stalled]");
    }

    handleVideoOnSuspend = (e: Event) => {
        log.debug("[suspend]");
    }

    handleVideoOnPlaying = (e: Event) => {
        this.setState({isVideoLoading: false});
        log.debug("[playing]");
    }

    handleVideoOnWaiting = (e: Event) => {
        this.setState({isVideoLoading: true});
        log.debug("[waiting]");
    }

    // Method is invoked on iOS safari when the video is being played in full-screen.
    // This method will apply a work-around for the behavior of iOS Safari where the 
    // captions are getting rougly half the size when the video is in full-screen mode.
    handleVideoOnWebkitBeginFullScreen = (e: Event) => {
        this.getVideoElementOrThrow().classList.add("increasecaptionsize");
    }

    // Method is invoked on iOS safari when the video is no longer being played in full-screen.    
    handleVideoOnWebkitEndFullScreen = (e: Event) => {
        this.getVideoElementOrThrow().classList.remove("increasecaptionsize");
    }

    
    handlePlayPauseToggle = () => {
        this.togglePlayPause();
    }

    handleSubTitleToggle = () => {
        this.toggleSubtitleDisplay();
    }
      
    handleVolumeChange = (value: number) => {
        const videoElement = this.getVideoElementOrThrow();
        videoElement.volume = value / 100;
        
        // Umute automatically when the volume is non-zero and mute when the volume is set to zero.
        if (value > 0) {
            videoElement.muted = false;            
        } 
        else
        {
            videoElement.muted = true;
        }        
    }


    handleMuteToggle = () => {
        const videoElement = this.getVideoElementOrThrow();
        videoElement.muted = !videoElement.muted;

        // In case the current volume is zero and the audio is unmuted, the volume will be set to 100% because else nothing would happen.
        if (!videoElement.muted && videoElement.volume === 0)  {
            videoElement.volume = 1;
        }
    }
  

    // Method toggles the fullscreen mode of the video.
    handleFullScreenToggle = () => {
        // Check if the video is currently in fullscreen mode and leave when this is the case.
        if (this.isVideoDisplayedFullScreen()) {
            // The video is currently displayed full screen, find the correct method to leave the fullscreen mode and execute it.
            if (document.exitFullscreen) { document.exitFullscreen(); } 
            else if (document.msExitFullscreen) { document.msExitFullscreen(); }            
            else if (document.mozCancelFullScreen) { document.mozCancelFullScreen(); } 
            else if (document.webkitExitFullscreen) { document.webkitExitFullscreen(); }   
            
            // Enforce a re-render, because the controls are rendered differently when the video player is displayed full screen.
            this.forceUpdate();

            return;
        }

        // Video is currently not in fullscreen mode, find the correct method to enter the fullscreen mode and execute it.
        const videoContainer = this.videoContainerRef.current;
        if (!videoContainer) {
            return;
        }

        const videoElement = this.getVideoElementOrThrow();

        if (videoContainer.requestFullscreen) {            
            videoContainer.requestFullscreen().catch(err => {
                log.error(`Entering full screen failed with error: ${err}`);
            }); 
        } 
        else if (videoContainer.msRequestFullscreen) { videoContainer.msRequestFullscreen(); } 
        else if (videoContainer.mozRequestFullScreen) { videoContainer.mozRequestFullScreen(); } 
        else if (videoContainer.webkitRequestFullscreen) { videoContainer.webkitRequestFullscreen(); }
        else if (videoElement.webkitEnterFullscreen) { 
            // iOS/Safari does only support full-screen mode of video, for this reason only the video element enters the full-screen mode 
            // and not the complete custom player like it is done for other platforms.
            videoElement.webkitEnterFullscreen();
        }

        // Enforce a re-render, because the controls are rendered differently when the video player is displayed full screen.
        this.forceUpdate();
    }

    handlePositionChange = (position: number) => {
        this.setPlayerPosition(position);
    }
    

    // Method returns a boolean indicating whether the video is currently played full screen.
    isVideoDisplayedFullScreen = () => {
        return document.fullscreenElement || 
               document.msFullscreenElement ||
               document.mozFullScreenElement ||
               document.webkitFullscreenElement || 
               false;
    }
 
    handleActivityStateChange = (newActivityState: boolean) => {
        this.setState({
            isUserActive: newActivityState
        });
    }

    setPlayerPosition = (position: number) => {
        this.getVideoElementOrThrow().currentTime = position;

        this.updateDisplayPlayerProgress(position, this.state.duration);
    }

    updateDisplayPlayerProgress = (currentTime: number, duration: number) => {        
        this.setState(
            {
                position: currentTime,
                duration: duration                
            });
    }

    togglePlayPause = () => {
        const videoElement = this.getVideoElementOrThrow();
        const { paused, ended } = videoElement;

        if (paused || ended) {
            videoElement.play();
        } else {
            videoElement.pause();
        }
    }

    enableSubtitles() {
        if (!this.state.isSubtitleActive) {
            this.toggleSubtitleDisplay();        
        }
    } 

    toggleSubtitleDisplay = () => {
        const videoElement = this.getVideoElementOrThrow();

        if (videoElement.textTracks.length > 0) {
            if (videoElement.textTracks[0].mode === 'showing') {
                videoElement.textTracks[0].mode = "hidden";

                this.setState({ isSubtitleActive: false });
            } else {
                videoElement.textTracks[0].mode = "showing";

                this.setState({ isSubtitleActive: true });
            }
        }
    }

    syncVolumeFromVideoPlayerToState = () => {
        const videoElement = this.getVideoElementOrThrow();

        this.setState(
            {
                isMuted: videoElement.muted,
                // Display the volume as zero when the audio muted.
                volume: videoElement.muted ? 0 : videoElement.volume * 100,                
            });
    }

    render() {
        const { isUserActive, isVideoPlaying, isMuted, volume, position, duration, isSubtitleActive, isVideoLoading } = this.state;
        const { style } = this.props.context;
        const isFullscreen = this.isVideoDisplayedFullScreen();

        // The play icon shown in the middle of the video area is only shown when the video is no longer loading 
        // (so ready to play) and the video is currently not playing. Also the icon should not be shown when the 
        // video is paused by the user, so an additional check is performed if the video is at the start.
        const showPlayIconOverlay = !isVideoLoading && !isVideoPlaying && position === 0;

        const loaderRendered = isVideoLoading ? 
            <div className={css.loader}>
                <Loader label="Loading..." />
            </div> : 
            <></>

        const playIconOverlayRendered = showPlayIconOverlay ? 
            <img className={css.playIcon} onClick={this.handlePlayPauseToggle} src={urls.thumbnailOverlay} alt="Overlay" /> :
            <></>

        return (
            <ActivityMeasurement onActivityStateChange={this.handleActivityStateChange} offSurfaceActivityTimeoutMs={offSurfaceVideoControlsFadeOutHoldOffMs} onSurfaceActivityTimeoutMs={onSurfaceVideoControlsFadeOutHoldOffMs} initialIsActive={this.state.isUserActive}>                
                <div ref={this.videoContainerRef} className={`${css.videoContainer} noselect`}>
                    <video ref={this.videoElementRef}
                        className="player" // ToDo: Move to CSS module
                        src={this.props.contentUrl}
                        autoPlay
                        crossOrigin="anonymous"
                        width='100%'
                        playsInline>
                            {
                                this.props.subtitleUrl && <track src={this.props.subtitleUrl} label={ISO6391.getName(this.props.code)} kind="subtitles" srcLang={this.props.code} />
                            }
                            Your browser does not support video.
                    </video>
                    {loaderRendered}
                    {playIconOverlayRendered}
                    <div className={`${css.playerControlsContainer} 
                                     ${style === 0 ? css.lightTheme : ''} 
                                     ${isFullscreen ? css.fullscreen : ''} 
                                     ${(!isVideoPlaying || isUserActive) ? css.showControls : ''}
                                     `}>
                        <PlayerControls 
                                showFullscreenIcon={true}
                                showSubtitleIcon={!!this.props.subtitleUrl}
                                showVolumeControls={true}
                                isMediaPlaying={isVideoPlaying}
                                isSubtitleActive={isSubtitleActive}
                                isMuted={isMuted}
                                volume={volume}
                                timelineEvents={this.props.timelineEvents}
                                duration={duration}
                                position={position}
                                onFullScreenToggle={this.handleFullScreenToggle}
                                onPlayPauseToggle={this.handlePlayPauseToggle}
                                onSubtitleToggle={this.handleSubTitleToggle}
                                onVolumeChange={this.handleVolumeChange}
                                onMuteToggle={this.handleMuteToggle}
                                onPositionChange={this.handlePositionChange}
                                onDownload={this.props.onDownload} />
                    </div>                        
                </div>
            </ActivityMeasurement>            
        );
    }
}

export const VideoPlayer = connectTeamsTheme(VideoPlayerInternal);