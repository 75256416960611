import React, { Fragment, useContext } from 'react';
import './SearchResult.scss';

import { TeamsRecordingSearchResult } from './TeamsRecordingSearchResult';
import SearchResultHeader from './SearchResultHeader';
import { Pager } from './Pager';
import { resultsProcessor } from '../../logic/resultsProcessor';
import { getStyle } from '../ThemeLogic';
import { Flex } from "@fluentui/react-northstar";
import { themeContext } from './../../context/TeamsFluentUiContextProvider'


const SearchResult = ({searchSettings, searchResult, sorterConfig, onClick, pageUp, pageDown, loadPage, innerRef}) => {

  const {style, theme} = useContext(themeContext);
  
  const teamsContext = {...theme.siteVariables, style}

  if (!searchResult || !searchResult.documents) {
    return null;
  }

  if (searchResult.documents.length === 0) {
    return "No search results...";
  }

  const processedResult = resultsProcessor.processResults(searchResult.documents);
  
  const currentPaginationBackground = getStyle(teamsContext).paginationBackground


  return (    
    <Fragment>
      <Flex 
      className='search-results-block'
      column
      >
        <div 
        className='searchresult-panel-header'>
          <SearchResultHeader searchSettings={searchSettings} searchResults={searchResult} sorterConfig={sorterConfig} />
        </div>
        <div ref={innerRef} className='searchresult-panel-body'>
          <section className="support-search-list">
            {processedResult.map(result =>  <TeamsRecordingSearchResult key={result.mediaId} document={result} onClick={onClick}/>)} 
          </section>
        </div>
        <div className='searchresult-panel-footer' style={currentPaginationBackground}>
          <Pager count={searchResult.totalCount} top={searchSettings.top} skip={searchSettings.skip} showPager='true' pageUp={pageUp} pageDown={pageDown} loadPage={loadPage} />
        </div>
      </Flex>
    </Fragment>
  );
}

export default SearchResult;
