import { Component } from 'react';
import {AzureTraceMapProps, createAzMap} from './Azure/AzureTraceMap'
import {TraceMapControl} from "./MapControl";
import {getTimeCoordinates, TimeCoordinate, getNearestTimeCoordinate, Position} from './TraceData';

interface TraceMapProps {
    traceData: any;
    startTime: Date,
    timeOffSetNavigationPointer: number
}
interface TraceMapState {
    mapCreated: boolean;
    mapReadReady: boolean;
}

export default class TraceMap extends Component<TraceMapProps, TraceMapState> {
    map!: TraceMapControl;
    timeCoordinates?: TimeCoordinate[];
    timeCoordinateIndexNavigationPointer: number = -1;

    constructor(props: TraceMapProps) {
        super(props);        
        this.state = {
            mapCreated: false,
            mapReadReady: false
        }
    }

    componentDidMount() {
        this.createMap("media-trace-map");
    }

    componentDidUpdate() {
        if (!this.state.mapCreated){            
            this.createMap("media-trace-map");
        }

        if (this.state.mapReadReady){            

            if (this.timeCoordinates){
                let index: number = getNearestTimeCoordinate(0, this.props.timeOffSetNavigationPointer, this.timeCoordinates);

                //if (index !== -1 && index !== this.timeCoordinateIndexNavigationPointer){
                    let timeCoordinate: TimeCoordinate = this.timeCoordinates[index];
                    
                    let nextPosition: Position | undefined;
                    if (index < this.timeCoordinates.length){
                        nextPosition = this.timeCoordinates[index + 1].coordinates;
                    }

                    this.map.setCoordinatesNavigationPointer(timeCoordinate.coordinates, nextPosition);
                    
                    this.timeCoordinateIndexNavigationPointer = index;
                //}
                
            }            
        }
      }

    createMap = (elementId: string) => {
        if(!this.props.traceData){
            return;
        }

        let props: AzureTraceMapProps = {
            elementId: elementId,
            onMapRead: this.onMapReady
        }
        this.map = createAzMap(props);

        this.setState({
            mapCreated: true
        });

        this.timeCoordinates =  getTimeCoordinates(this.props.traceData, this.props.startTime);
    };

    onMapReady = async () => {        
        if(this.props.traceData !== null){
            await this.map.loadTraceData(this.props.traceData);
        }        
        this.setState({
            mapReadReady: true
        });
    }

    render() {
        return (
            <>
                <div id="media-trace-map" style={{ height: '70vh'  }}>
                    
                </div>
            </>
        )
    }
}
