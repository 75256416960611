import React, { Component } from 'react';
import './DatePickerPresentation.scss'
import { Datepicker, Input } from '@fluentui/react-northstar';
import moment from 'moment';
import { getStyle } from '../../ThemeLogic';
import { themeContext } from './../../../context/TeamsFluentUiContextProvider'

class DatePicker extends Component {

    static contextType = themeContext

    onDateChange = (date) => {
        if (this.props.onChange === null) {
            return;
        }
        let newDate = moment(new Date(date.value));
        this.props.onChange(newDate);
    }

    render() {
        const teamsContext = {...this.context.theme.siteVariables, style: this.context.style}

        const date = this.props.value.toDate();

        let minDate = null;
        let maxDate = null;
        if (this.props.minDate) {
            minDate = this.props.minDate.toDate();
        }
        if (this.props.maxDate) {
            maxDate = this.props.maxDate.toDate();
        }

        const { style } = this.context;

        return ( 
        <div>
            <div
                onClick={this.onClick}
                className={`support-datepicker ${style === 1 || style === 2 ? 'dark' : ''}`}
                style={getStyle(teamsContext).picker}>
                <Input
                    fluid
                    value={this.props.value.format("DD MMM yyyy")}
                    disabled
                />
                <Datepicker
                    className='roter'
                    label=''
                    minDate={minDate}
                    maxDate={maxDate}
                    buttonOnly={true}
                    selectedDate={date}
                    onDateChange={(component, newDate) => this.onDateChange(newDate)}
                />
            </div>
        </div>
        )
    }
}

export default DatePicker;
