import React, { Component, useContext } from 'react';
import { Button } from '@fluentui/react-northstar'
import { AuthorizationContext, AuthorizationContextInterface } from '../../context/AuthorizationContext';

import css from './ErrorMessage.module.scss';

interface TenantNoReplayInternalProps {
    authorizationContext: AuthorizationContextInterface    
}

class TenantNoReplayInternal extends  Component<TenantNoReplayInternalProps, {}> {
    handleLogout = () => {
        this.props.authorizationContext.logoutRedirect();
    }

    render() {
        const logoutButtonRendered = !this.props.authorizationContext.isSSO() ? 
        <>
            <p className={css.paddingTop}>In case you accidentally logged-in with a wrong account, use the button below to logout.</p>
            <div className={css.paddingTop}>
                <Button content="Logout" primary onClick={this.handleLogout}/>
            </div>
        </> : 
        <></>

        return (
            <div className={css.main}>
                <p><strong>Your organization is no longer allowed to replay the recorded calls.</strong></p>
                <p>Please contact your IT administrator.</p>
                {logoutButtonRendered}
            </div>
        )
    }
}

// Inject the required contexts.
export const TenantNoReplay: React.FunctionComponent = () => {
    const authorizationContext = useContext(AuthorizationContext);
    if (!authorizationContext) {
        throw new Error("No AuthorizationContext found in React tree.");
    }

    return (
        <TenantNoReplayInternal authorizationContext={authorizationContext} />
    )
}