import { Field } from '../../../types/Field';
import GeographyPointsFacet from './GeographyPointsFacetPresentation';
import { FacetLogic, GeographyPointsFacetValue } from './Types';

function createFilter(facetSelection: GeographyPointsFacetValue, field: Field) {
    let radiusInKm = facetSelection.radius/ 1000;
    let filter = field.systemSettings.filterClause
        .replace('{{LONGITUDE}}', facetSelection.longitude.toString())
        .replace('{{LATITUDE}}', facetSelection.latitude.toString())
        .replace('{{OPERATOR}}', 'le')
        .replace('{{RADIUS}}', radiusInKm.toString());
    return filter;
}

function createRequest(field: Field) {
    return ""; //GeographyPointsFacet is not a real facet and can not be requested, so return empty string.
}

function extractFacetValues(facetValuesFromSearch: GeographyPointsFacetValue) {    
    //GeographyPointsFacet is not a real facet and can not be requested, therefore we also can not extract any value.
    return {};
}

function renderFacet (field: Field, facetValue: GeographyPointsFacetValue, facetSelection: GeographyPointsFacetValue, onChanged: any) {    
    return <GeographyPointsFacet
             key={field.name} 
             name={field.name} 
             displayName={field.userSettings.displayName} 
             facetData={facetValue} 
             facetSelection={facetSelection}
             onChangeFacet={onChanged}/>
}

// The facetValue contains the value of the entry that has been clicked.
// If this entry currently exists in the selection it will be removed else it will be added.
function createUpdatedSelection(facetValue: GeographyPointsFacetValue, prevFacetSelection: GeographyPointsFacetValue) {
    return facetValue;
}

export const geographyPointsFacetLogic : FacetLogic = {
    dataType: 'Collection(Edm.GeographyPoint)',
    createFilter: createFilter,
    createRequest: createRequest,
    extractFacetValues: extractFacetValues,
    renderFacet: renderFacet,
    createUpdatedSelection: createUpdatedSelection,
}