import React, { Component, useContext } from 'react';
import { Button } from '@fluentui/react-northstar'
import { AuthorizationContext, AuthorizationContextInterface } from '../../context/AuthorizationContext';

import css from './ErrorMessage.module.scss';

interface TenantUnknownInternalProps {
    authorizationContext: AuthorizationContextInterface    
}

class TenantUnknownInternal extends Component<TenantUnknownInternalProps, {}> {
    handleLogout = () => {
        this.props.authorizationContext.logoutRedirect();
    }

    render() {
        const logoutButtonRendered = !this.props.authorizationContext.isSSO() ? 
        <>
            <p className={css.paddingTop}>In case you accidentally logged-in with a wrong account, use the button below to logout.</p>
            <div className={css.paddingTop}>
                <Button content="Logout" primary onClick={this.handleLogout}/>
            </div>
        </> : 
        <></>

        return (
            <div className={css.main}>
                <p><strong>Your organization has not signed up yet for the Attest service.</strong></p>
                <p>Please contact your IT administrator.</p>
                <br/>
                <p>The Attest service can be purchased here: <a href="https://azuremarketplace.microsoft.com/en-us/marketplace/apps/cybertwicebv1586872140395.attest_teams">Azure Marketplace</a></p>                
                <br/>
                {logoutButtonRendered}
            </div>
        )
    }
}

// Inject the required contexts.
export const TenantUnknown: React.FunctionComponent = () => {
    const authorizationContext = useContext(AuthorizationContext);
    if (!authorizationContext) {
        throw new Error("No AuthorizationContext found in React tree.");
    }

    return (
        <TenantUnknownInternal authorizationContext={authorizationContext} />
    )
}