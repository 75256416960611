import React, { Component } from 'react'
import { Dropdown } from '@fluentui/react-northstar'
import { Field } from '../../types/Field'
import { themeContext } from './../../context/TeamsFluentUiContextProvider'

interface SearchInDropDownProps {
    fields: Field[];
    onChange?(e: SearchInDropDownChangedEventArgs): void;
}

interface SearchInDropDownState {
}

export interface SearchInDropDownChangedEventArgs {
    selectedItem: string;
}

const cacheActiveFilter = 'cacheActiveFilter'

function fieldDisplayNameComparer(field1: Field, field2: Field) {
    if (field1.userSettings.displayName < field2.userSettings.displayName) {
        return -1;
    }
    if (field1.userSettings.displayName > field2.userSettings.displayName) {
        return 1;
    }
    return 0;
}

function getInputItems(fields: Field[]): any[] {
    const fieldsToShow = fields.filter(f => f.systemSettings.isSearchable && f.userSettings.showInSearchInFieldsSelector).sort(fieldDisplayNameComparer);

    let items = fieldsToShow.map(f => {
        return {
            key: f.name,
            header: f.userSettings.displayName
        };
    });
    items.unshift({
        key: "#all#",
        header: "(All Fields)"
    });
    return items;
}

export default class SearchInDropDown extends Component<SearchInDropDownProps, SearchInDropDownState> {
    context!: React.ContextType<typeof themeContext>;
    static contextType = themeContext
    state = {}

    onChangeClick = (event: any, data: any) => {
        if (this.props.onChange) {
            let selectedItem = data.value.key === "#all#" ? undefined : data.value.key;
            this.props.onChange({ selectedItem: selectedItem })
        }
        localStorage.setItem(cacheActiveFilter, data.searchQuery)
    }

    render() {

        const renderActiveFilter = localStorage.getItem(cacheActiveFilter)
        const items = getInputItems(this.props.fields);

        return (
            <div>
                <Dropdown
                    fluid
                    items={items}
                    onChange={this.onChangeClick}
                    defaultValue={renderActiveFilter ?? items[0]}
                    highlightFirstItemOnOpen={true}
                    variables={{
                        borderColorHover: 'none',
                        listItemBackgroundColorHover: this.context.theme.siteVariables.colorScheme.brand.foreground1,
                    }}
                />
            </div>
        )
    }
}