import React, {createContext} from 'react';
import { Provider, teamsTheme, teamsDarkTheme, teamsHighContrastTheme, mergeThemes } from '@fluentui/react-northstar'
import { microsoftTeamsLogic } from '../logic/microsoftTeamsLogic';

// The TeamsFluentUiContextProvider is required for the UI components from the '@fluentui/react-northstar'.
// In case the application runs in the Teams environment, this provider will automatically react to changes
// in the settings of the theme in Teams.

export const themeContext = createContext()

export class TeamsFluentUiContextProvider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            theme: teamsTheme,
            style: 0
        }
    }

    componentDidMount() {
        // Register to receive an event when the Teams theme is changed.
        microsoftTeamsLogic.registerThemeChangeCallback(this.updateTheme);
    }

    componentWillUnmount() {
        microsoftTeamsLogic.unregisterThemeChangeCallback(this.updateTheme);
    }

    // Sets the correct theme type from the query string parameter.
    updateTheme = (themeStr) => {
        let theme;
        let style;
        switch (themeStr) {
            case 'dark':
                theme = teamsDarkTheme;
                style = 1
                break;
            case 'contrast':
                theme = teamsHighContrastTheme;
                style = 2
                break;
            case 'default':
            default:
                theme = teamsTheme;
                style = 0
        }
        this.setState({ theme, style });
    }

    render() {

        const customTheme = {
            componentStyles: {
                Flex: {
                    root: {
                        backgroundColor: this.state.theme.siteVariables.bodyBackground, 
                        border: this.state.style === 2 ? `0.13rem solid` : ''
                    }
                },
                Input: {
                    root: {

                    }
                },
                RadioGroupItem: {
                    root: {
                        margin: 0
                    }
                },
                Checkbox: {
                    root: {
                        paddingLeft: 'none',
                        color: 'unset',
                    },
                },
                Button: {
                    root: {
                        border: 'none',
                        boxShadow: 'none',
                    },
                }
            },
            componentVariables: {
                Checkbox:{
                    checkedBorderColor: this.state.theme.siteVariables.colorScheme.brand.foreground1,
                    borderColorHover: this.state.theme.siteVariables.colorScheme.brand.foreground1
                },
                Input: {
                    colorDisabled: this.state.theme.siteVariables.colorScheme.default.foreground,
                },
                Button: {
                    primaryBackgroundColorHover: this.state.theme.siteVariables.colorScheme.brand.foreground1,
                    backgroundColorActive: 'none',
                    colorHover: this.state.theme.siteVariables.bodyBackground,
                    backgroundColorIconOnlyHover: 'none',
                    textColorIconOnlyHover: this.state.theme.siteVariables.colorScheme.brand.foreground1,
                }
            }
        }

        const theme = mergeThemes(this.state.theme, customTheme)

        return (
            <Provider theme={theme}>
                <themeContext.Provider value = {this.state}> 
                {this.props.children}
                </themeContext.Provider>
            </Provider>
        )
    }
}
