import { Flex } from '@fluentui/react-northstar'
import React, { Component } from 'react'
import { FaceButtonListItem, FaceButtonListItemClickedEventArgs } from './FaceButtonListItem';
import { Face } from './types';
import { Text } from '@fluentui/react-northstar'
import { getStyle } from '../../ThemeLogic';
import { themeContext } from './../../../context/TeamsFluentUiContextProvider'

export interface FaceButtonListItemSelectedEventArgs{
    id: string | undefined;
}
interface FacesButtonListProps {
    imagesUrl: string
    faces: Face[];
    context: any    
    itemSelected?(e: FaceButtonListItemSelectedEventArgs): void;
}
interface FacesButtonListState {
    selectedItem?: string
}

interface FacesButtonListItemsProps {
    faces: Face[];    
    handleItemClick(e: FaceButtonListItemClickedEventArgs): void;
    selectedItem?: string
    context?: any
}


function FacesButtonListItems(props: FacesButtonListItemsProps) {    
    const persons = props.faces;
    const listItems = persons.map((person) => {
        return (
           <FaceButtonListItem key={person.id} faceDate={person} selected={props.selectedItem === person.id} handleClick={props.handleItemClick}  />
        );
    });

    return (
        <>
            {listItems}
        </>      
    );
  }

export class FacesButtonList extends Component<FacesButtonListProps, FacesButtonListState> {
    context!: React.ContextType<typeof themeContext>
    static contextType = themeContext

    state = {
        selectedItem: undefined
    }

    handleItemClick = (e: FaceButtonListItemClickedEventArgs) => {
        let selectedItemId: string | undefined = undefined;

        if (this.state.selectedItem !== e.id){
            selectedItemId = e.id;
        }
        
        this.setState({
            selectedItem: selectedItemId
        });

        if (this.props.itemSelected){
            this.props.itemSelected({ id: selectedItemId} )
        }
    };

    render() {

        const teamsContext = {...this.context.theme.siteVariables, style: this.context.style}

        const { selectedItem } = this.state;
        const selectedFace = this.props.faces.find(f => f.id === selectedItem);
        const background = getStyle(teamsContext).paginationBackground

        return (            
            <>
                {selectedFace &&
                    <>
                        <Flex gap="gap.small" vAlign='center' className='peoples' style={background}>
                            <FaceButtonListItem key={selectedFace.id} faceDate={selectedFace ?? undefined} selected={false} />
                            <Text weight="regular" content={selectedFace.title} />
                        </Flex>
                    </>
                }
                <Flex gap="gap.small" wrap={true}>
                    <FacesButtonListItems faces={this.props.faces} handleItemClick={this.handleItemClick} selectedItem={selectedItem} />
                </Flex> 
            </>
        )
    }   
}
