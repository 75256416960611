import React, { Component } from 'react';
import memoize from "memoize-one";
import { TextItem, Participant } from '../../types/transcription';
import { durationToTranscriptionDisplayString } from '../../logic/timeDisplay';
import { createContent } from '../../logic/contentCreation';
import { Avatar, Chat, ChatItemProps } from '@fluentui/react-northstar'
import { AcceptIcon } from '@fluentui/react-icons-northstar'

import css from './TranscriptionListRow.module.scss';

const avatarStatus = {
    status: {
      color: 'green',
      icon: <AcceptIcon />,
    },
}

export type TranscriptionRowData = {
    textItem: TextItem,
    participant: Participant,
    isSelected: boolean,
    isAttached: boolean,
    showParticipant: boolean,
}

type Props = {
    index: number,
    style: any,
    data: TranscriptionRowData,
    onSetPositionRequest: (position: number) => void,
    heightCallback: heightCallback,
}

type heightCallback = (index: number, height: number) => void;

export class TranscriptionListRow extends Component<Props, {}> {
    
    divRef: React.RefObject<HTMLDivElement> = React.createRef();

    /*
    constructor(props: Props) {
        super(props);
        console.log(`Constructor invoked for row with index ${props.index}`);
    }
    */
   
    componentDidMount() {
        this.reportHeight();
    }

    componentDidUpdate() {
        this.reportHeight();
    }

    componentWillUnmount() {
        //console.log(`Row with index ${this.props.index} will unmount.`);
    }

    memoizedCreateChatItem = memoize((textItem: TextItem, participant: Participant, showAttached: boolean, messageId: number, showMarked: boolean, showParticipant: boolean) => 
        this.createChatItem(textItem, participant, showAttached, messageId, showMarked, showParticipant)
    )

    createChatItem = (textItem: TextItem, participant: Participant, showAttached: boolean, messageId: number, showMarked: boolean, showParticipant: boolean) : ChatItemProps => {        
        const contentWithHighlights = createContent(textItem, css.highlight, this.props.onSetPositionRequest);
        
        const attached: boolean | 'top' | 'bottom' = showAttached ? true : 'top';
        const variables = showMarked ? { isImportant: true } : {};

        let displayname = "";
        if ((!participant) || (Object.keys(participant).length === 0)) {
            displayname = "A";
        } else {
            displayname = participant.displayname;
        }
        
        const chatItem = {
            gutter: showParticipant ? <Avatar {...avatarStatus} name={displayname} /> : null,
            message: (
              <Chat.Message 
                content={contentWithHighlights} 
                author={displayname} 
                timestamp={durationToTranscriptionDisplayString(textItem.offsetSeconds)}     
                variables={variables}              
              />
            ),
            attached: attached,
            key: `message-id-${messageId}`
        }

        return chatItem;
    }    

    // Method reports the rendered row height in pixels back to the TranscriptionList component. 
    reportHeight = () => {
        const divElement = this.divRef.current;
        if (!divElement) { 
            return;
        }

        const scrollHeight = divElement.scrollHeight;
        
        //console.log(`ScrollHeight of item ${this.props.index} is ${scrollHeight}. clientHeight: ${divElement.clientHeight}`);
        this.props.heightCallback(this.props.index, scrollHeight);
    }

    render() {
        //console.log(`[render] Row index: ${this.props.index}. Style height: ${this.props.style.height}`);

        const { textItem, participant, isSelected, isAttached, showParticipant } = this.props.data;
        const { style } = this.props;

        // The 'style' object passed from the VariableSizeList parent component contains a height property which is
        // ignored so that the row uses all the height it intrinsically needs.  
        const styleCopy = {...style};
        delete styleCopy.height;
        
        const chatItem = this.memoizedCreateChatItem(textItem, participant, isAttached, 1, isSelected, showParticipant);
        return <div ref={this.divRef} style={styleCopy}>
                    <Chat items={[chatItem]} styles={{marginRight: '12px'}}/>
               </div>
    }
}
