import React, { Component } from 'react';
import './SearchResultHeader.scss';
import { SearchContext } from '../../context/SearchContext';
import log from 'loglevel';
import { Dropdown, Button, ArrowUpIcon, ArrowDownIcon } from '@fluentui/react-northstar'
import { connectTeamsTheme } from "./../../context/connectTeamsTheme";
import { style } from 'typestyle';

class SearchResultsHeader extends Component {
    static contextType = SearchContext;

    getSortOrderIcon = () => {
        if (this.props.searchSettings.sortOrder === "asc") {
            return <ArrowUpIcon/>;
        } else {
            return <ArrowDownIcon/>;
        }
    }

    onSortOrderClick = () => {
        let sortOrder = "asc"
        if (this.props.searchSettings.sortOrder === "asc") {
            sortOrder = "desc"
        }

        const searchContext = this.context;
        searchContext.updateSorting(this.props.searchSettings.sortBy, sortOrder);
    }

    onSortByChange = (_, options) => {
        log.debug(`onSortByChange ${options.value.name}`);

        const searchContext = this.context;
        searchContext.updateSorting(options.value.name, this.props.searchSettings.sortOrder);
    }


    render() {

        const selectedItemStyle = style({
            backgroundColor: this.props.context.theme.siteVariables.colorScheme.brand.foreground1,
        })

        const sorterOptions = [];

        for (let i = 0; i < this.props.sorterConfig.length; i++) {
            const sorter = this.props.sorterConfig[i];

            sorterOptions.push({header: sorter.displayName, name: sorter.name, className: sorter.name === this.props.searchSettings.sortBy ? selectedItemStyle : null});
        }

        const iconStyles = style({
            border: "none",
            "&:hover": {
                backgroundColor: "inherit"
              },
            "&:active": {
                backgroundColor: "inherit",
                color: this.props.context.theme.siteVariables.colorScheme.brand.foreground1
              },
        });

        const searchContext = this.context;
        const headerText = searchContext.isExecutingSearch ?
            'Searching...' :
            `${this.props.searchSettings.skip + 1}-${this.props.searchSettings.skip + this.props.searchResults.documentCount} of ${this.props.searchResults.totalCount} results`;
        return (
            <header className="wrapper-header display--flex flex--wrap">
                <p className="title">{headerText}</p>
                <div className="support-sorters-panel display--flex">
                    <p className="sorter-title">Sort by:</p>
                    <Dropdown
                        fluid
                        items={sorterOptions}
                        defaultValue={sorterOptions[1]}
                        onChange={this.onSortByChange}
                        highlightFirstItemOnOpen={true}
                        variables={{
                            borderColorHover: 'none',
                            listItemBackgroundColorHover: this.props.context.theme.siteVariables.colorScheme.brand.foreground1,
                        }}
                    />
                    <Button
                        iconOnly
                        className={`${iconStyles}`}
                        onClick={this.onSortOrderClick}
                        icon={this.getSortOrderIcon()}
                        variables={{
                            textColorIconOnlyHover: this.props.context.theme.siteVariables.colorScheme.brand.foreground1,
                        }}
                        title="Change sorting order"
                    />
                </div>
            </header>
        );
    }
}

const SearchResultHeader = connectTeamsTheme(SearchResultsHeader);
export default SearchResultHeader