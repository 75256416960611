import { Field } from '../../../types/Field';
import StringFacet from './StringFacetPresentation';
import { FacetLogic } from './Types';

// facetSelection is an array of strings.
function createFilter(facetSelection: any, field: Field) {
    const filterClauses = facetSelection.map((selection: any) => field.systemSettings.filterClause.replace('{{VALUE}}', selection).replace('{{OPERATOR}}', 'eq'));
    if (filterClauses.length === 0) {
        return "";
    }

    const filterClausesString = filterClauses.join(' and ');    
    return `(${filterClausesString})`;
}

function createRequest(field: any) {
    return field.name;
}

function extractFacetValues(facetValuesFromSearch: any) {
    let extractedValues: any = {};

    facetValuesFromSearch.forEach((facetValue: any) => {
        // Filter out empty values.
        if (!facetValue.value) {
            return;
        }

        extractedValues[facetValue.value] = {
            count: facetValue.count
        }
    });

    return extractedValues;
}

function renderFacet (field: Field, facetValue: any, facetSelection: any, onChanged: any, setFacetExpanded: any) {  
    return <StringFacet
             key={field.name} 
             name={field.name}
             setFacetExpanded={setFacetExpanded}
             displayName={field.userSettings.displayName} 
             facetData={facetValue} 
             facetSelection={facetSelection}
             onChangeFacet={onChanged}/>
}

// The facetValue contains the value of the entry that has been clicked.
// If this entry currently exists in the selection it will be removed else it will be added.
function createUpdatedSelection(facetValue: any, prevFacetSelection: any) {
    prevFacetSelection = prevFacetSelection || [];

    const isValueCurrentlySelected = prevFacetSelection.some((item: any) => item === facetValue);
    const newSelectionsOfFacet = isValueCurrentlySelected ?
        prevFacetSelection.filter((item: any) => item !== facetValue) : // Remove item
        [...prevFacetSelection, facetValue]; // Add item

    return newSelectionsOfFacet;
}

export const stringFacetLogic: FacetLogic = {
    dataType: 'Edm.String',
    createFilter: createFilter,
    createRequest: createRequest,
    extractFacetValues: extractFacetValues,
    renderFacet: renderFacet,
    createUpdatedSelection: createUpdatedSelection,
}

export const stringArrayFacetLogic: FacetLogic = {
    dataType: 'Collection(Edm.String)',
    createFilter: createFilter,
    createRequest: createRequest,
    extractFacetValues: extractFacetValues,
    renderFacet: renderFacet,
    createUpdatedSelection: createUpdatedSelection,
}