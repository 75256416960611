// Default values for the runtime configuration. See 'config.js' file 
export const appConfig = {
    authority: 'https://login.microsoftonline.com/common',
    clientId: '',    
    replayApiHostname: '',
    logLevel: 'debug',
    apiScopes: [
        '',
    ],
    azMapsClientId: '',
    attestReplayWebUrl: 'https://replay.attest.cybertwice.com' 
};
